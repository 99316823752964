import ListMod from '../../Component/Redesign/ListMod';
import { DisputesListDatagrid } from './components/DisputesListDataGrid';

export const DisputesList = () => {

    return (
        <ListMod title="Disputes" basePath="/disputes">
            <DisputesListDatagrid/>
        </ListMod>
    )
}
