import {DefaultCurrency} from "../constant/Const";

export const reformatNumber = (value, separator = ',') => {
	if (value === null || value === undefined) {
		return '';
	}

	const newVal = typeof value === 'string' ? parseFloat(value) : value;
	const parsed = newVal.toFixed(2).replace('.00', '');
	const search = new RegExp(/(\d)(?=(\d\d\d)+([^\d]|$))/g);
	return parsed.replace(search, `$1${separator}`) || value.toString();
}

export const getFinancialNumberWithCurrency = (value, currency = DefaultCurrency.badge) => {
	const parsed = reformatNumber(value);
	return parsed ? `${currency} ${parsed}` : ''
}
