import {SvgIcon} from "@mui/material";

export const DeleteColumn = (props) => {
    return (<SvgIcon fontSize="inherit" {...props} width="44" height="44" viewBox="0 0 44 44" fill="none">
        <path d="M2.77521 4.47227C2.77521 3.91998 3.22292 3.47227 3.77521 3.47227H11.9419V9.41991H2.77521V4.47227Z"
              fill="white"/>
        <path d="M12.5366 3.4723H21.7032V9.41994H12.5366V3.4723Z" fill="white"/>
        <path d="M22.2972 3.4723H31.4639V9.41994H22.2972V3.4723Z" fill="#C35E5B"/>
        <path d="M32.0587 3.4723H40.2253C40.7776 3.4723 41.2253 3.92001 41.2253 4.4723V9.41994H32.0587V3.4723Z"
              fill="white"/>
        <path d="M2.77521 10.0147H11.9419V15.9624H2.77521V10.0147Z" fill="white"/>
        <path d="M2.77521 16.5572H11.9419V22.5048H2.77521V16.5572Z" fill="white"/>
        <path d="M2.77521 23.0996H11.9419V29.0473H2.77521V23.0996Z" fill="white"/>
        <path d="M12.5366 10.0147H21.7032V15.9624H12.5366V10.0147Z" fill="white"/>
        <path d="M12.5366 23.0996H21.7032V29.0473H12.5366V23.0996Z" fill="white"/>
        <path d="M12.5366 16.5572H21.7032V22.5048H12.5366V16.5572Z" fill="white"/>
        <path d="M22.2972 23.0996H31.4639V29.0473H22.2972V23.0996Z" fill="#C35E5B"/>
        <path d="M32.0587 23.0996H41.2253V29.0473H32.0587V23.0996Z" fill="white"/>
        <path d="M22.2972 10.0147H31.4639V15.9624H22.2972V10.0147Z" fill="#C35E5B"/>
        <path d="M32.0587 10.0147H41.2253V15.9624H32.0587V10.0147Z" fill="white"/>
        <path d="M22.2972 16.5572H31.4639V22.5048H22.2972V16.5572Z" fill="#C35E5B"/>
        <path d="M32.0587 16.5572H41.2253V22.5048H32.0587V16.5572Z" fill="white"/>
        <path d="M11.9419 3.47227H12.5367V29.642H11.9419V3.47227Z" fill="#C7C7C7"/>
        <path d="M41.2253 9.41994L41.2253 10.0147L2.77519 10.0147L2.77519 9.41994L41.2253 9.41994Z" fill="#C7C7C7"/>
        <path d="M41.2253 15.9624L41.2253 16.5571L2.77519 16.5571L2.77519 15.9624L41.2253 15.9624Z" fill="#C7C7C7"/>
        <path d="M41.2253 22.5048L41.2253 23.0996L2.77519 23.0996L2.77519 22.5048L41.2253 22.5048Z" fill="#C7C7C7"/>
        <path d="M21.7032 3.47227H22.298V29.642H21.7032V3.47227Z" fill="#C7C7C7"/>
        <path d="M31.4639 3.47227H32.0587V29.642H31.4639V3.47227Z" fill="#C7C7C7"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.18042 2.8775C2.62814 2.8775 2.18042 3.32522 2.18042 3.8775V28.642C2.18042 29.1943 2.62813 29.642 3.18042 29.642L40.8195 29.6421C41.3718 29.6421 41.8195 29.1943 41.8195 28.6421V3.8775C41.8195 3.32522 41.3718 2.8775 40.8195 2.8775H3.18042ZM41.2247 4.47227C41.2247 3.91998 40.777 3.47227 40.2247 3.47227L3.77518 3.47227C3.2229 3.47227 2.77518 3.91998 2.77518 4.47227V28.0473C2.77518 28.5996 3.2229 29.0473 3.77518 29.0473L40.2247 29.0473C40.777 29.0473 41.2247 28.5996 41.2247 28.0473V4.47227Z"
              fill="#7D7D7D"/>
        <mask id="mask0_4_488" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="21" y="29" width="12"
              height="13">
            <rect x="21.1921" y="29.6421" width="11.4804" height="11.4804" fill="#C35E5B"/>
        </mask>
        <g mask="url(#mask0_4_488)">
            <path
                d="M25.6891 37.5356L26.9329 36.2918L28.1767 37.5356L28.8465 36.8659L27.6027 35.6221L28.8465 34.3782L28.1767 33.7085L26.9329 34.9523L25.6891 33.7085L25.0193 34.3782L26.2631 35.6221L25.0193 36.8659L25.6891 37.5356ZM24.5409 39.6884C24.2778 39.6884 24.0526 39.5948 23.8654 39.4076C23.6779 39.2201 23.5841 38.9948 23.5841 38.7316V32.5125H23.1057V31.5557H25.4977V31.0773H28.3681V31.5557H30.7601V32.5125H30.2817V38.7316C30.2817 38.9948 30.1881 39.2201 30.0009 39.4076C29.8133 39.5948 29.588 39.6884 29.3249 39.6884H24.5409Z"
                fill="#C35E5B"/>
        </g>
    </SvgIcon>)
}
