import ListMod from "../../../Component/Redesign/ListMod";
import React from "react";
import {
	CreateButton,
	Datagrid,
	DeleteWithConfirmButton,
	EditButton,
	FunctionField,
	SelectField,
	TextField,
	TopToolbar
} from "react-admin";
import {EmptyMod} from "../../../Component/Redesign/EmptyMod";
import {getFinancialNumberWithCurrency} from "../../../Utils/helpers/reformat-number";
import {SOCIAL_CHANNEL_TYPES_LIST} from "../../../Utils/constant/Const";
import {CreatorOrderEdit} from "./order-edit";

const ListActions = () => (
	<TopToolbar>
		<CreateButton resource="settings/top-creator"/>
	</TopToolbar>
);

export const List = () => {
	return <ListMod
		empty={<EmptyMod withCreate={true} label="No Top Creators yet" route="settings/top-creator"/>}
		title="Top Creators"
		actions={<ListActions/>}
		resource="configs/top-creator"
	>
		<Datagrid bulkActionButtons={false}>
			<TextField source="name" sortable={false}/>
			<SelectField source="primary" choices={SOCIAL_CHANNEL_TYPES_LIST} sortable={false}/>
			<TextField source="followers" sortable={false}/>
			<FunctionField render={record => getFinancialNumberWithCurrency(record.marketValue ?? 0)} label="Market Value"/>
			<FunctionField render={() => <CreatorOrderEdit/>} label="Order"/>
			<EditButton resource="settings/top-creator"/>
			<DeleteWithConfirmButton color="primary" confirmTitle="Are you sure?"
															 confirmContent="Top creator will be deleted"
															 resource="configs/top-creator"
															 redirect={false}
			> DELETE </DeleteWithConfirmButton>
		</Datagrid>
	</ListMod>
}
