import {minValue, regex, required, TextInput, useNotify, useRecordContext, useRefresh, useUpdate} from "react-admin";
import {FormProvider, useForm} from "react-hook-form";
import React, {useState} from "react";
import {INTEGER_NUMBER_PATTERN} from "../../../Utils/regex/regex";

export const CreatorOrderEdit = () => {
	const record = useRecordContext()
	const {id, order} = record
	const notify = useNotify()
	const refresh = useRefresh()
	const [isValueChange, setIsValueChange] = useState(false)

	const form = useForm({
		defaultValues: {
			order,
		}
	})

	const [update] = useUpdate()

	const onBlur = () => {
		if (isValueChange) {
			updateOrder()
			setIsValueChange(false)
		}
	}

	const updateOrder = () => {
		const value = form.getValues().order
		update(
			`configs/top-creator`,
			{id, data: {order: value}, meta: {resourceSuffix: 'order'}},
			{
				onSuccess: () => {
					notify('Order updated')
				},
				onError: (err) => {
					notify(err.message, {type: 'error'})
					refresh()
				}
			}
		)
	}

	const onChange = () => {
		setIsValueChange(true)
	}


	return <FormProvider {...form}>
		<TextInput name="order"
							 source="order"
							 helperText={false}
							 validate={[required(), minValue(1), regex(INTEGER_NUMBER_PATTERN, 'Value must be an integer.')]}
							 onBlur={onBlur}
							 onChange={onChange}/>
	</FormProvider>
}
