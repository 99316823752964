import {Datagrid, FunctionField, Pagination, ReferenceManyField, SelectField, TextField,} from "react-admin";
import DateFieldMod from "../Redesign/DateFieldMod";
import {CreditLimitReasonStatus, CreditLimitStatus,} from "../../Utils/constant/Const";
import {getFinancialNumberWithCurrency} from "../../Utils/helpers/reformat-number";

export const CreditLimitHistory = (props) => {
    return (
        <>
            <h2>Credit limit request's history</h2>
            <ReferenceManyField
                record={props}
                source="id"
                reference="credit-limits"
                perPage={10}
                target="financialAccountId"
                pagination={<Pagination/>}
                sort={{field: "created_at", order: "DESC"}}
            >
                <Datagrid bulkActionButtons={false}>
                    <TextField label="Id" source="id"/>
                    <FunctionField
                        label="Credit limit"
                        source="amount"
                        sortBy="amount"
                        render={(record) =>
                            `${getFinancialNumberWithCurrency(record.amount || 0)}`
                        }
                    />
                    <FunctionField
                        label="Recommended credit limit"
                        source="amount"
                        sortBy="recommendedAmount"
                        render={(record) =>
                            record.recommendedAmount
                                ? `${getFinancialNumberWithCurrency(record.recommendedAmount)}`
                                : ""
                        }
                    />
                    <FunctionField
                        label="Initial total bank account(s) balance"
                        source="initialTotalBalance"
                        sortBy="initialTotalBalance"
                        render={(record) =>
                            record.initialTotalBalance
                                ? `${getFinancialNumberWithCurrency(
                                    record.initialTotalBalance
                                )}`
                                : ""
                        }
                    />
                    <DateFieldMod showTime label="Created at" source="created_at"/>
                    <SelectField
                        label={"Status"}
                        source="status"
                        choices={CreditLimitStatus}
                    />
                    <SelectField
                        label={"Failed reason"}
                        source="reason_status"
                        choices={CreditLimitReasonStatus}
                    />
                </Datagrid>
            </ReferenceManyField>
        </>
    );
};
