import React from 'react';
import {
	Create,
	regex,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useRedirect
} from 'react-admin';
import BooleanCheckboxMod from "../../Component/Redesign/BooleanCheckboxMod";
import {booleanToConditional, CONDITIONAL_STATUS, conditionalToBoolean} from "../../Utils/constant/Const";
import Button from "@mui/material/Button";
import {ROLES_CHOICES} from "../../Utils/constant/Permissions";
import {ADMIN_PASSWORD_PATTERN, EMAIL_PATTERN, NAME_PATTERN, PHONE_NUMBER_PATTERN} from "../../Utils/regex/regex";

const AdminFormToolbar = props => {
    const redirect = useRedirect();

    const onCancel = () => {
        redirect('/admins')
    }
    return (
        <Toolbar {...props}>
            <Button color="info" style={{marginRight: '1rem'}} onClick={onCancel}>Cancel</Button>
            <SaveButton/>
        </Toolbar>
    );
}

export const AdminCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" label="list" variant="outlined" encType="multipart/form-data"
                    toolbar={<AdminFormToolbar/>} sx={{'& .MuiFormControl-root ': {minWidth: '400px'}}}>
            <TextInput label="First Name" name="first_name" source="first_name"
                       validate={[required(), regex(NAME_PATTERN, 'Please enter a valid first name.')]}/>
            <TextInput label="Last Name" name="last_name" source="last_name"
                       validate={[required(), regex(NAME_PATTERN, 'Please enter a valid last name.')]}/>
            <TextInput label="Phone" name="phone" source="phone"
                       placeholder="+12345678900"
                       validate={[required(), regex(PHONE_NUMBER_PATTERN, 'Phone number must be US number: +12345678900')]}/>
            <TextInput label="Email" name="email" source="email"
                       validate={[required(), regex(EMAIL_PATTERN, 'Invalid email format')]}/>
            <TextInput label="Password" name="password" source="password"
                       validate={[required(), regex(ADMIN_PASSWORD_PATTERN, 'Min 12 symbols with at least 1 upper, lower, number and one special symbol')]}
                       sx={{
                           maxWidth: '400px',
                           '& .Mui-error': {wordWrap: 'break-word'}
                       }}
            />
            <SelectInput label="Role" name="role" source="role" choices={ROLES_CHOICES} validate={required()}
                         sx={{minWidth: 219}}/>
            <BooleanCheckboxMod label="Notifications" source="receive_notification"
                                defaultValue={CONDITIONAL_STATUS.NO}
                                format={value => conditionalToBoolean(value)}
                                parse={value => booleanToConditional(value)}/>
        </SimpleForm>
    </Create>
);
