import { maxValue, minValue, number, required } from "react-admin";

const defaultValidation = [required(), number(), minValue(0)];
export const calculatorConfigList = [
  {
    name: "baseBalanceCreditMultiplier",
    label: "Base balance credit multiplier",
    type: "number",
    validate: [...defaultValidation, maxValue(1)],
  },
  {
    name: "baseIncomeMultiplier",
    label: "Base income multiplier",
    type: "number",
    validate: [...defaultValidation, maxValue(1)],
  },
  {
    name: "baseMinBalanceThreshold",
    label: "Base min balance threshold",
    type: "number",
    validate: [...defaultValidation, maxValue(1000000)],
  },
];
