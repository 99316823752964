import React from 'react';
import {
    Datagrid,
    LoadingPage,
    SelectField,
    ShowButton,
    TextField,
    usePermissions,
} from 'react-admin';
import ListMod from '../../Component/Redesign/ListMod';
import { CardStatusSelection } from '../../Utils/constant/Const';
import UrlField from '../../Component/Redesign/UrlField';
import {
    getHighNoteOrganizationLink,
    getHighNoteProductId,
} from '../../Utils/constant/HighNote';
import DateFieldMod from '../../Component/Redesign/DateFieldMod';
import { hasAdminRole } from '../../Utils/helpers/permissions';

export const CardList = () => {
    const higNoteLink = getHighNoteOrganizationLink();
    const higNoteProductId = getHighNoteProductId();
    const { permissions, isLoading } = usePermissions();

    return !isLoading ? (
        <ListMod title="Card list" basePath="/cards">
            <Datagrid bulkActionButtons={ false }>
                <TextField label={ 'CARD ID' } source="id"/>
                <TextField label={ 'REQUEST ID' } source="request_id"/>
                { hasAdminRole(permissions) && [
                    <TextField key="user_name" label={ 'USER' } source="user_name"/>,
                    <TextField
                        key="user_email"
                        label={ 'USER EMAIL' }
                        source="user_email"
                    />,
                ] }
                <TextField label={ 'LAST 4' } source="last4"/>
                <SelectField label={ 'STATUS' } source="status" choices={ CardStatusSelection }/>
                <DateFieldMod
                    label="EXPIRED AT"
                    options={ { year: 'numeric', month: 'numeric' } }
                    source="expiration_date"
                />
                <DateFieldMod showTime label="CREATED AT" source="created_at"/>
                <UrlField
                    label="HIGHNOTE LINK"
                    source="external_id"
                    val={ `${ higNoteLink }/card-products/${ higNoteProductId }/payment-cards/` }
                    target="_blank"
                    rel="noopener noreferrer"
                />
                <ShowButton/>
            </Datagrid>
        </ListMod>
    ) : (
        <LoadingPage/>
    );
};
