import {Button, Confirm, ImageField, useRecordContext,} from "react-admin";
import get from 'lodash/get';
import DeleteIcon from "@mui/icons-material/Delete";
import React, {memo, useState} from "react";

const ImageFieldMod = ({source, onRemove, imageSx, buttonSx}) => {
	const record = useRecordContext()
	const item = get(record, source)
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)

	if (!item) return null
	return <div style={{position: 'relative', display: 'inline-flex'}}>
		<ImageField label="Avatar" source="user.s3Url" sx={imageSx}/>
		<Button sx={buttonSx} color="error"
						onClick={() => setOpenDeleteConfirm(true)}>
			<DeleteIcon/>
		</Button>
		<Confirm isOpen={openDeleteConfirm}
						 title="Attention"
						 content="Are you sure that you want remove user image?"
						 onConfirm={() => {
							 onRemove()
							 setOpenDeleteConfirm(false)
						 }}
						 onClose={() => setOpenDeleteConfirm(false)}/>
	</div>
}

export default memo(ImageFieldMod);
