import {
	Edit,
	regex,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRecordContext,
	useRefresh
} from 'react-admin';
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import {NAME_PATTERN, PHONE_NUMBER_PATTERN} from '../../Utils/regex/regex';
import React from 'react';
import {CREATOR_STATUS_TYPE, CreatorType, Gender, USER_STATUS_TYPE, UserStatus} from '../../Utils/constant/Const';

const UserEditToolbar = () => {
	const navigate = useNavigate();
	const goBack = () => navigate(-1);

	return (
		<Toolbar>
			<SaveButton/>
			<Button variant="outlined"
							color="secondary"
							style={{marginLeft: '1rem'}}
							onClick={goBack}>
				Cancel
			</Button>
		</Toolbar>
	)
}

const transform = ({id, firstName, lastName, status, phone, gender, creatorType}) => {

	return ({
		id,
		firstName,
		lastName,
		status,
		phone: phone?.replace('+', '') || '',
		gender,
		creatorType
	});
}

const UserEditForm = () => {
	const record = useRecordContext()
	const userForm = record?.user ? {...record.user, phone: record.user.phone ? `+${record.user.phone}` : ''} : null;
	const statuses = UserStatus.reduce((acc, status) => {
		if (status.id === USER_STATUS_TYPE.deleted) return acc;
		if (status.id === USER_STATUS_TYPE.pending) {
			return [...acc, {...status, disabled: true}]
		}
		return [...acc, status]
	}, []);

	const creators = CreatorType.filter((type) => type.id !== CREATOR_STATUS_TYPE.deleted)

	return <>
		<SimpleForm toolbar={<UserEditToolbar/>}
								record={userForm}
								sx={{'& .MuiFormControl-root ': {minWidth: '400px'}}}>
			<TextInput label="First Name"
								 name="firstName"
								 source="firstName"
								 validate={[required(), regex(NAME_PATTERN, 'Please enter a valid first name.')]}/>
			<TextInput label="Last Name"
								 name="lastName"
								 source="lastName"
								 validate={[required(), regex(NAME_PATTERN, 'Please enter a valid last name.')]}/>
			<TextInput label="Phone"
								 name="phone"
								 source="phone"
								 placeholder="+12345678900"
								 validate={[regex(PHONE_NUMBER_PATTERN, 'Phone number must be US number: +12345678900')]}/>
			<SelectInput label="Gender"
									 name="gender"
									 source="gender"
									 choices={Gender}
									 validate={required()}
									 sx={{minWidth: 219}}/>
			<SelectInput label="Creator type"
									 name="creatorType"
									 source="creatorType"
									 choices={creators}
									 validate={required()}
									 sx={{minWidth: 219}}/>
			<SelectInput label="Status"
									 name="status"
									 source="status"
									 choices={statuses}
									 validate={required()}
									 sx={{minWidth: 219}}/>
		</SimpleForm>
	</>
}
export const UserEdit = () => {
	const notify = useNotify();
	const refresh = useRefresh();
	const navigate = useNavigate();
	const onSuccess = () => {
		notify('User updated successfully')
		navigate(-1);
		refresh();
	}

	return (
		<Edit redirect="list"
					transform={transform}
					mutationMode="pessimistic"
					mutationOptions={{onSuccess}}>
			<UserEditForm/>
		</Edit>
	)
}
