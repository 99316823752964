import {SvgIcon} from "@mui/material";

export const ToggleHeaderColumn = (props) => {
    return (<SvgIcon fontSize="inherit" {...props} width="44" height="44" viewBox="0 0 44 44" fill="none">
        <path d="M2.77515 4.47227C2.77515 3.91998 3.22286 3.47227 3.77515 3.47227H11.9418V9.41991H2.77515V4.47227Z"
              fill="white"/>
        <path d="M12.5366 3.4723H21.7033V9.41994H12.5366V3.4723Z" fill="white"/>
        <path d="M22.2974 3.4723H31.464V9.41994H22.2974V3.4723Z" fill="#5694E1"/>
        <path d="M32.0586 3.4723H40.2253C40.7775 3.4723 41.2253 3.92001 41.2253 4.4723V9.41994H32.0586V3.4723Z"
              fill="white"/>
        <path d="M2.77515 10.0147H11.9418V15.9624H2.77515V10.0147Z" fill="white"/>
        <path d="M2.77515 16.5572H11.9418V22.5048H2.77515V16.5572Z" fill="white"/>
        <path d="M2.77515 23.0996H11.9418V29.0473H2.77515V23.0996Z" fill="white"/>
        <path d="M12.5366 10.0147H21.7033V15.9624H12.5366V10.0147Z" fill="white"/>
        <path d="M12.5366 23.0996H21.7033V29.0473H12.5366V23.0996Z" fill="white"/>
        <path d="M12.5366 16.5572H21.7033V22.5048H12.5366V16.5572Z" fill="white"/>
        <path d="M22.2974 23.0996H31.464V29.0473H22.2974V23.0996Z" fill="#5694E1"/>
        <path d="M32.0586 23.0996H41.2253V29.0473H32.0586V23.0996Z" fill="white"/>
        <path d="M22.2974 10.0147H31.464V15.9624H22.2974V10.0147Z" fill="#5694E1"/>
        <path d="M32.0586 10.0147H41.2253V15.9624H32.0586V10.0147Z" fill="white"/>
        <path d="M22.2974 16.5572H31.464V22.5048H22.2974V16.5572Z" fill="#5694E1"/>
        <path d="M32.0586 16.5572H41.2253V22.5048H32.0586V16.5572Z" fill="white"/>
        <path d="M11.9419 3.47227H12.5367V29.642H11.9419V3.47227Z" fill="#C7C7C7"/>
        <path d="M41.2253 9.41994L41.2253 10.0147L2.77519 10.0147L2.77519 9.41994L41.2253 9.41994Z" fill="#C7C7C7"/>
        <path d="M41.2253 15.9624L41.2253 16.5571L2.77519 16.5571L2.77519 15.9624L41.2253 15.9624Z" fill="#C7C7C7"/>
        <path d="M41.2253 22.5048L41.2253 23.0996L2.77519 23.0996L2.77519 22.5048L41.2253 22.5048Z" fill="#C7C7C7"/>
        <path d="M21.7034 3.47227H22.2981V29.642H21.7034V3.47227Z" fill="#C7C7C7"/>
        <path d="M31.4639 3.47227H32.0586V29.642H31.4639V3.47227Z" fill="#C7C7C7"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.18042 2.8775C2.62814 2.8775 2.18042 3.32522 2.18042 3.8775V28.642C2.18042 29.1943 2.62813 29.642 3.18042 29.642L40.8195 29.6421C41.3718 29.6421 41.8195 29.1943 41.8195 28.6421V3.8775C41.8195 3.32522 41.3718 2.8775 40.8195 2.8775H3.18042ZM41.2247 4.47227C41.2247 3.91998 40.777 3.47227 40.2247 3.47227L3.77518 3.47227C3.2229 3.47227 2.77518 3.91998 2.77518 4.47227V28.0473C2.77518 28.5996 3.2229 29.0473 3.77518 29.0473L40.2247 29.0473C40.777 29.0473 41.2247 28.5996 41.2247 28.0473V4.47227Z"
              fill="#7D7D7D"/>
        <path
            d="M27.2219 38.0277V32.0668H28.1161V34.6002H30.7985V32.0668H31.6926V38.0277H30.7985V35.4943H28.1161V38.0277H27.2219Z"
            fill="#5694E1"/>
        <rect x="22.0693" y="32.0472" width="3.42857" height="0.857143" fill="#5694E1"/>
        <rect x="22.0693" y="33.7615" width="3.42857" height="0.857143" fill="#5694E1"/>
        <rect x="22.0693" y="35.4758" width="3.42857" height="0.857143" fill="#5694E1"/>
        <rect x="22.0693" y="37.1901" width="3.42857" height="0.857143" fill="#5694E1"/>
    </SvgIcon>)
}
