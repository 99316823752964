export const DateTimeFormatString = (date) => {
    if (!date) {
        return null
    }
    if (date instanceof Date) {
        return date.toISOString();
    }

    /** fix for Safari (Date format must be YYYY-MM-DDTHH:mm:ss) */
    const indexOfSpace = date.indexOf(' ')
    if (indexOfSpace !== -1) {
        const utcDate = new Date(date.replace(/ /g, 'T'));
        const offset = new Date().getTimezoneOffset();
        utcDate.setMinutes(utcDate.getMinutes() - offset);
        return utcDate
    }
    return new Date(date);
}
