import { SettingsList } from "../../../Component/Settings/SettingsList";
import React from "react";
import { calculatorConfigList } from "./Constant/calculatorConfigList";
import { creditConfigList } from "./Constant/creditConfigList";

export const CreditSettings = () => {
  return (
    <>
      <SettingsList
        resource="configs/credit"
        title="Credit configs"
        pagination={false}
        configList={creditConfigList}
      />
      <SettingsList
        resource="configs/calculator"
        title=" "
        pagination={false}
        configList={calculatorConfigList}
      />
    </>
  );
};
