import React, {useState} from "react";
import {Datagrid, FunctionField, TextField, useNotify, useRefresh,} from "react-admin";
import ListMod from "../Redesign/ListMod";
import {getConfigInfo} from "../../Utils/helpers/getConfigInfo";
import Button from "@mui/material/Button";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import {UpdateSettingsModal} from "./UpdateSettingsModal";

export const SettingsList = ({resource, title, configList, ...rest}) => {
    const [open, setIsOpen] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState(null);
    const refresh = useRefresh();
    const notify = useNotify();
    const handleSelectConfig = (item) => {
        setIsOpen(true);
        setSelectedConfig(item);
    };

    const onSuccessUpdate = () => {
        refresh();
        notify("Success update");
    };

    return (
        <>
            <ListMod
                title={title}
                sort={{field: "name", order: "ASC"}}
                actions={false}
                resource={resource}
                {...rest}
                sx={{
                    marginTop: 3,
                }}
            >
                <Datagrid bulkActionButtons={false}>
                    <FunctionField
                        label="Name"
                        sortBy="name"
                        render={(record) =>
                            getConfigInfo(record.name, configList)?.label || ""
                        }
                    />
                    <TextField label="Value" source="value"/>
                    <FunctionField
                        label="Update"
                        render={(record) => (
                            <Button
                                startIcon={<ChangeCircleOutlinedIcon/>}
                                onClick={() => handleSelectConfig(record)}
                            >
                                Update
                            </Button>
                        )}
                    />
                </Datagrid>
            </ListMod>
            {open && !!selectedConfig && (
                <UpdateSettingsModal
                    item={selectedConfig}
                    configList={configList}
                    onSuccess={onSuccessUpdate}
                    onClose={() => setIsOpen(false)}
                />
            )}
        </>
    );
};
