import {SvgIcon} from "@mui/material";

export const AddRowAfter = (props) => {
    return (<SvgIcon fontSize="inherit" {...props} width="44" height="44" viewBox="0 0 44 44" fill="none">
        <path d="M2.7749 6.9411C2.7749 6.38882 3.22262 5.9411 3.7749 5.9411H11.9416V11.8887H2.7749V6.9411Z"
              fill="white"/>
        <path d="M12.5364 5.94113H21.703V11.8888H12.5364V5.94113Z" fill="white"/>
        <path d="M22.297 5.94113H31.4637V11.8888H22.297V5.94113Z" fill="white"/>
        <path d="M32.0583 5.94113H40.225C40.7773 5.94113 41.225 6.38885 41.225 6.94113V11.8888H32.0583V5.94113Z"
              fill="white"/>
        <path d="M2.7749 12.4836H11.9416V18.4312H2.7749V12.4836Z" fill="white"/>
        <path d="M2.7749 19.026H11.9416V24.9737H2.7749V19.026Z" fill="#D2E3F5"/>
        <path d="M2.7749 25.5685H11.9416V31.5161H2.7749V25.5685Z" fill="#84BA71"/>
        <path d="M2.7749 32.1109H11.9416V38.0586H2.7749V32.1109Z" fill="white"/>
        <path d="M12.5364 12.4836H21.703V18.4312H12.5364V12.4836Z" fill="white"/>
        <path d="M12.5364 25.5685H21.703V31.5161H12.5364V25.5685Z" fill="#84BA71"/>
        <path d="M12.5364 19.026H21.703V24.9737H12.5364V19.026Z" fill="#D2E3F5"/>
        <path d="M12.5364 32.1109H21.703V38.0586H12.5364V32.1109Z" fill="white"/>
        <path d="M22.297 25.5685H31.4637V31.5161H22.297V25.5685Z" fill="#84BA71"/>
        <path d="M32.0583 25.5685H41.225V31.5161H32.0583V25.5685Z" fill="#84BA71"/>
        <path d="M22.297 12.4836H31.4637V18.4312H22.297V12.4836Z" fill="white"/>
        <path d="M32.0583 12.4836H41.225V18.4312H32.0583V12.4836Z" fill="white"/>
        <path d="M22.297 19.026H31.4637V24.9737H22.297V19.026Z" fill="#D2E3F5"/>
        <path d="M32.0583 19.026H41.225V24.9737H32.0583V19.026Z" fill="#D2E3F5"/>
        <path d="M22.297 32.1109H31.4637V38.0586H22.297V32.1109Z" fill="white"/>
        <path d="M32.0583 32.1109H41.225V38.0586H32.0583V32.1109Z" fill="white"/>
        <path d="M11.9415 5.9411H12.5363V38.0585H11.9415V5.9411Z" fill="#C7C7C7"/>
        <path d="M41.2251 11.8888L41.2251 12.4835L2.77495 12.4835L2.77495 11.8888L41.2251 11.8888Z" fill="#C7C7C7"/>
        <path d="M41.2251 18.4312L41.2251 19.026L2.77495 19.026L2.77495 18.4312L41.2251 18.4312Z" fill="#C7C7C7"/>
        <path d="M41.2251 24.9737L41.2251 25.5684L2.77495 25.5684L2.77495 24.9737L41.2251 24.9737Z" fill="#C7C7C7"/>
        <path d="M41.2251 31.5161L41.2251 32.1109L2.77495 32.1109L2.77495 31.5161L41.2251 31.5161Z" fill="#C7C7C7"/>
        <path d="M21.703 5.9411H22.2978V38.0585H21.703V5.9411Z" fill="#C7C7C7"/>
        <path d="M31.4636 5.9411H32.0584V38.0585H31.4636V5.9411Z" fill="#C7C7C7"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M41.8198 6.34667C41.8198 5.79439 41.3721 5.34667 40.8198 5.34667H3.18018C2.62789 5.34667 2.18018 5.79439 2.18018 6.34667V37.6533C2.18018 38.2056 2.62789 38.6533 3.18017 38.6533H40.8198C41.3721 38.6533 41.8198 38.2056 41.8198 37.6533V6.34667ZM2.77491 37.0586C2.77491 37.6108 3.22263 38.0586 3.77491 38.0586H40.2251C40.7773 38.0586 41.2251 37.6108 41.2251 37.0586V6.94144C41.2251 6.38915 40.7773 5.94144 40.2251 5.94144H3.77491C3.22263 5.94144 2.77491 6.38915 2.77491 6.94144V37.0586Z"
              fill="#7D7D7D"/>
        <mask id="mask0_4_606" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="15" y="19" width="14"
              height="13">
            <rect x="27.51" y="30.781" width="11.02" height="11.02" transform="rotate(-180 27.51 30.781)" fill="#D9D9D9"
                  stroke="black"/>
        </mask>
        <g mask="url(#mask0_4_606)">
            <path
                d="M23.7034 24.7702L23.7034 25.0702L24.0034 25.0702L25.4524 25.0702C25.6212 25.0702 25.7141 25.2663 25.6072 25.3969L22.1549 29.6164C22.0748 29.7142 21.9253 29.7142 21.8453 29.6164L18.3929 25.3969C18.2861 25.2663 18.379 25.0702 18.5477 25.0702L19.9967 25.0702L20.2967 25.0702L20.2967 24.7702L20.2967 21.2635C20.2967 21.1531 20.3863 21.0635 20.4967 21.0635L23.5034 21.0635C23.6139 21.0635 23.7034 21.1531 23.7034 21.2635L23.7034 24.7702Z"
                fill="#84BA71" stroke="white" strokeWidth="0.6"/>
        </g>
    </SvgIcon>)
}
