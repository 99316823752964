import ListMod from "../../Component/Redesign/ListMod";
import {BooleanInput, Datagrid, FunctionField, ShowButton, TextField} from "react-admin";
import {Link} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DateFieldMod from "../../Component/Redesign/DateFieldMod";
import {getHighNoteOrganizationLink} from "../../Utils/constant/HighNote";
import {CONDITIONAL_STATUS} from "../../Utils/constant/Const";
import {makeStyles} from "tss-react/mui";
import {getFinancialNumberWithCurrency} from "../../Utils/helpers/reformat-number";

const useStyles = makeStyles()({
    list: {
        '& .MuiToolbar-root form': {
            padding: '.425rem 1rem',
        }
    },
});

const UserFilters = [
    <BooleanInput source="hasOutstandingBalance" type="checkbox" name="hasOutstandingBalance"
                  label="Outstanding balance more than 0"
                  format={value => value === CONDITIONAL_STATUS.YES}
                  parse={value => value ? CONDITIONAL_STATUS.YES : null} alwaysOn/>,
];


export const FinancialAccountList = () => {

    const higNoteLink = getHighNoteOrganizationLink();
    const {classes} = useStyles();
    return (
        <ListMod title="Financial accounts"
                 filters={UserFilters}
                 className={classes.list}
                 basePath="/financial-accounts"
        >
            <Datagrid bulkActionButtons={false}>
                <TextField label="ID" source="id" sortBy="id"/>
                <TextField label="NAME" source="name"/>
                <TextField label="USER ID" source="businessHolder.user.id" sortable={false}/>
                <FunctionField label="USER" render={data => {
                    const user = data?.businessHolder?.user;
                    return user ? `${user.first_name} ${user.last_name}` : null
                }}/>
                <FunctionField label="HIGHNOTE URL" render={(item) =>
                    <Link
                        href={`${higNoteLink}/card-products/${item?.product}/${item?.externalId}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        <OpenInNewIcon/>
                    </Link>}
                />
                <DateFieldMod showTime label="CREATED AT" source="createdAt"/>
                <FunctionField label="CREDIT LIMIT"
                               render={data => data.additionalData ? `${getFinancialNumberWithCurrency(data.additionalData.creditLimit)}` : ''}/>
                <DateFieldMod label="DATE CREDIT SET" source="additionalData.dateCreditSet" sortable={false}/>
                <FunctionField label="OUTSTANDING BALANCE"
                               render={item => item.additionalData ? `${getFinancialNumberWithCurrency(item.additionalData.outstandingBalance)}` : ''}/>
                <DateFieldMod showTime label="LAST REPAYMENT DATE" source="additionalData.lastRepaymentDate"
                              sortable={false}/>
                <ShowButton/>
            </Datagrid>
        </ListMod>
    );
}
