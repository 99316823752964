import {regex, required, TextInput, useNotify, useRecordContext, useRefresh, useUpdate} from "react-admin";
import {FormProvider, useForm} from "react-hook-form";
import {CircularProgress, Dialog, DialogContent, DialogTitle} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import MuiButton from "@mui/material/Button";
import React, {memo} from "react";
import {SOCIAL_CHANNEL_TYPES} from "../../Utils/constant/Const";

const SPOTIFY_ARTIST_LINK_PATTERN = /^(http(s)?:\/\/)?open\.spotify\.com\/artist\/[a-zA-Z0-9]+/;
const YOUTUBE_MUSIC_ARTIST_LINK_PATTERN = /^(http(s)?:\/\/)?music\.youtube\.com\/channel\/[a-zA-Z0-9-_]+/;

const EditLinkModal = ({closeModal}) => {
	const record = useRecordContext()
	const refresh = useRefresh()
	const notify = useNotify()
	const [updateLink, {isLoading}] = useUpdate()

	const type = record.channel.type
	const linkPattern = type === SOCIAL_CHANNEL_TYPES.spotify ?
		SPOTIFY_ARTIST_LINK_PATTERN :
		type === SOCIAL_CHANNEL_TYPES.youtubeMusic ?
			YOUTUBE_MUSIC_ARTIST_LINK_PATTERN
			: null

	const form = useForm({
		values: {
			link: record.channel.socialChannelLink
		},
		shouldFocusError: true,
		mode: 'onChange',
		reValidateMode: 'onChange'
	})

	const editChannelLink = ({link}) => {
		if (isLoading) {
			return
		}
		updateLink(
			'social-networks/change-link',
			{
				id: record.channel.id,
				data: {link},
				meta: {resourceSuffix: ''},
			},
			{
				onSuccess: () => {
					refresh()
					notify('Channel link updated')
					closeModal()
				},
				onError: (error) => {
					refresh()
					form.setError('link', {type: 'manual', message: error.message})
				}
			}).then()

	}


	return <Dialog open={true} disableEnforceFocus={true}>
		<DialogTitle>Edit user link</DialogTitle>
		<DialogContent sx={{width: '400px'}}>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(editChannelLink)}>
					<TextInput name="link"
										 source="link"
										 fullWidth
										 disabled={isLoading}
										 helperText={false}
										 validate={[required(), linkPattern ? regex(linkPattern, 'Enter valid artist link') : () => null]}/>

					<DialogActions sx={{gap: '8px'}}>
						<MuiButton onClick={closeModal} disabled={isLoading}>Cancel</MuiButton>
						<MuiButton variant="contained"
											 type="submit"
											 color="primary"
											 disabled={isLoading || !form.formState.isValid}>
							{isLoading && <CircularProgress size={25} thickness={2}/>}
							Save
						</MuiButton>
					</DialogActions>
				</form>
			</FormProvider>
		</DialogContent>
	</Dialog>
}

export default memo(EditLinkModal)
