import {required, SelectInput, useNotify, useRecordContext, useRefresh, useUpdate} from 'react-admin';
import {FormProvider, useForm} from 'react-hook-form';
import React from 'react';
import {DISPUTE_STATUS} from '../../../Utils/constant/Const';

export const DisputeStatusEdit = () => {
	const {id, status} = useRecordContext();
	const notify = useNotify();
	const refresh = useRefresh();

	const form = useForm({
		defaultValues: {
			status
		}
	})

	const [update, {isLoading}] = useUpdate();

	const onUpdate = (e) => {
		if (isLoading) {
			return
		}
		const value = e.target.value
		update(
			`disputes`,
			{
				id,
				data: {status: value},
				meta: {resourceSuffix: 'status'},
			},
			{
				onSuccess: () => {
					notify('Status updated')
				},
				onError: (error) => {
					notify(error.message, {type: 'error'})
					refresh()
				}
			}
		)
	}

	return (
		<FormProvider {...form}>
			<SelectInput name="status"
									 source="status"
									 helperText={false}
									 validate={required()}
									 choices={DISPUTE_STATUS}
									 onChange={onUpdate}/>
		</FormProvider>
	)
}
