import React from 'react';
import ListMod from '../../Component/Redesign/ListMod';
import { AdminsListDatagrid } from './AdminsListDatagrid';

export const AdminList = ({ permissions }) => {

    return (
        <ListMod title="Admin list" basePath="/admins">
            <AdminsListDatagrid/>
        </ListMod>
    );
}
