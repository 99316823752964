export const FINANCIAL_NUMBER_PATTERN = /^\d*(?:\.\d{1,2})?$/;

export const PHONE_NUMBER_PATTERN = /^\+1([0-9]){10}$/;

export const ADMIN_PASSWORD_PATTERN = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{12,}$/);

export const EMAIL_PATTERN = new RegExp(/^[a-zA-Z0-9+]+[a-zA-Z0-9._+-]*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const NAME_PATTERN = new RegExp(/^[a-zA-Z]([a-zA-Z '`-]{0,64})$/);

export const INTEGER_NUMBER_PATTERN = /^\d+$/;

export const LATIN_SYMBOLS_PATTERN = new RegExp(/^[a-zA-Z0-9 '"`\-,.()!@#$%&*^?:=+]*$/);

export const PASSWORD_PATTERN = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{12,}$/);
