import {Labeled, useNotify, useRedirect, useRefresh} from 'react-admin';
import {SetCreditLimitModal} from './SetCreditLimitModal';
import {Dialog, DialogTitle, Typography} from '@mui/material';
import React, {useState} from 'react';
import Button from '@mui/material/Button';
import {FinancialRepaymentModal} from '../FinancialRepaymentForm/FinancialRepaymentModal';
import {getFinancialNumberWithCurrency} from '../../Utils/helpers/reformat-number';
import {isBlockedCard} from '../../Utils/helpers/isBlockedCard';
import {InformationDialog} from "../InformationDialog/InformationDialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export const CreditLimit = ({additionalData, holderId, cardStatus, user}) => {

	const refresh = useRefresh();
	const notify = useNotify();
	const redirect = useRedirect();
	const [openRepayment, setRepaymentModalOpen] = useState(false);
	const [openCreditLimit, setCreditLimitModalOpen] = useState(false);
	const [openFeeErrorModal, setOpenFeeErrorModal] = useState(false);
	const [openTrialWarningModal, setOpenTrialWarningModal] = useState(false);
	const {creditLimit, outstandingBalance, availableCredit} = additionalData || {};
	const isCardBlocked = isBlockedCard(cardStatus);

	const onUpdateCreditLimit = () => {
		refresh();
		notify('Request sent');
		setCreditLimitModalOpen(false)
	}

	const onRepay = () => {
		refresh();
		notify('Request sent');
		setRepaymentModalOpen(false);
		redirect(`repayments`)
	}

	const onSetCreditLimit = () => {
		if (user?.activeSubscription) {
			setCreditLimitModalOpen(true)
			return
		}
		if (user?.membershipTrail && user?.membershipTrailDaysLeft >= 0) {
			setOpenTrialWarningModal(true)
			return
		}
		setOpenFeeErrorModal(true)
	}

	const onConfirmSetLimit = () => {
		setOpenTrialWarningModal(false)
		setCreditLimitModalOpen(true)
	}

	return (<>
		{
			creditLimit === undefined ?
				(
					<div>
						{user?.id &&
							<Button onClick={() => onSetCreditLimit()} disabled={isCardBlocked}
											variant="contained" color="primary">
								Set credit limit
							</Button>
						}
						{
							openCreditLimit && <SetCreditLimitModal id={user?.id}
																											amount={1}
																											onClose={() => setCreditLimitModalOpen(false)}
																											onSuccess={onUpdateCreditLimit}/>
						}

					</div>
				) :
				<>
					<div className="ra-field">
						<Labeled label="Credit Limit">
							<Typography style={{padding: '8px 0 4px'}}
													variant="body2">
								{getFinancialNumberWithCurrency(creditLimit || 0)}
								{user?.id &&
									<Button onClick={() => onSetCreditLimit()}
													disabled={isCardBlocked}
													variant="contained"
													color="primary"
													style={{marginLeft: '1rem'}}>
										Set credit limit
									</Button>
								}
								{
									openCreditLimit && <SetCreditLimitModal id={user?.id}
																													amount={creditLimit || 0}
																													minAmount={outstandingBalance || 0}
																													onClose={() => setCreditLimitModalOpen(false)}
																													onSuccess={onUpdateCreditLimit}/>
								}
							</Typography>
						</Labeled>
					</div>
					<div className="ra-field">
						<Labeled label="Available Credit">
							<Typography style={{padding: '8px 0 4px'}}
													variant="body2">{getFinancialNumberWithCurrency(availableCredit || 0)}</Typography>
						</Labeled>
					</div>
					<div className="ra-field">
						<Labeled label="Outstanding balance">
							<Typography style={{padding: '8px 0 4px'}}
													variant="body2">
								{getFinancialNumberWithCurrency(outstandingBalance || 0)}
								{holderId &&
									<Button onClick={() => setRepaymentModalOpen(true)} disabled={!outstandingBalance}
													variant="contained" color="primary" style={{marginLeft: '1rem'}}>
										Repay
									</Button>
								}
								{openRepayment &&
									<FinancialRepaymentModal outstandingBalance={outstandingBalance} holderId={holderId}
																					 onClose={() => setRepaymentModalOpen(false)}
																					 onSuccess={onRepay}/>}
							</Typography>
						</Labeled>
					</div>
				</>
		}
		<InformationDialog open={openFeeErrorModal} onClose={() => setOpenFeeErrorModal(false)}
											 title="Set credit limit"
											 text="User does not have an active subscription or trial period has been finished already."/>
		{openTrialWarningModal &&
			<Dialog open={true} onClose={() => setOpenTrialWarningModal(false)} disableEnforceFocus={true}
							sx={{'& .MuiPaper-root': {maxWidth: '400px', width: '100%'}}}>
				<DialogTitle color="error">Warning</DialogTitle>
				<DialogContent>
					<span style={{fontWeight: '500'}}>User does not have an active subscription</span>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenTrialWarningModal(false)}>Cancel</Button>
					<Button variant="contained" color="primary" onClick={() => onConfirmSetLimit()}>Set limit</Button>
				</DialogActions>
			</Dialog>
		}
	</>)
}
