import React from 'react';
import {Datagrid, EditButton, FunctionField, TextField, Toolbar, useListContext, useRecordContext} from 'react-admin';
import DateFieldMod from '../../Component/Redesign/DateFieldMod';
import {AdminRoleEdit} from './AdminRoleEdit';
import {AdminNotificationStatusItem} from './AdminNotificationStatusItem';
import {ROLE_SUPER_ADMIN} from '../../Utils/constant/Permissions';
import Spinner from '../../Component/Spinner/Spinner';

const CustomToolbar = () => {

    const {role} = useRecordContext();

    return (
        <Toolbar sx={{backgroundColor: 'transparent'}}>
            {
                (role !== ROLE_SUPER_ADMIN) && <EditButton/>
            }
        </Toolbar>
    );
}

export const AdminsListDatagrid = () => {

    const {isFetching} = useListContext();

    if (isFetching) {
        return <Spinner/>
    }

    return (
        <Datagrid bulkActionButtons={false}>
            <TextField label={'ID'} source="id"/>
            <TextField label={'FIRST NAME'} source="first_name"/>
            <TextField label={'LAST NAME'} source="last_name"/>
            <TextField label={'EMAIL'} source="email"/>
            <DateFieldMod showTime label="CREATED AT" source="created_at"/>
            <FunctionField label={'ROLE'}
                           render={() => <AdminRoleEdit/>}/>
            <FunctionField label={'NOTIFICATIONS'}
                           render={() => <AdminNotificationStatusItem/>}/>
            <CustomToolbar/>
        </Datagrid>
    )
}
