import {SvgIcon} from "@mui/material";

export const AddTableIcon = (props) => {
    return (<SvgIcon fontSize="inherit" {...props} width="44" height="44" viewBox="0 0 44 44" fill="none">
        <path d="M3.56232 7.7746C3.56232 6.76208 4.38313 5.94127 5.39565 5.94127H15.4576V11.8889H3.56232V7.7746Z"
              fill="#5694E1"/>
        <path d="M16.0524 5.94128H27.9477V11.8889H16.0524V5.94128Z" fill="#5694E1"/>
        <path d="M28.5425 5.94128H38.6044C39.617 5.94128 40.4378 6.7621 40.4378 7.77462V11.8889H28.5425V5.94128Z"
              fill="#5694E1"/>
        <path d="M3.56232 12.4837H15.4576V18.4314H3.56232V12.4837Z" fill="white"/>
        <path d="M3.56232 19.0262H15.4576V24.9738H3.56232V19.0262Z" fill="white"/>
        <path d="M3.56232 25.5686H15.4576V31.5163H3.56232V25.5686Z" fill="white"/>
        <path d="M3.56232 32.1111H15.4576V38.0587H3.56232V32.1111Z" fill="white"/>
        <path d="M16.0524 12.4837H27.9477V18.4314H16.0524V12.4837Z" fill="white"/>
        <path d="M16.0524 25.5686H27.9477V31.5163H16.0524V25.5686Z" fill="white"/>
        <path d="M16.0524 19.0262H27.9477V24.9738H16.0524V19.0262Z" fill="white"/>
        <path d="M16.0524 32.1111H27.9477V38.0587H16.0524V32.1111Z" fill="white"/>
        <path d="M28.5425 25.5686H40.4378V31.5163H28.5425V25.5686Z" fill="white"/>
        <path d="M28.5425 12.4837H40.4378V18.4314H28.5425V12.4837Z" fill="white"/>
        <path d="M28.5425 19.0262H40.4378V24.9738H28.5425V19.0262Z" fill="white"/>
        <path d="M28.5425 32.1111H40.4378V38.0587H28.5425V32.1111Z" fill="white"/>
        <path d="M15.4576 5.94127H16.0524V38.0587H15.4576V5.94127Z" fill="#C7C7C7"/>
        <path d="M40.4378 11.8889L40.4378 12.4837L3.56234 12.4837L3.56234 11.8889L40.4378 11.8889Z" fill="#C7C7C7"/>
        <path d="M40.4378 18.4314L40.4378 19.0262L3.56234 19.0262L3.56234 18.4314L40.4378 18.4314Z" fill="#C7C7C7"/>
        <path d="M40.4378 24.9738L40.4378 25.5686L3.56234 25.5686L3.56234 24.9738L40.4378 24.9738Z" fill="#C7C7C7"/>
        <path d="M40.4377 31.5163L40.4377 32.1111L3.56228 32.1111L3.56228 31.5163L40.4377 31.5163Z" fill="#C7C7C7"/>
        <path d="M27.9477 5.94127H28.5425V38.0587H27.9477V5.94127Z" fill="#C7C7C7"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M41.0325 7.17987C41.0325 6.16735 40.2117 5.34654 39.1991 5.34654L4.80091 5.34653C3.78839 5.34653 2.96758 6.16734 2.96758 7.17986L2.96753 36.8202C2.96753 37.8327 3.78834 38.6535 4.80086 38.6535H39.1991C40.2117 38.6535 41.0325 37.8327 41.0325 36.8202V7.17987ZM40.4377 7.77463C40.4377 6.76211 39.6169 5.9413 38.6044 5.9413L5.39568 5.9413C4.38316 5.9413 3.56235 6.76211 3.56235 7.77463V36.2254C3.56235 37.2379 4.38316 38.0587 5.39568 38.0587H38.6044C39.6169 38.0587 40.4377 37.2379 40.4377 36.2254V7.77463Z"
              fill="#7D7D7D"/>
    </SvgIcon>)
}
