import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const InformationDialog = ({title, text, open, onClose}) => {

	const handleClose = () => {
		onClose(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="xs"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{title}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description" style={{color: 'rgba(0, 0, 0, 0.87)'}}>
					{text}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>OK</Button>
			</DialogActions>
		</Dialog>
	);

};
