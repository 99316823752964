import {SvgIcon} from "@mui/material";

export const AddColumnAfter = (props) => {
    return (<SvgIcon fontSize="inherit" {...props} width="44" height="44" viewBox="0 0 44 44" fill="none">
        <path d="M2.7749 6.9411C2.7749 6.38882 3.22262 5.9411 3.7749 5.9411H11.9416V11.8887H2.7749V6.9411Z"
              fill="white"/>
        <path d="M12.5363 5.94113H21.703V11.8888H12.5363V5.94113Z" fill="#D2E3F5"/>
        <path d="M22.297 5.94113H31.4637V11.8888H22.297V5.94113Z" fill="#84BA71"/>
        <path d="M32.0584 5.94113H40.2251C40.7774 5.94113 41.2251 6.38885 41.2251 6.94113V11.8888H32.0584V5.94113Z"
              fill="white"/>
        <path d="M2.7749 12.4836H11.9416V18.4312H2.7749V12.4836Z" fill="white"/>
        <path d="M2.7749 19.026H11.9416V24.9737H2.7749V19.026Z" fill="white"/>
        <path d="M2.7749 25.5685H11.9416V31.5161H2.7749V25.5685Z" fill="white"/>
        <path d="M2.7749 32.1109H11.9416V38.0586H2.7749V32.1109Z" fill="white"/>
        <path d="M12.5363 12.4836H21.703V18.4312H12.5363V12.4836Z" fill="#D2E3F5"/>
        <path d="M12.5363 25.5685H21.703V31.5161H12.5363V25.5685Z" fill="#D2E3F5"/>
        <path d="M12.5363 19.026H21.703V24.9737H12.5363V19.026Z" fill="#D2E3F5"/>
        <path d="M12.5363 32.1109H21.703V38.0586H12.5363V32.1109Z" fill="#D2E3F5"/>
        <path d="M22.297 25.5685H31.4637V31.5161H22.297V25.5685Z" fill="#84BA71"/>
        <path d="M32.0584 25.5685H41.2251V31.5161H32.0584V25.5685Z" fill="white"/>
        <path d="M22.297 12.4836H31.4637V18.4312H22.297V12.4836Z" fill="#84BA71"/>
        <path d="M32.0584 12.4836H41.2251V18.4312H32.0584V12.4836Z" fill="white"/>
        <path d="M22.297 19.026H31.4637V24.9737H22.297V19.026Z" fill="#84BA71"/>
        <path d="M32.0584 19.026H41.2251V24.9737H32.0584V19.026Z" fill="white"/>
        <path d="M22.297 32.1109H31.4637V38.0586H22.297V32.1109Z" fill="#84BA71"/>
        <path d="M32.0584 32.1109H41.2251V38.0586H32.0584V32.1109Z" fill="white"/>
        <path d="M11.9416 5.9411H12.5364V38.0585H11.9416V5.9411Z" fill="#C7C7C7"/>
        <path d="M41.225 11.8888L41.225 12.4835L2.77489 12.4835L2.77489 11.8888L41.225 11.8888Z" fill="#C7C7C7"/>
        <path d="M41.225 18.4312L41.225 19.026L2.77489 19.026L2.77489 18.4312L41.225 18.4312Z" fill="#C7C7C7"/>
        <path d="M41.225 24.9737L41.225 25.5684L2.77489 25.5684L2.77489 24.9737L41.225 24.9737Z" fill="#C7C7C7"/>
        <path d="M41.225 31.5161L41.225 32.1109L2.77489 32.1109L2.77489 31.5161L41.225 31.5161Z" fill="#C7C7C7"/>
        <path d="M21.703 5.9411H22.2978V38.0585H21.703V5.9411Z" fill="#C7C7C7"/>
        <path d="M31.4637 5.9411H32.0584V38.0585H31.4637V5.9411Z" fill="#C7C7C7"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M41.8198 6.34667C41.8198 5.79439 41.3721 5.34667 40.8198 5.34667H3.18018C2.62789 5.34667 2.18018 5.79439 2.18018 6.34667V37.6533C2.18018 38.2056 2.62789 38.6533 3.18017 38.6533H40.8198C41.3721 38.6533 41.8198 38.2056 41.8198 37.6533V6.34667ZM2.77491 37.0586C2.77491 37.6108 3.22263 38.0586 3.77491 38.0586H40.2251C40.7773 38.0586 41.2251 37.6108 41.2251 37.0586V6.94144C41.2251 6.38915 40.7773 5.94144 40.2251 5.94144H3.77491C3.22263 5.94144 2.77491 6.38915 2.77491 6.94144V37.0586Z"
              fill="#7D7D7D"/>
        <mask id="mask0_4_381" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="15" y="15" width="14"
              height="14">
            <rect x="-0.5" y="-0.5" width="11.0228" height="11.0228"
                  transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 27.0114 27.0114)" fill="#D9D9D9" stroke="black"/>
        </mask>
        <g mask="url(#mask0_4_381)">
            <path
                d="M21.4991 23.7038L21.7991 23.7038L21.7991 24.0038L21.7991 25.4535C21.7991 25.6222 21.9951 25.7151 22.1257 25.6082L26.3466 22.1548C26.4444 22.0748 26.4444 21.9252 26.3466 21.8452L22.1257 18.3918C21.9951 18.2849 21.7991 18.3779 21.7991 18.5466L21.7991 19.9962L21.7991 20.2962L21.4991 20.2962L17.9915 20.2962C17.881 20.2962 17.7915 20.3858 17.7915 20.4962L17.7915 23.5038C17.7915 23.6143 17.881 23.7038 17.9915 23.7038L21.4991 23.7038Z"
                fill="#84BA71" stroke="white" strokeWidth="0.6"/>
        </g>
    </SvgIcon>)
}


