import React, { useState } from 'react';
import {
    Form,
    FunctionField,
    Labeled,
    LoadingIndicator,
    minValue,
    number,
    regex,
    required,
    SaveButton,
    TextInput,
    useDataProvider
} from 'react-admin';
import InformationErrorDialog from '../InformationDialog/InformationErrorDialog';
import { Dialog, DialogTitle, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useGet } from '../../Hooks/useGet';
import { isNumber } from 'lodash';
import { getFinancialNumberWithCurrency } from '../../Utils/helpers/reformat-number';
import { FINANCIAL_NUMBER_PATTERN } from '../../Utils/regex/regex';

export const SetCreditLimitModal = ({ id, amount, onSuccess, onClose, minAmount = 1 }) => {
    const [ loading, setLoading ] = React.useState(false);
    const [ errorModalState, setErrorModalState ] = useState({ errors: null, open: false });

    const handleOpenError = (errors) => {
        setErrorModalState(() => ({ errors, open: true }));
    };

    const { data, isLoading } = useGet('credit-limits/calculate', { id })
    const handleCloseError = () => {
        setErrorModalState({ errors: null, open: false });
    };

    const dataProvider = useDataProvider();

    const handleSubmit = (values) => {
        setLoading(true);
        const { amount } = values
        dataProvider.update(
            'financial-accounts',
            {
                id,
                data: {
                    amount,
                },
                meta: {
                    resourceSuffix: 'update-credit-limit',
                }
            },
        ).then(() => {
            setLoading(false);
            onClose();
            onSuccess(amount);
        }).catch((err) => {
            setLoading(false);
            handleOpenError(err);
        })
    }

    const labelSX = {
        fontWeight: 700,
        fontSize: '1rem',
        color: '#000',
    }

    return (
        <>
            <Dialog open={ true } onClose={ onClose } disableEnforceFocus={ true }
                    sx={ { '& .MuiPaper-root': { width: '400px' } } }>
                <Form defaultValues={ { amount: amount || 0 } } mode="all" onSubmit={ handleSubmit }>
                    <DialogTitle>New credit limit</DialogTitle>
                    <DialogContent>
                        { isLoading ?
                            <LoadingIndicator/> :
                            <div style={ { display: 'flex', flexDirection: 'column' } }>
                                <Labeled component="div"
                                         label={
                                             <Typography component="span" variant="body1" sx={ labelSX }>
                                                 Сurrent balance
                                             </Typography>
                                         }>
                                    <FunctionField
                                        render={ () => isNumber(data?.currentTotalBalance) ? `${ getFinancialNumberWithCurrency(data.currentTotalBalance) }` : 'No data' }/>
                                </Labeled>
                                <Labeled component="div"
                                         label={
                                             <Typography component="span" variant="body1" sx={ labelSX }>
                                                 Referral bonus
                                             </Typography>
                                         }>
                                    <FunctionField
                                        render={ () => isNumber(data?.referralBonus) ? `${ getFinancialNumberWithCurrency(data.referralBonus) }` : 'No data' }/>
                                </Labeled>
                                <Labeled component="div"
                                         label={
                                             <Typography component="span"
                                                         variant="body1"
                                                         sx={ labelSX }>
                                                 Total income
                                             </Typography>
                                         }>
                                    <FunctionField
                                        render={ () => isNumber(data?.pastMonthIncome) ? `${ getFinancialNumberWithCurrency(data.pastMonthIncome) }` : 'No data' }/>
                                </Labeled>
                                <Labeled component="div"
                                         label={ <Typography component="span" variant="body1" sx={ labelSX }>
                                             Recommended credit limit
                                         </Typography> }>
                                    <FunctionField color="primary" style={ { fontWeight: 'bold' } }
                                                   render={ () => isNumber(data?.creditAmount) ? `${ getFinancialNumberWithCurrency(data?.creditAmount) }` : 'No data' }/>
                                </Labeled>
                                <Labeled component="div"
                                         label={
                                             <Typography component="span" variant="body1" sx={ labelSX }>
                                                 New credit limit
                                             </Typography>
                                         }>
                                    <TextInput
                                        source="amount"
                                        name="amount"
                                        variant="outlined"
                                        size="medium"
                                        validate={ [ required(), number(), minValue(minAmount), regex(FINANCIAL_NUMBER_PATTERN, 'Value must be in financial number format with dot separator') ] }
                                        fullWidth={ true }
                                        autoFocus
                                    />
                                </Labeled>
                            </div>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ onClose }>Cancel</Button>
                        <SaveButton disabled={ loading }/>
                    </DialogActions>
                </Form>
            </Dialog>
            {
                errorModalState.errors && <InformationErrorDialog { ...errorModalState } onClose={ handleCloseError }/>
            }
        </>
    );
};
