import { Loading } from 'ra-ui-materialui';
import React from 'react';

const Spinner = () => {
    return (
        <div style={ {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
        } }>
            <Loading/>
        </div>
    )
}

export default Spinner;
