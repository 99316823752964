import React, {useState} from 'react';
import {
	Datagrid,
	DeleteWithConfirmButton,
	FunctionField,
	LoadingPage,
	ReferenceManyField,
	RefreshIconButton,
	SelectField,
	Show,
	ShowButton,
	SimpleShowLayout,
	TabbedShowLayout,
	TabbedShowLayoutTabs,
	TextField,
	TopToolbar,
	useDataProvider,
	useNotify,
	usePermissions,
	useRecordContext,
	useRefresh,
	useUpdate,
} from 'react-admin';
import {ApplicationStatus, CardStatusSelection, HOLDER_STATUS, HolderState} from "../../Utils/constant/Const";
import DateFieldMod from "../../Component/Redesign/DateFieldMod";
import UrlField from "../../Component/Redesign/UrlField";
import InformationErrorDialog from "../../Component/InformationDialog/InformationErrorDialog";
import {getHighNoteOrganizationLink} from "../../Utils/constant/HighNote";
import CustomErrorFormField from "../../Component/CustomErrorFormField/CustomErrorFormField";
import {HolderApplicationVerificationShow} from "./holder-application-verification-show";
import Button from "@mui/material/Button";
import {hasAdminRole} from "../../Utils/helpers/permissions";
import {Dialog, DialogTitle} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const PostShowActions = () => {
	const data = useRecordContext()
	const refresh = useRefresh();
	const notify = useNotify()
	const dataProvider = useDataProvider();

	const [errorModalState, setErrorModalState] = useState({errors: null, open: false});
	const [openWarningFeeModal, setOpenWarningFeeModal] = useState(false);


	const handleOpenError = (errors) => {
		setErrorModalState(() => ({errors, open: true}));
	};

	const handleCloseError = () => {
		setErrorModalState({errors: null, open: false});
	};


	const [declineUpdateAll] = useUpdate(
		'holders/decline',
		{id: data?.id || '', data: {}},
		{
			onSuccess: () => {
				refresh()
				notify('Request declined')
			}, onError: (err) => {
				handleOpenError(err);
			}
		})

	const [approveUpdateAll] = useUpdate(
		'holders/approve',
		{id: data?.id || '', data: {}},
		{
			onSuccess: () => {
				refresh();
				notify('Request approved')
			}, onError: (err) => {
				handleOpenError(err);
			}
		});

	const [createCard] = useUpdate(
		'holders/create-card',
		{id: data ? data.id : '', data: {}},
		{
			onSuccess: () => {
				refresh()
				notify('Card created')
			},
			onError: (err) => {
				notify(err.message, {type: 'error'})
			}
		});

	const onCreateCard = () => {
		if (data?.activeSubscription) {
			createCard()
			return
		}
		setOpenWarningFeeModal(true)
	}

	const onConfirmCreateCard = () => {
		setOpenWarningFeeModal(false)
		createCard()
	}

	const [sentErrors] = useUpdate(
		'holders/request-error',
		{id: data ? data.id : '', data: {}, meta: {resourceSuffix: 'send'}},
		{
			onSuccess: () => {
				refresh()
				notify('Request sent to user')
			},
			onError: (err) => {
				notify(err.message, {type: 'error'})
			}

		});

	const statusCheck = () => {
		dataProvider.get('cards/status/check')
			.then(() => {
				refresh()
				notify('Card status updated')
			})
	}

	if (!data || !data.holder) {
		return null;
	}

	return (<TopToolbar>
		{<>
			{data?.holder?.status === HOLDER_STATUS.declined && <div>
				<Button color="primary" disabled> REQUEST DECLINED </Button>
			</div>}
			{data?.holder?.status === HOLDER_STATUS.review && <div>
				<Button color="primary" disabled> Send to user </Button>
			</div>}
			{data?.holder?.status === HOLDER_STATUS.pending && <div>
				<DeleteWithConfirmButton color="primary" confirmTitle="Are you sure?"
																 confirmContent="Current card request will be deleted"
																 record={data}
																 resource="holders/delete"
																 redirect="list"
				> DELETE </DeleteWithConfirmButton>
				{data?.requestErrors?.length ?
					<Button color="secondary" onClick={() => sentErrors()}> SEND USER ERRORS</Button> : (<>
						<Button color="primary" onClick={() => declineUpdateAll()}> DECLINE </Button>
						<Button color="primary" onClick={() => approveUpdateAll()}> APPROVE </Button>
					</>)}
			</div>}
			{data?.holder?.status === HOLDER_STATUS.highNoteReview && <div>
				<Button color="primary" disabled> MANUAL REVIEW </Button>
			</div>}
			{data?.holder?.status === HOLDER_STATUS.inProgress && <div>
				<Button color="primary" disabled> IN PROGRESS </Button>
				<RefreshIconButton color="primary" onClick={() => statusCheck()}> </RefreshIconButton>
			</div>}
			{data?.holder?.status === HOLDER_STATUS.approved && <div>
				<Button color="primary" onClick={() => onCreateCard()}> CREATE CARD </Button>
			</div>}
			{data?.holder?.status === HOLDER_STATUS.completed && <div>
				<Button color="primary" disabled> CARD CREATED </Button>
			</div>}

		</>
		}
		{errorModalState.errors && <InformationErrorDialog {...errorModalState} onClose={handleCloseError}/>}
		{openWarningFeeModal &&
			<Dialog open={true} onClose={() => setOpenWarningFeeModal(false)} disableEnforceFocus={true}
							sx={{'& .MuiPaper-root': {maxWidth: '400px', width: '100%'}}}>
				<DialogTitle color="error">Warning</DialogTitle>
				<DialogContent>
					<span style={{fontWeight: '500'}}>The user does not have an active subscription</span>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenWarningFeeModal(false)}>Cancel</Button>
					<Button variant="contained" color="primary" onClick={() => onConfirmCreateCard()}>Create</Button>
				</DialogActions>
			</Dialog>
		}
	</TopToolbar>)
};

export const AccountHoldersShow = () => {
	const higNoteLink = getHighNoteOrganizationLink();
	const {permissions, isLoading} = usePermissions();

	return !isLoading ?
		<Show actions={hasAdminRole(permissions) ? <PostShowActions/> : null}>
			<TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto"/>}>
				{
					hasAdminRole(permissions) &&
					<TabbedShowLayout.Tab label="Primary Authorized Person">
						<SimpleShowLayout>
							<FunctionField label={false} render={record => record?.holder?.status === 5 ?
								<h3>Mark invalid data by click on field</h3> : null}/>
							<TextField label="Id" source="holder.id" sortBy="id"/>
							<CustomErrorFormField customLabel="Phone" label={false} source="holder.phone">
								<TextField source="holder.phone"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="First Name" label={false}
																		source="holder.givenName">
								<TextField source="holder.givenName"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Last Name" label={false} source="holder.familyName">
								<TextField source="holder.familyName"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Email" label={false} source="holder.email">
								<TextField source="holder.email"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Job Title" label={false} source="holder.jobTitle">
								<TextField source="holder.jobTitle"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Home address" label={false} source="holder.street">
								<TextField source="holder.street"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Extended address" label={false}
																		source="holder.extendedAddress">
								<TextField source="holder.extendedAddress"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="City" label={false} source="holder.city">
								<TextField source="holder.city"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="State" label={false} source="holder.state">
								<SelectField source="holder.state" choices={HolderState}/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Postal Code" label={false}
																		source="holder.postalCode">
								<TextField source="holder.postalCode"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="SSN" label={false} source="holder.ssn">
								<TextField source="holder.ssn"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Percentage Ownership" label={false}
																		source="holder.percentage">
								<TextField source="holder.percentage"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Date of Birth" label={false}
																		source="holder.birthDate">
								<DateFieldMod source="holder.birthDate"/>
							</CustomErrorFormField>
						</SimpleShowLayout>
					</TabbedShowLayout.Tab>
				}
				{
					hasAdminRole(permissions) &&
					<TabbedShowLayout.Tab label="Beneficial Owners Details">
						<SimpleShowLayout>
							<FunctionField label={false} render={record => record?.holder?.status === 5 ?
								<h3>Mark invalid data by click on field</h3> : null}/>
							<TextField label="Id" source="owner.id" sortBy="id"/>
							<CustomErrorFormField customLabel="Phone" label={false} source="owner.phone">
								<TextField source="owner.phone"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="First Name" label={false} source="owner.givenName">
								<TextField source="owner.givenName"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Last Name" label={false} source="owner.familyName">
								<TextField source="owner.familyName"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Email" label={false} source="owner.email">
								<TextField source="owner.email"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Home address" label={false} source="owner.street">
								<TextField source="owner.street"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Extended address" label={false}
																		source="owner.extendedAddress">
								<TextField source="owner.extendedAddress"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Postal Code" label={false} source="owner.postalCode">
								<TextField source="owner.postalCode"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="City" label={false} source="owner.city">
								<TextField source="owner.city"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="State" label={false} source="owner.state">
								<SelectField source="owner.state" choices={HolderState}/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="SSN" label={false} source="owner.ssn">
								<TextField source="owner.ssn"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Date of Birth" label={false}
																		source="owner.birthDate">
								<DateFieldMod source="owner.birthDate"/>
							</CustomErrorFormField>
							<CustomErrorFormField customLabel="Percentage Ownership" label={false}
																		source="owner.percentage">
								<TextField source="owner.percentage"/>
							</CustomErrorFormField>
						</SimpleShowLayout>
					</TabbedShowLayout.Tab>
				}
				<TabbedShowLayout.Tab label="Business Profile Details">
					<SimpleShowLayout>
						<FunctionField label={false} render={record => record?.holder?.status === 5 ?
							<h3>Mark invalid data by click on field</h3> : null}/>
						<TextField label="Id" source="profile.id" sortBy="id"/>
						<CustomErrorFormField customLabel="Phone" source="profile.phone" label={false}>
							<TextField source="profile.phone"/>
						</CustomErrorFormField>
						<CustomErrorFormField customLabel="Legal Business Name" label={false}
																	source="profile.legalName">
							<TextField source="profile.legalName"/>
						</CustomErrorFormField>
						<CustomErrorFormField customLabel="Doing Business Name" label={false}
																	source="profile.doingName">
							<TextField source="profile.doingName"/>
						</CustomErrorFormField>
						<CustomErrorFormField customLabel="Home Address" label={false} source="profile.street">
							<TextField source="profile.street"/>
						</CustomErrorFormField>
						<CustomErrorFormField customLabel="Extended Address" label={false}
																	source="profile.extendedAddress">
							<TextField source="profile.extendedAddress"/>
						</CustomErrorFormField>
						<CustomErrorFormField customLabel="Postal Code" label={false} source="profile.postalCode">
							<TextField source="profile.postalCode"/>
						</CustomErrorFormField>
						<CustomErrorFormField customLabel="City" label={false} source="profile.city">
							<TextField source="profile.city"/>
						</CustomErrorFormField>
						<CustomErrorFormField customLabel="State" label={false} source="profile.state">
							<SelectField source="profile.state" choices={HolderState}/>
						</CustomErrorFormField>
						<CustomErrorFormField customLabel="Employer Identification Number" label={false}
																	source="profile.employerId">
							<TextField source="profile.employerId"/>
						</CustomErrorFormField>
						<CustomErrorFormField customLabel="Website" label={false} source="profile.website">
							<TextField source="profile.website"/>
						</CustomErrorFormField>
						<CustomErrorFormField customLabel="Business Type" label={false}
																	source="profile.businessType">
							<TextField source="profile.businessType"/>
						</CustomErrorFormField>
					</SimpleShowLayout>
				</TabbedShowLayout.Tab>
				<TabbedShowLayout.Tab label="Application">
					<SimpleShowLayout>
						<TextField label="Id" source="application.id" sortBy="id"/>
						<SelectField label="Status" source="application.status" choices={ApplicationStatus}/>
						<div>
							<HolderApplicationVerificationShow source="applicationCurrentVerification"/>
						</div>
					</SimpleShowLayout>
				</TabbedShowLayout.Tab>
				<TabbedShowLayout.Tab label="Financial Account">
					<SimpleShowLayout>
						<TextField label="Id" source="financial.id" sortBy="id"/>
						<TextField label="Name" source="financial.name"/>
						<TextField label="Card Product" source="financial.product"/>
						<FunctionField label={false} render={record => {
							const productId = record?.financial?.product;
							return (<UrlField
								label='HIGHNOTE LINK'
								alignment="flex-start"
								source='finExternalId'
								val={`${higNoteLink}/card-products/${productId}/`}
								target='_blank'
								rel="noopener noreferrer"
							/>)
						}}/>

					</SimpleShowLayout>
				</TabbedShowLayout.Tab>
				<TabbedShowLayout.Tab label="Cards">
					<ReferenceManyField source="id" reference="cards" target="financial" label={false}
															emptyText="No results found">
						<Datagrid bulkActionButtons={false} empty={<div>No results found</div>}>
							<TextField label="Id" source="id"/>
							<TextField label="bin" source="bin"/>
							<TextField label="last4" source="last4"/>
							<DateFieldMod label="Expiration date" source="expiration_date"/>
							<TextField label="Network" source="network"/>
							<SelectField label="Status" source="status" choices={CardStatusSelection}/>
							<ShowButton/>
						</Datagrid>
					</ReferenceManyField>
				</TabbedShowLayout.Tab>
			</TabbedShowLayout>
		</Show> :
		<LoadingPage/>
}
