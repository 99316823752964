import {ROLE_ADMIN, ROLE_SUPER_ADMIN} from "../constant/Permissions";

export const hasRole = (role, permissions) => {
    if (!role) {
        console.error(`no role`)
        return false
    }
    if (!permissions) {
        return null
    }
    const roles = Array.isArray(role) ? role : [role]
    return roles.some(r => permissions.role === r)
}

export const hasAdminRole = (permissions) => {
    return hasRole([ROLE_SUPER_ADMIN, ROLE_ADMIN], permissions)
}

//
// export const hasPermission = (role, {permissions, action, resource}) => {
//     if (!hasRole(role)) {
//         return false
//     }
//     const item = permissions.find(per => per.resource === resource)
//     if (!item) {
//         throw new Error('no registered resource permission')
//     }
//     return item.action?.includes(action)
// }
