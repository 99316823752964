// const ROLE_USER = 'ROLE_USER'; - FE application user role
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_MANAGER = 'ROLE_MANAGER';

export const ALL_ADMIN_ROLES = [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MANAGER];

export const ROLES_CHOICES = [
    {
        id: ROLE_ADMIN,
        name: 'Bump owner'
    },
    {
        id: ROLE_MANAGER,
        name: 'Manager'
    },
]
