import {SettingsList} from "../../../Component/Settings/SettingsList";
import React from "react";
import {referralConfigList} from "./Constant/referralConfigTypes";

export const ReferralSettings = () => {
    return (
        <SettingsList
            resource="configs/referral"
            title="Referral configs"
            configList={referralConfigList}
            pagination={false}
        />
    );
};
