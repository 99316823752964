import {useDataProvider} from "react-admin";
import {useQuery} from "react-query";

export const useGet = (
	resource,
	{id, meta} = {},
	options = {},
) => {
	const dataProvider = useDataProvider();
	const queryKey = id || meta ? [resource, 'get', {id: String(id), meta}] : [resource, 'get']
	return useQuery(
		queryKey,
		() =>
			dataProvider
				.get(resource, {id, meta})
				.then(({data}) => data),
		options
	);
};
