import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Divider, Paper} from "@mui/material";

const InformationErrorDialog = ({errors, open, onClose}) => {
    const errorBackground = "#e0bac1";
    const errorText = "#a3253c";
    const fontsFamily = "'Roboto','Helvetica','Arial', 'sans-serif'";

    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {errors?.message}
                </DialogTitle>
                <DialogContent>
                    <div id="alert-dialog-description">
                        {errors?.data?.length ? errors.data.map((error, index) =>
                            <Paper key={index} elevation={3} style={{
                                fontFamily: fontsFamily,
                                padding: 10,
                                marginTop: 10,
                                background: errorBackground
                            }}>
                                <span>{error.path}</span>
                                <span style={{color: errorText, display: "block"}}>{error.code}</span>
                                <span>{error.description}</span>
                            </Paper>
                        ) : null}
                    </div>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button onClick={onClose}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default InformationErrorDialog;
