export const PolicyDocumentTypes = {
	cardAgreement: 1,
	serviceTerms: 2,
	privacy: 3,
	EFTA: 4,
}

export const DOCUMENT_LIST = [
	{
		type: PolicyDocumentTypes.cardAgreement,
		title: 'Card agreement',
	},
	{
		type: PolicyDocumentTypes.serviceTerms,
		title: 'Terms of service',
	},
	{
		type: PolicyDocumentTypes.privacy,
		title: 'Privacy policy'
	},
	{
		type: PolicyDocumentTypes.EFTA,
		title: 'Electronic Fund Transfer Act'
	},
]

export const getDocumentTitleByType = (type) => DOCUMENT_LIST.find(d => d.type === type)?.title || '';
