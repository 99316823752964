import {
    BooleanInput,
    Edit,
    PasswordInput,
    regex,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify,
    useRedirect,
    useRefresh
} from 'react-admin';
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import {ADMIN_PASSWORD_PATTERN, EMAIL_PATTERN, NAME_PATTERN, PHONE_NUMBER_PATTERN} from '../../Utils/regex/regex';
import React from 'react';
import {ROLES_CHOICES} from '../../Utils/constant/Permissions';
import {booleanToConditional, conditionalToBoolean} from '../../Utils/constant/Const';

const AdminEditToolbar = () => {
	const navigate = useNavigate();
	const goBack = () => navigate(-1);

	return (
		<Toolbar>
			<SaveButton/>
			<Button variant="outlined"
							color="secondary"
							style={{marginLeft: '1rem'}}
							onClick={goBack}>
				Cancel
			</Button>
		</Toolbar>
	)
}

const transform = data => ({
	...data,
	role: data.role.toString(),
});

export const AdminEdit = () => {

	const notify = useNotify();
	const refresh = useRefresh();
	const redirect = useRedirect();

	const onSuccess = () => {
		notify('Admin updated successfully')
		redirect('/admins');
		refresh();
	}

	return (
		<Edit redirect="list"
					transform={transform}
					mutationMode="pessimistic"
					mutationOptions={{onSuccess}}>
			<SimpleForm toolbar={<AdminEditToolbar/>}
									sx={{'& .MuiFormControl-root ': {minWidth: '400px'}}}>
				<TextInput label="First Name"
									 name="firstName"
									 source="first_name"
									 validate={[required(), regex(NAME_PATTERN, 'Please enter a valid first name.')]}/>
				<TextInput label="Last Name"
									 name="lastName"
									 source="last_name"
									 validate={[required(), regex(NAME_PATTERN, 'Please enter a valid last name.')]}/>
				<TextInput label="Phone"
									 name="phone"
									 source="phone"
									 placeholder="+12345678900"
									 validate={[required(), regex(PHONE_NUMBER_PATTERN, 'Phone number must be US number: +12345678900')]}/>
				<TextInput label="Email"
									 name="email"
									 source="email"
									 validate={[required(), regex(EMAIL_PATTERN, 'Invalid email format')]}/>
				<PasswordInput label="Password"
											 name="password"
											 defaultValue=""
											 initialValue=""
											 autoComplete="off"
											 source="password"
											 validate={[regex(ADMIN_PASSWORD_PATTERN, 'Min 12 symbols with at least 1 upper, lower, number and one special symbol')]}
											 sx={{
												 maxWidth: '400px',
												 '& .Mui-error': {wordWrap: 'break-word'}
											 }}/>
				<SelectInput label="Role"
										 name="role"
										 source="role"
										 choices={ROLES_CHOICES}
										 validate={required()}
										 sx={{minWidth: 219}}/>
				<BooleanInput label="Notifications"
											source="receiveNotification"
											format={value => conditionalToBoolean(value)}
											parse={value => booleanToConditional(value)}/>
			</SimpleForm>
		</Edit>
	)
}
