import {FieldTitle, Labeled} from "react-admin";
import * as React from "react";
import {memo} from "react";
import {FormControl, IconButton, Tooltip} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const FormControlView = ({className, classes, handleOpen, children, showError, customLabel, error, editable}) => {
    return (<FormControl onClick={showError && editable ? handleOpen : null} margin="dense"
                         style={{cursor: showError && editable ? 'pointer' : 'default'}}
                         className={className}
    >
        <Labeled className={classes.label} label={
            <>
                <FieldTitle
                    label={`${customLabel}`}
                />
                {showError && error ? <Tooltip title="Field error" placement="top" arrow>
                    <IconButton color="error" sx={{marginLeft: '.5rem', padding: '0.2rem'}}>
                        <ErrorOutlineIcon fontSize="small"/>
                    </IconButton>
                </Tooltip> : null}
            </>
        }>
            {React.cloneElement(children, {
                color: showError && !!error ? 'error' : 'inherit',
            })}
        </Labeled>
    </FormControl>)
}

export default memo(FormControlView)
