export const DefaultCurrency = {
	label: 'USD',
	badge: '$'
}

export const HOLDER_STATUS = {
	declined: 0,
	review: 4,
	pending: 5,
	highNoteReview: 6,
	inProgress: 7,
	approved: 9,
	completed: 10,

}

export const HolderStatusSelection = [
	{id: HOLDER_STATUS.completed, name: 'COMPLETED'},
	{id: HOLDER_STATUS.approved, name: 'APPROVED'},
	{id: HOLDER_STATUS.inProgress, name: 'IN PROGRESS'},
	{id: HOLDER_STATUS.highNoteReview, name: 'MANUAL REVIEW'},
	{id: HOLDER_STATUS.pending, name: 'PENDING'},
	{id: HOLDER_STATUS.review, name: 'SENT TO USER'},
	{id: HOLDER_STATUS.declined, name: 'DECLINED'},
];

export const REFERRAL_STATUS = {
	signedUp: 1,
	accountLinked: 2,
	cardCreated: 3,
	payed: 4,
	received: 5,
}

export const ReferralStatusSelection = [
	{id: REFERRAL_STATUS.signedUp, name: 'SIGNED UP'},
	{id: REFERRAL_STATUS.accountLinked, name: 'ACCOUNT LINKED'},
	{id: REFERRAL_STATUS.cardCreated, name: 'CARD CREATED'},
	{id: REFERRAL_STATUS.payed, name: 'PAYED'},
	{id: REFERRAL_STATUS.received, name: 'RECEIVED'},
];

export const CardStatus = {
	closed: 0,
	adminSuspend: 2,
	userSuspend: 4,
	pending: 6,
	active: 10,
	paymentOverdue: 12,
	noBankAccount: 14,
	noSubscription: 15
};

export const CardStatusSelection = [
	{id: CardStatus.paymentOverdue, name: 'PAYMENT OVERDUE'},
	{id: CardStatus.active, name: 'ACTIVE'},
	{id: CardStatus.pending, name: 'PENDING'},
	{id: CardStatus.userSuspend, name: 'USER SUSPEND'},
	{id: CardStatus.adminSuspend, name: 'ADMIN SUSPEND'},
	{id: CardStatus.closed, name: 'CLOSED'},
	{id: CardStatus.noBankAccount, name: 'Suspended - no bank account'},
	{id: CardStatus.noSubscription, name: 'NO MEMBERSHIP'},
];

export const CreditLimitStatus = [
	{id: 1, name: 'PENDING'},
	{id: 2, name: 'PROCESSING'},
	{id: 3, name: 'COMPLETED'},
	{id: 4, name: 'FAILED'},
];

export const CreditLimitReasonStatus = [
	{id: 1, name: 'Account closed'},
	{id: 2, name: 'Account no found'},
	{id: 3, name: 'Insufficient funds'},
	{id: 4, name: 'Limit amount exceeding max program load limit'},
];

export const USER_STATUS_TYPE = {
	active: 10,
	unverified: 7,
	pending: 3,
	blocked: 1,
	deleted: 0,
}
export const UserStatus = [
	{id: 10, name: 'ACTIVE'},
	{id: 7, name: 'UNVERIFIED'},
	{id: 3, name: 'PENDING'},
	{id: 1, name: 'BLOCKED'},
	{id: 0, name: 'DELETED'}
];
export const USER_HAS_FILTER_TYPE = {
	with: 1,
	without: 2
}
export const UserHasFilterCollection = [
	{id: USER_HAS_FILTER_TYPE.with, name: 'With'},
	{id: USER_HAS_FILTER_TYPE.without, name: 'Without'},
]

export const USER_CLAIM_STATUS_TYPE = {
	unclaimed: 1,
	claimed: 2,
}
export const UserClaimFilterCollection = [
	{id: USER_CLAIM_STATUS_TYPE.unclaimed, name: 'Unclaimed'},
	{id: USER_CLAIM_STATUS_TYPE.claimed, name: 'Claimed'},
	{id: 3, name: 'Created by admin'},
]

export const Gender = [
	{id: 1, name: 'Male'},
	{id: 2, name: 'Female'},
	{id: 3, name: 'Other'},
];

export const CREATOR_STATUS_TYPE = {
	deleted: 0,
	musician: 1,
	influencer: 2,
	podcaster: 3,
	actor: 4,
	writer: 5,
	photographer: 6,
	freelancer: 7,
	model: 8,
	other: 9,
}

export const CreatorType = [
	{id: CREATOR_STATUS_TYPE.deleted, name: 'DELETED'},
	{id: CREATOR_STATUS_TYPE.musician, name: 'Musician'},
	{id: CREATOR_STATUS_TYPE.influencer, name: 'Influencer'},
	{id: CREATOR_STATUS_TYPE.podcaster, name: 'Podcaster'},
	{id: CREATOR_STATUS_TYPE.actor, name: 'Actor'},
	{id: CREATOR_STATUS_TYPE.writer, name: 'Writer'},
	{id: CREATOR_STATUS_TYPE.photographer, name: 'Photographer'},
	{id: CREATOR_STATUS_TYPE.freelancer, name: 'Freelancer'},
	{id: CREATOR_STATUS_TYPE.model, name: 'Model'},
	{id: CREATOR_STATUS_TYPE.other, name: 'Other'}
]

export const HolderState = [
	{id: 'AL', name: 'ALABAMA'},
	{id: 'AK', name: 'ALASKA'},
	{id: 'AS', name: 'AMERICAN SAMOA'},
	{id: 'AZ', name: 'ARIZONA'},
	{id: 'AR', name: 'ARKANSAS'},
	{id: 'CA', name: 'CALIFORNIA'},
	{id: 'CO', name: 'COLORADO'},
	{id: 'CT', name: 'CONNECTICUT'},
	{id: 'DE', name: 'DELAWARE'},
	{id: 'DC', name: 'DISTRICT OF COLUMBIA'},
	{id: 'FL', name: 'FLORIDA'},
	{id: 'GA', name: 'GEORGIA'},
	{id: 'GU', name: 'GUAM'},
	{id: 'HI', name: 'HAWAII'},
	{id: 'ID', name: 'IDAHO'},
	{id: 'IL', name: 'ILLINOIS'},
	{id: 'IA', name: 'INDIANA'},
	{id: 'IA', name: 'IOWA'},
	{id: 'KS', name: 'KANSAS'},
	{id: 'KY', name: 'KENTUCKY'},
	{id: 'LA', name: 'LOUISIANA'},
	{id: 'ME', name: 'MAINE'},
	{id: 'MD', name: 'MARYLAND'},
	{id: 'MA', name: 'MASSACHUSETTS'},
	{id: 'MI', name: 'MICHIGAN'},
	{id: 'MN', name: 'MINNESOTA'},
	{id: 'MS', name: 'MISSISSIPPI'},
	{id: 'MO', name: 'MISSOURI'},
	{id: 'MT', name: 'MONTANA'},
	{id: 'NE', name: 'NEBRASKA'},
	{id: 'NV', name: 'NEVADA'},
	{id: 'NH', name: 'NEW HAMPSHIRE'},
	{id: 'NJ', name: 'NEW JERSEY'},
	{id: 'NM', name: 'NEW MEXICO'},
	{id: 'NY', name: 'NEW YORK'},
	{id: 'NC', name: 'NORTH CAROLINA'},
	{id: 'ND', name: 'NORTH DAKOTA'},
	{id: 'MP', name: 'NORTHERN MARIANA IS'},
	{id: 'OH', name: 'OHIO'},
	{id: 'OK', name: 'OKLAHOMA'},
	{id: 'OR', name: 'OREGON'},
	{id: 'PA', name: 'PENNSYLVANIA'},
	{id: 'PR', name: 'PUERTO RICO'},
	{id: 'RI', name: 'RHODE ISLAND'},
	{id: 'SC', name: 'SOUTH CAROLINA'},
	{id: 'SD', name: 'SOUTH DAKOTA'},
	{id: 'TN', name: 'TENNESSEE'},
	{id: 'TX', name: 'TEXAS'},
	{id: 'UT', name: 'UTAH'},
	{id: 'VT', name: 'VERMONT'},
	{id: 'VA', name: 'VIRGINIA'},
	{id: 'VI', name: 'VIRGIN ISLANDS'},
	{id: 'WA', name: 'WASHINGTON'},
	{id: 'WV', name: 'WEST VIRGINIA'},
	{id: 'WI', name: 'WISCONSIN'},
	{id: 'WY', name: 'WYOMING'}
];

export const HolderType = [
	{id: 10, name: 'Corporation'},
	{id: 8, name: 'LLC'},
	{id: 6, name: 'Non Profit'},
	{id: 4, name: 'Partnership'},
	{id: 2, name: 'Sole Proprietorship'}
];

export const HolderJobTitle = [
	{id: 14, name: 'CEO'},
	{id: 12, name: 'CFO'},
	{id: 10, name: 'COO'},
	{id: 8, name: 'Owner'},
	{id: 6, name: 'President'},
	{id: 4, name: 'Treasurer'},
	{id: 2, name: 'Vice President'}
];

export const ApplicationStatus = [
	{id: 10, name: 'APPROVED'},
	{id: 8, name: 'IN_REVIEW'},
	{id: 6, name: 'PENDING'},
	{id: 4, name: 'DENIED'},
	{id: 0, name: 'CLOSED'}
];

export const EventType = [
	{id: 10, name: 'Created at'},
	{id: 7, name: 'Bank account added'},
	{id: 6, name: 'Login'},
	{id: 5, name: 'Logout'},
	{id: 1, name: 'Bank account disconnected'},
	{id: 0, name: 'User account deleted'},
];


export const TransactionTypes = [
	{
		id: 1, name: 'Authorization approved',
	},
	{
		id: 2, name: 'Authorization declined',
	},
	{
		id: 3, name: 'Authorization reversed',
	},
	{
		id: 4, name: 'Cleared',
	},
	{
		id: 5, name: 'Verification approved',
	},
	{
		id: 6, name: 'Verification declined',
	},
	{
		id: 7, name: 'Authorization and clear approved',
	},
	{
		id: 8, name: 'Authorization and clear declined',
	},
	{
		id: 8, name: 'Authorization created',
	},
];

export const CONDITIONAL_STATUS = {
	YES: 1,
	NO: 0
}

export const booleanToConditional = (value) => {
	return value ? CONDITIONAL_STATUS.YES : CONDITIONAL_STATUS.NO
}

export const conditionalToBoolean = (value) => {
	return CONDITIONAL_STATUS.YES === value
}

export const REPAYMENT_INIT_TYPE = [
	{id: 1, name: 'User'},
	{id: 2, name: 'System'},
	{id: 3, name: 'Admin'},
];

export const REPAYMENT_STATUS = [
	{id: 1, name: 'BANK_ACCOUNT_BALANCE_NOT_ENOUGH'},
	{id: 2, name: 'HIGHNOTE_API_ERROR'},
	{id: 3, name: 'INTERNAL_ERROR'},
];

export const DISPUTE_REASON = [
	{id: 1, name: 'NOT_AUTHORIZED_TRANSACTION'},
	{id: 2, name: 'ISSUE_WITH_TRANSACTION'},
	{id: 3, name: 'ITEM_OR_SERVICE_WAS_NOT_RECEIVED'},
	{id: 4, name: 'SERVICE_IS_DIFFERENT'}
]

export const DisputeStatus = {
	OPEN: 1,
	IN_PROGRESS: 2,
	RESOLVED: 3,
	DECLINED: 4
}

export const DISPUTE_STATUS = [
	{id: 1, name: 'OPEN'},
	{id: 2, name: 'IN PROGRESS'},
	{id: 3, name: 'RESOLVED'},
	{id: 4, name: 'DECLINED'}
]

export const SubscriptionPeriodType = {
	MONTHLY: 'monthly',
	ANNUAL: 'annual',
}
export const SUBSCRIPTION_PERIOD_LIST = [
	{id: SubscriptionPeriodType.MONTHLY, label: 'Monthly', period: 'month'},
	{id: SubscriptionPeriodType.ANNUAL, label: 'Annual', period: 'year'},
]

export const SubscriptionStatus = {
	SUBSCRIBE_STATUS_ACTIVE: 1,
	SUBSCRIBE_STATUS_CANCELED: 2,
}

export const SUBSCRIPTION_STATUS_LIST = [
	{id: SubscriptionStatus.SUBSCRIBE_STATUS_ACTIVE, name: 'Active'},
	{id: SubscriptionStatus.SUBSCRIBE_STATUS_CANCELED, name: 'Canceled'},
]

export const MONTHS_LABELS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const SOCIAL_CHANNEL_TYPES = {
	youtube: 1,
	instagram: 2,
	tikTok: 3,
	spotify: 4,
	youtubeMusic: 5,
}

export const SOCIAL_CHANNEL_LABELS = {
	[`${SOCIAL_CHANNEL_TYPES.youtube}`]: 'YouTube',
	[`${SOCIAL_CHANNEL_TYPES.instagram}`]: 'Instagram',
	[`${SOCIAL_CHANNEL_TYPES.tikTok}`]: 'TikTok',
	[`${SOCIAL_CHANNEL_TYPES.spotify}`]: 'Spotify',
	[`${SOCIAL_CHANNEL_TYPES.youtubeMusic}`]: 'YouTube Music',
}

export const ADD_SOCIAL_CHANNEL_TYPES_LIST = [
	{id: SOCIAL_CHANNEL_TYPES.tikTok, name: SOCIAL_CHANNEL_LABELS[`${SOCIAL_CHANNEL_TYPES.tikTok}`]},
	{id: SOCIAL_CHANNEL_TYPES.spotify, name: SOCIAL_CHANNEL_LABELS[`${SOCIAL_CHANNEL_TYPES.spotify}`]},
	{id: SOCIAL_CHANNEL_TYPES.youtubeMusic, name: SOCIAL_CHANNEL_LABELS[`${SOCIAL_CHANNEL_TYPES.youtubeMusic}`]},
]

export const SOCIAL_CHANNEL_TYPES_LIST = [
	{id: SOCIAL_CHANNEL_TYPES.youtube, name: SOCIAL_CHANNEL_LABELS[`${SOCIAL_CHANNEL_TYPES.youtube}`]},
	{id: SOCIAL_CHANNEL_TYPES.instagram, name: SOCIAL_CHANNEL_LABELS[`${SOCIAL_CHANNEL_TYPES.instagram}`]},
	{id: SOCIAL_CHANNEL_TYPES.tikTok, name: SOCIAL_CHANNEL_LABELS[`${SOCIAL_CHANNEL_TYPES.tikTok}`]},
	{id: SOCIAL_CHANNEL_TYPES.spotify, name: SOCIAL_CHANNEL_LABELS[`${SOCIAL_CHANNEL_TYPES.spotify}`]},
	{id: SOCIAL_CHANNEL_TYPES.youtubeMusic, name: SOCIAL_CHANNEL_LABELS[`${SOCIAL_CHANNEL_TYPES.youtubeMusic}`]},
]

export const FULL_STATS_SOCIAL_CHANNELS = [SOCIAL_CHANNEL_TYPES.youtube, SOCIAL_CHANNEL_TYPES.instagram, SOCIAL_CHANNEL_TYPES.tikTok]

export const TIKTOK_LINK_PATTERN = /^(http(s)?:\/\/)?www\.tiktok\.com\/@[a-zA-Z0-9]+/;
export const SPOTIFY_ARTIST_LINK_PATTERN = /^(http(s)?:\/\/)?open\.spotify\.com\/artist\/[a-zA-Z0-9]+/;
export const YOUTUBE_MUSIC_ARTIST_LINK_PATTERN = /^(http(s)?:\/\/)?music\.youtube\.com\/channel\/[a-zA-Z0-9-_]+/;


export const SOCIAL_CHANNEL_STATUSES = {
	deleted: 0,
	active: 1,
	failed: 2,
	disabled: 3,
	new: 4,
	empty: 5,
	unverified: 6,
	rejected: 7,
}

export const SOCIAL_CHANNEL_STATUSES_LIST = [
	{id: SOCIAL_CHANNEL_STATUSES.active, name: 'Active'},
	{id: SOCIAL_CHANNEL_STATUSES.failed, name: 'Failed'},
	{id: SOCIAL_CHANNEL_STATUSES.disabled, name: 'Disabled'},
	{id: SOCIAL_CHANNEL_STATUSES.new, name: 'New'},
	{id: SOCIAL_CHANNEL_STATUSES.empty, name: 'Empty'},
	{id: SOCIAL_CHANNEL_STATUSES.unverified, name: 'Unverified'},
	{id: SOCIAL_CHANNEL_STATUSES.rejected, name: 'Rejected'},
]


export const SOCIAL_CHANNEL_SUB_STATUSES = {
	parsing_failed: 1,
	token_failed: 2,
}
export const SOCIAL_CHANNEL_SUB_STATUSES_LIST = [
	{id: SOCIAL_CHANNEL_SUB_STATUSES.parsing_failed, name: 'Parsing failed'},
	{id: SOCIAL_CHANNEL_SUB_STATUSES.token_failed, name: 'Token failed'},
]
