import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

export const SelectMod = ({id, label, value, handleChange, choices}) => {
    return (
        <FormControl fullWidth>
            <InputLabel id={`select-label - ${id}`}>{label}</InputLabel>
            <Select
                labelId={`select-label - ${id}`}
                id={`select- ${id}`}
                name={label}
                value={value}
                label={label}
                onChange={(e) => handleChange(e.target.value)}
            >
                {
                    choices?.map((item, index) =>
                        <MenuItem value={item?.value} key={index}>{item?.label}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
}
