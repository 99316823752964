import {
    Datagrid,
    FunctionField,
    LoadingPage,
    Pagination,
    ReferenceManyField,
    SimpleShowLayout,
    TextField,
    useGetOne
} from 'react-admin';
import React, { useMemo } from 'react';
import { SUBSCRIPTION_PERIOD_LIST } from '../../Utils/constant/Const';
import { getFinancialNumberWithCurrency } from '../../Utils/helpers/reformat-number';

export const ShowSubscriptionByUser = ({ id }) => {

    const { data, isLoading } = useGetOne('subscriptions/user', { id })
    const subPeriod = useMemo(() => {
        return SUBSCRIPTION_PERIOD_LIST.find(item => item.id === data?.periodicity)
    }, [ data ])

    return (
        <>
            { isLoading ? <LoadingPage/> :
                <SimpleShowLayout record={ data }>
                    <TextField label="Name" source="name"/>
                    <TextField label="Description" source="description"/>
                    <FunctionField label="Price per period" render={ record =>
                        subPeriod ? `${ getFinancialNumberWithCurrency(record?.price) } / per ${ subPeriod.period }` : null
                    }/>
                    <TextField label="Membership started at" source="info.start"/>
                    <TextField label="The date of the next payment" source="info.nextPay"/>
                    <FunctionField label="Remaining until the next payment" render={ record => {
                        return record?.info?.remainingDays ? `${ record?.info?.remainingDays } day(s)` : ''
                    } }/>

                    <h2>Membership payment history</h2>
                    <ReferenceManyField record={ { id } }
                                        source="id"
                                        label={ false }
                                        reference="subscriptions/invoices"
                                        perPage={ 10 }
                                        target="userId"
                                        pagination={ <Pagination/> }
                                        sort={ { field: 'createdAt', order: 'DESC' } }>
                        <Datagrid bulkActionButtons={ false }>
                            <TextField label="Id" source="id"/>
                            <FunctionField
                                label="Amount"
                                render={ (record) =>
                                    `${ getFinancialNumberWithCurrency(record.amount || 0) }`
                                }
                            />
                            {/*TODO: need list of payment statuses*/ }
                            <TextField label="Status" source="status"/>
                            <TextField sortable={ false } label="Created At" source="createdAt"/>
                            <FunctionField sortable={ false }
                                           label="Period"
                                           render={ record => `${ record?.period?.start } - ${ record?.period?.end }` }/>
                            <TextField sortable={ false } label="Description" source="description"/>
                        </Datagrid>
                    </ReferenceManyField>
                </SimpleShowLayout>
            }
        </>
    )
}
