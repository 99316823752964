import {useTiptapEditor} from "ra-input-rich-text";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {AddTableIcon} from "../icons/add-table";
import * as React from "react";
import {AddColumnBefore} from "../icons/add-column-before";
import {AddColumnAfter} from "../icons/add-column-after";
import {DeleteColumn} from "../icons/delete-column";
import {AddRowBefore} from "../icons/add-row-before";
import {AddRowAfter} from "../icons/add-row-after";
import {DeleteRow} from "../icons/delete-row";
import {DeleteTable} from "../icons/delete-table";
import {MergeCells} from "../icons/merge-cells";
import {SplitCells} from "../icons/split-cells";
import {ToggleHeaderColumn} from "../icons/toggle-header-column";
import {ToggleHeaderRow} from "../icons/toggle-header-row";
import {ToggleHeaderCell} from "../icons/toggle-header-cell";
import {GoToPreviousCell} from "../icons/go-to-previous-cell";
import {GoToNextCell} from "../icons/go-to-next-cell";
import {FixTables} from "../icons/fix-table";

export const RichTextInputTableButtons = ({size}) => {
    const editor = useTiptapEditor()
    const buttons = TableButtons
    const rootClass = 'RichTextInputTableButtons-root'
    const handleChange = (event, button) => {
        if (!editor) {
            return
        }
        button.action(editor)
    }


    return (
        <ToggleButtonGroup
            size={size}
            disabled={!editor?.isEditable}
            onChange={handleChange}
            exclusive={true}
            className={rootClass}
        >
            {buttons.map((item, index) =>
                <ToggleButton key={index}
                              aria-label={item.label}
                              title={item.label}
                              size={size}
                              disabled={!editor?.isEditable}
                              value={item}
                >
                    {item.icon ? React.cloneElement(item.icon, {key: index}) : null}
                </ToggleButton>
            )}
        </ToggleButtonGroup>
    );
};


const TableButtons = [
    {
        label: 'Add table',
        action: (editor) => editor.chain().focus().insertTable({rows: 3, cols: 3, withHeaderRow: true}).run(),
        icon: <AddTableIcon/>
    },
    {
        label: 'Add column before',
        action: (editor) => editor.chain().focus().addColumnBefore().run(),
        icon: <AddColumnBefore/>
    },
    {
        label: 'Add column after',
        action: (editor) => editor.chain().focus().addColumnAfter().run(),
        icon: <AddColumnAfter/>
    },
    {
        label: 'Delete column',
        action: (editor) => editor.chain().focus().deleteColumn().run(),
        icon: <DeleteColumn/>
    },
    {
        label: 'Add row before',
        action: (editor) => editor.chain().focus().addRowBefore().run(),
        icon: <AddRowBefore/>
    },
    {
        label: 'Add row after',
        action: (editor) => editor.chain().focus().addRowAfter().run(),
        icon: <AddRowAfter/>
    },
    {
        label: 'Delete row',
        action: (editor) => editor.chain().focus().deleteRow().run(),
        icon: <DeleteRow/>
    },
    {
        label: 'Delete table',
        action: (editor) => editor.chain().focus().deleteTable().run(),
        icon: <DeleteTable/>
    },
    {
        label: 'Merge cells',
        action: (editor) => editor.chain().focus().mergeCells().run(),
        icon: <MergeCells/>
    },
    {
        label: 'Split cells',
        action: (editor) => editor.chain().focus().splitCell().run(),
        icon: <SplitCells/>
    },
    {
        label: 'Toggle header column',
        action: (editor) => editor.chain().focus().toggleHeaderColumn().run(),
        icon: <ToggleHeaderColumn/>
    },
    {
        label: 'Toggle header row',
        action: (editor) => editor.chain().focus().toggleHeaderRow().run(),
        icon: <ToggleHeaderRow/>
    },
    {
        label: 'Toggle header cell',
        action: (editor) => editor.chain().focus().toggleHeaderCell().run(),
        icon: <ToggleHeaderCell/>
    },
    {
        label: 'Go to previous cell',
        action: (editor) => editor.chain().focus().goToPreviousCell().run(),
        icon: <GoToPreviousCell/>
    },
    {
        label: 'Go to next cell',
        action: (editor) => editor.chain().focus().goToNextCell().run(),
        icon: <GoToNextCell/>
    },
    {
        label: 'Fix tables',
        action: (editor) => editor.chain().focus().fixTables().run(),
        icon: <FixTables/>
    },
]
