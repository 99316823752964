import {useInput} from "react-admin";
import {Checkbox, Typography} from "@mui/material";
import React, { useCallback } from 'react';

const BooleanCheckboxMod = ({label, parse, ...rest}) => {
    const inputProps = {...rest, type: 'checkbox'}
    const {id, field} = useInput(inputProps);

    const handleChange = useCallback(
        ({target}) => {
            const res = parse ? parse(target.checked) : target.checked;
            field.onChange(res);
            field.onBlur();
        },
        [field, parse]
    );

    return (
        <label htmlFor={id}>
            <Typography variant="body2">
                {label}
            </Typography>
            <Checkbox edge="start"
                      {...field}
                      onChange={handleChange}
                      tabIndex={-1}
                      id={id}/>
        </label>
    );
};

export default BooleanCheckboxMod
