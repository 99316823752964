import {Datagrid, FunctionField, ReferenceManyField, SelectField, TextField} from 'react-admin';
import PaginationMod from '../../Component/Redesign/PaginationMod';
import DateFieldMod from '../../Component/Redesign/DateFieldMod';
import {REPAYMENT_INIT_TYPE, REPAYMENT_STATUS} from '../../Utils/constant/Const';
import {getFinancialNumberWithCurrency} from '../../Utils/helpers/reformat-number';

export const RepaymentsFailsReferenceList = ({source}) => {

    return (
        <ReferenceManyField source={source}
                            reference="repayments/fails"
                            target="userId"
                            label={false}
                            pagination={<PaginationMod/>}
                            sort={{field: 'createdAt', order: 'DESC'}}>
            <Datagrid bulkActionButtons={false}>
                <TextField label="Id"
                           source="id"/>
                <FunctionField label="Amount"
                               sortBy="amount"
                               render={record => `${getFinancialNumberWithCurrency(record.amount)}`}/>
                <SelectField label="Initiated by"
                             source="type"
                             choices={REPAYMENT_INIT_TYPE}/>
                <SelectField label="Fail Reason"
                             source="failReason"
                             choices={REPAYMENT_STATUS}/>
                <DateFieldMod showTime
                              label="Created at"
                              source="createdAt"/>
                <DateFieldMod showTime
                              label="Updated at"
                              source="updatedAt"/>
            </Datagrid>
        </ReferenceManyField>
    )
}
