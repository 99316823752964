import {FunctionField, Link, SimpleShowLayout, TextField, usePermissions, useRecordContext} from "react-admin";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React from "react";
import DateFieldMod from "../../Component/Redesign/DateFieldMod";
import get from 'lodash/get';
import {getHighNoteOrganizationLink} from "../../Utils/constant/HighNote";
import {CreditLimitHistory} from "../../Component/CreditLimit/CreditLimitHistory";
import {IsEmptyValue} from "../../Component/Handlers/IsEmptyValue";
import {Link as MuiLink} from "@mui/material";
import {hasAdminRole} from "../../Utils/helpers/permissions";
import {getFinancialNumberWithCurrency} from "../../Utils/helpers/reformat-number";


export const FinancialAccountByUserShow = ({source, userId, ...props}) => {
	if (!source || !userId) {
		throw new Error('no source or user id')
	}
	const record = useRecordContext(props);
	const account = get(record, source);
	const higNoteLink = getHighNoteOrganizationLink();
	const hasCreditLimit = !IsEmptyValue(account?.credit_limit);
	const {permissions} = usePermissions();

	return (
		<>
			<SimpleShowLayout record={account?.financial_account}>
				<h2>Financial account</h2>
				<TextField label="Id" source="id" sortBy="id"/>
				<FunctionField label="Name" render={record =>
					permissions && hasAdminRole(permissions) ?
						<Link to={`/financial-accounts/${record?.id}/show`}>
							{record?.name}
						</Link> :
						<TextField source="name"/>
				}/>
				<TextField label="Product id" source="product"/>
				<FunctionField label="Highnote url" render={(item) => <MuiLink
					href={`${higNoteLink}/card-products/${item?.product}/${item?.external_id}`}
					target="_blank"
					rel="noopener noreferrer">
					<OpenInNewIcon/>
				</MuiLink>
				}/>
				<DateFieldMod showTime label="Created At" source="created_at"/>
			</SimpleShowLayout>
			<SimpleShowLayout>
				<FunctionField label="Credit Limit"
											 record={account?.credit_limit?.account_holder_credit_limit}
											 render={data => hasCreditLimit ? `${getFinancialNumberWithCurrency(data?.value)}` : 'Credit limit not set'}/>
				{
					hasCreditLimit &&
					<FunctionField label="Available Credit"
												 record={account?.credit_limit?.available_credit}
												 render={data => `${getFinancialNumberWithCurrency(data?.value || 0)}`}/>
				}
				{
					hasCreditLimit &&
					<FunctionField label="Outstanding balance"
												 record={account?.credit_limit?.outstanding_balance_payable}
												 render={data => `${getFinancialNumberWithCurrency(data?.value || 0)}`}/>
				}

			</SimpleShowLayout>

			<SimpleShowLayout>
				<CreditLimitHistory {...account}/>
			</SimpleShowLayout>
		</>
	)
};
