import {useGet} from "../../../Hooks/useGet";
import {LoadingPage, useSidebarState} from "react-admin";
import React, {useCallback, useEffect, useState} from "react";
import {MainSocialStatisticTable} from "./mainSocialStatisticTable";
import {SOCIAL_CHANNEL_TYPES} from "../../../Utils/constant/Const";
import {MusicSocialStatisticTable} from "./musicSocialStatitsticTable";
import {Box, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {UpdateMultiplierModal} from "./updateMultiplierModal";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {UploadChannelStatisticTableModal} from "./uploadChannelStatisticTableModal";

export const MarketValueChannelSettings = ({channelType}) => {
	const {
		data,
		isLoading,
	} = useGet('social-network-settings/table', {meta: {query: {type: channelType}}})
	const mainSocialChannels = [SOCIAL_CHANNEL_TYPES.youtube, SOCIAL_CHANNEL_TYPES.instagram, SOCIAL_CHANNEL_TYPES.tikTok]
	const isMainChannel = mainSocialChannels.includes(channelType)
	const [isOpenMultiplierForm, setIsOpenMultiplierForm] = useState(false)
	const [isOpenUploadForm, setIsOpenUploadForm] = useState(false)
	const [isOpen] = useSidebarState()
	const [maxWidth, setMaxWidth] = useState(0)


	const setVw = useCallback(() => {

		const clientWidth = document.documentElement.clientWidth;
		const maxWidth = clientWidth < 600 ?
			clientWidth - 48 :
			clientWidth - (isOpen ? 208 : 54) - 48;
		setMaxWidth(maxWidth)
	}, [isOpen])

	useEffect(() => {
		setVw()

		window.addEventListener('resize', setVw)
		return () => {
			window.removeEventListener('resize', setVw)
		}
	}, [setVw]);

	if (isLoading) {
		return <LoadingPage/>
	}
	return <div style={{maxWidth}}>
		<div style={{
			display: "flex",
			flexWrap: 'wrap',
			gap: '16px',
			marginBottom: '16px',
			justifyContent: 'space-between',
		}}>
			<div style={{display: "flex", alignItems: "center",}}>
				<Typography variant="subtitle1">
					Multiplier: <b>{data?.multiplier}</b>
				</Typography>
				<Button variant="text"
								color="primary"
								sx={{margin: '0 0 8px 8px'}}
								onClick={() => setIsOpenMultiplierForm(true)}>
					<EditOutlinedIcon/>
				</Button>
				{isOpenMultiplierForm &&
					<UpdateMultiplierModal multiplier={data?.multiplier} channelType={channelType}
																 onClose={() => setIsOpenMultiplierForm(false)}/>
				}
			</div>
			<div>
				<Button variant="outlined"
								color="primary"
								onClick={() => setIsOpenUploadForm(true)}>
					Upload new config
				</Button>
				{
					isOpenUploadForm &&
					<UploadChannelStatisticTableModal channelType={channelType} onClose={() => setIsOpenUploadForm(false)}/>
				}
			</div>
		</div>
		{
			Array.isArray(data?.table) ?
				<div style={{maxWidth: '100%', overflow: 'auto', height: 'calc(100vh - 210px)'}}>
					{
						isMainChannel ?
							<MainSocialStatisticTable table={data.table} channelType={channelType}/> :
							<MusicSocialStatisticTable table={data.table}/>
					}
				</div> :
				<Box textAlign="center" m={1}>
					<Typography variant="h4" paragraph>
						No actual data
					</Typography>
				</Box>
		}
	</div>
}
