import {
    FunctionField,
    LoadingPage,
    SelectField,
    Show,
    SimpleShowLayout,
    TabbedShowLayout,
    TabbedShowLayoutTabs,
    TextField,
    useGetOne,
    useGetRecordId
} from 'react-admin';
import React from 'react';
import { DISPUTE_REASON, DISPUTE_STATUS } from '../../Utils/constant/Const';
import DateFieldMod from '../../Component/Redesign/DateFieldMod';
import { getFinancialNumberWithCurrency } from '../../Utils/helpers/reformat-number';
import { EditDisputeModal } from './components/EditDisputeModal';

export const DisputesShow = () => {
    const recordId = useGetRecordId();

    const { data: disputeDetails, isLoading } = useGetOne(`disputes`, {
        id: recordId,
        meta: { resourceSuffix: 'details' }
    })

    if (isLoading) return <LoadingPage/>

    return (
        <Show>
            <TabbedShowLayout tabs={ <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto"/> }>
                <TabbedShowLayout.Tab label="Dispute">
                    <SimpleShowLayout>
                        <TextField label="Id"
                                   source="id"/>
                        <TextField label="User Id"
                                   source="userId"/>
                        <SelectField label="Reason"
                                     source="reason"
                                     choices={ DISPUTE_REASON }/>
                        <SelectField label="Status"
                                     source="status"
                                     choices={ DISPUTE_STATUS }/>
                        <TextField label="Description"
                                   style={ { display: 'inline-block', maxWidth: '500px', wordBreak: 'break-all' } }
                                   source="description"/>
                        <FunctionField label="Transaction Amount"
                                       source="transactionAmount"
                                       render={
                                           record => record.transactionAmount ?
                                               `${ getFinancialNumberWithCurrency(record.transactionAmount / 100) }` : ''
                                       }/>
                        <DateFieldMod showTime
                                      label="Transaction Time"
                                      source="transactionTime"/>
                        <EditDisputeModal/>
                        <DateFieldMod showTime
                                      label="Created At"
                                      source="createdAt"/>
                        <DateFieldMod showTime
                                      label="Updated At"
                                      source="updatedAt"/>
                    </SimpleShowLayout>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab disabled={ !disputeDetails }
                                      label="Details"
                                      path="details">
                    <SimpleShowLayout record={ disputeDetails }>
                        <TextField label="Country"
                                   source="address.countryCodeAlpha3"/>
                        <TextField label="Region"
                                   source="address.region"/>
                        <TextField label="Locality"
                                   source="address.locality"/>
                        <TextField label="Postal Code"
                                   source="address.postalCode"/>
                        <TextField label="Name"
                                   source="name"/>
                        <TextField label="Description"
                                   source="description"/>
                        <TextField label="Category"
                                   source="category"/>
                        <TextField label="Category Code"
                                   source="categoryCode"/>
                    </SimpleShowLayout>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    )
}
