import {Extension} from '@tiptap/react';
import {useTiptapEditor} from "ra-input-rich-text";
import * as React from "react";
import {useEffect, useState} from "react";
import {alpha, styled} from "@mui/material/styles";
import {List, ListItem, ListItemText, Menu, MenuItem} from "@mui/material";
import clsx from "clsx";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

/**
 * FontSize - Custom Extension
 * editor.commands.setFontSize(e.target.value) :set the font-size.
 */


export const FontSize = Extension.create({
	name: 'fontSize',
	addOptions() {
		return {
			types: ['textStyle'],
		};
	},
	addGlobalAttributes() {
		return [
			{
				types: this.options.types,
				attributes: {
					fontSize: {
						default: null,
						parseHTML: element => element.style.fontSize.replace(/['"]+/g, ''),
						renderHTML: attributes => {
							if (!attributes.fontSize) {
								return {};
							}
							return {
								style: `font-size: ${attributes.fontSize}`,
							};
						},
					},
				},
			},
		];
	},
	addCommands() {
		return {
			setFontSize: fontSize => ({chain}) => {
				return chain()
					.setMark('textStyle', {fontSize: fontSize + "px"})
					.run();
			},
			unsetFontSize: () => ({chain}) => {
				return chain()
					.setMark('textStyle', {fontSize: null})
					.removeEmptyTextStyle()
					.run();
			},
		};
	},
});


export const FontSizeSelect = ({size}) => {

	const editor = useTiptapEditor();
	const [anchorElement, setAnchorElement] = useState(
		null
	);

	const [selectedOption, setSelectedOption] = useState(defaultOption);

	const handleMenuItemClick = (event, index) => {
		setAnchorElement(null);
		const selectedItem = options[index];
		editor
			.chain()
			.focus()
			.setFontSize(selectedItem.value)
			.run();
	};


	const handleClickListItem = (event) => {
		setAnchorElement(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorElement(null);
	};

	useEffect(() => {
		const handleUpdate = () => {
			if (!editor) return;
			const selected = options.find((option) =>
					editor.isActive('textStyle', {fontSize: option.value ? `${option.value}px` : null})) ||
				defaultOption;
			setSelectedOption(selected);
		};

		if (editor) {
			editor.on('update', handleUpdate);
			editor.on('selectionUpdate', handleUpdate);
		}

		return () => {
			if (editor) {
				editor.off('update', handleUpdate);
				editor.off('selectionUpdate', handleUpdate);
			}
		};
	}, [editor]);

	return (
		<Root>
			<List
				component="nav"
				aria-label="Select the font size"
				dense
				disablePadding
				className={classes.list}
			>
				<ListItem
					button
					aria-haspopup="true"
					aria-controls="level-menu"
					aria-label="Current size"
					disabled={!editor?.isEditable}
					onClick={handleClickListItem}
					className={clsx({
						[classes.sizeSmall]: size === 'small',
						[classes.sizeLarge]: size === 'large',
					})}
				>
					<ListItemText
						primary={selectedOption.label}
					/>
					<ArrowDropDownIcon/>
				</ListItem>
			</List>
			<Menu
				anchorEl={anchorElement}
				open={Boolean(anchorElement)}
				id="size-menu"
				onClose={handleClose}
			>
				{options.map((option, index) => (
					<MenuItem
						key={option.label}
						selected={option === selectedOption}
						onClick={event => {
							handleMenuItemClick(event, index);
						}}
					>
						{option.label}
					</MenuItem>
				))}
			</Menu>
		</Root>
	);
}

const defaultOption = {
	label: 'Default',
	value: null,
}

const options = [
	defaultOption,
	{
		label: '10',
		value: 10,
	},
	{
		label: '11',
		value: 11,
	},
	{
		label: '12',
		value: 12,
	},
	{
		label: '13',
		value: 13,
	},
	{
		label: '14',
		value: 14,
	},
	{
		label: '15',
		value: 15,
	},
	{
		label: '16',
		value: 16,
	},
	{
		label: '18',
		value: 18,
	},
	{
		label: '20',
		value: 20,
	},
	{
		label: '22',
		value: 22,
	},
	{
		label: '24',
		value: 24,
	},
	{
		label: '28',
		value: 28,
	},
	{
		label: '32',
		value: 32,
	},
]

const PREFIX = 'RaRichTextInputFontSizeSelect';
const classes = {
	list: `${PREFIX}-list`,
	sizeSmall: `${PREFIX}-sizeSmall`,
	sizeLarge: `${PREFIX}-sizeLarge`,
};

const Root = styled('div')(({theme}) => ({
	[`&.${classes.list}`]: {
		borderRadius: theme.shape.borderRadius,
		border: `1px solid ${alpha(theme.palette.action.active, 0.12)}`,
	},
	[`& .${classes.sizeSmall}`]: {
		paddingTop: 1,
		paddingBottom: 1,
		'& .MuiTypography-root': {
			fontSize: theme.typography.pxToRem(13),
		},
	},
	[`& .${classes.sizeLarge}`]: {
		paddingTop: 8,
		paddingBottom: 8,
		'& .MuiTypography-root': {
			fontSize: theme.typography.pxToRem(15),
		},
	},
}));
