import React from "react";
import {SettingsList} from "../../../Component/Settings/SettingsList";
import {repaymentConfigTypes} from "./Constant/repaymentConfigTypes";

export const RepaymentSettings = () => {
    return (
        <SettingsList
            resource="configs/repayments"
            title="Repayment configs"
            configList={repaymentConfigTypes}
            pagination={false}
        />
    );
};
