import React, {useState} from "react";
import {useGet} from "../../Hooks/useGet";
import BarChartGraph from "../../Component/Charts/BarChartGraph";
import {LoadingPage} from "react-admin";
import MarketValueStatistic from "./MarketValueStatistic";


const datasetConfig = [
	{
		label: '',
		legend: 'Total Market Value For Creators',
		legendColor: '#8264F5',
		dataKey: 'avgMarketValue',
		backgroundColor: '#AF9CF9',
	},
]

export const MarketValueDashboard = () => {
	const year = new Date().getFullYear();
	const [filterData, setFilterData] = useState({year});
	const {data, isLoading} = useGet('overview/market-value', {meta: {query: filterData}});

	const availableYears = data?.availableYears?.length ?
		data.availableYears.map(year => ({value: year, label: year.toString()})) :
		[{
			value: year,
			label: year.toString()
		}]

	const onChangeYear = year => {
		setFilterData({year: +year})
	}

	return isLoading ? <LoadingPage/> : (
		<div style={{'display': 'flex', 'width': '100%'}}>
			<div style={{
				'display': 'flex',
				'flexDirection': 'column',
				'width': '100%',
			}}>
				<BarChartGraph availableYears={availableYears ?? []} filterData={filterData}
											 datasetConfig={datasetConfig} handleChange={onChangeYear} graphic={data?.graphic}/>
				<MarketValueStatistic data={data.blocks}/>
			</div>
		</div>
	)
};

export default MarketValueDashboard;
