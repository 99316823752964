import {SvgIcon} from "@mui/material";

export const DeleteRow = (props) => {
    return (<SvgIcon fontSize="inherit" {...props} width="44" height="44" viewBox="0 0 44 44" fill="none">
        <path d="M2.7749 6.94127C2.7749 6.38898 3.22262 5.94127 3.7749 5.94127H11.9416V11.8889H2.7749V6.94127Z"
              fill="white"/>
        <path d="M12.5364 5.9413H21.703V11.8889H12.5364V5.9413Z" fill="white"/>
        <path d="M22.297 5.9413H31.4637V11.8889H22.297V5.9413Z" fill="white"/>
        <path d="M2.7749 12.4837H11.9416V18.4314H2.7749V12.4837Z" fill="white"/>
        <path d="M2.7749 19.0262H11.9416V24.9738H2.7749V19.0262Z" fill="#C35E5B"/>
        <path d="M2.7749 25.5686H11.9416V31.5163H2.7749V25.5686Z" fill="white"/>
        <path d="M2.7749 32.1111H11.9416V38.0587H2.7749V32.1111Z" fill="white"/>
        <path d="M12.5364 12.4837H21.703V18.4314H12.5364V12.4837Z" fill="white"/>
        <path d="M12.5364 25.5686H21.703V31.5163H12.5364V25.5686Z" fill="white"/>
        <path d="M12.5364 19.0262H21.703V24.9738H12.5364V19.0262Z" fill="#C35E5B"/>
        <path d="M12.5364 32.1111H21.703V38.0587H12.5364V32.1111Z" fill="white"/>
        <path d="M22.297 25.5686H31.4637V31.5163H22.297V25.5686Z" fill="white"/>
        <path d="M22.297 12.4837H31.4637V18.4314H22.297V12.4837Z" fill="white"/>
        <path d="M22.297 19.0262H31.4637V24.9738H22.297V19.0262Z" fill="#C35E5B"/>
        <path d="M22.297 32.1111H31.4637V38.0587H22.297V32.1111Z" fill="white"/>
        <path d="M11.9415 5.94127H12.5363V38.0587H11.9415V5.94127Z" fill="#C7C7C7"/>
        <path d="M32.0583 11.8889L32.0583 12.4837L2.77494 12.4837L2.77494 11.8889L32.0583 11.8889Z" fill="#C7C7C7"/>
        <path d="M32.0583 18.4314L32.0583 19.0261L2.77494 19.0261L2.77494 18.4314L32.0583 18.4314Z" fill="#C7C7C7"/>
        <path d="M32.0583 24.9738L32.0583 25.5686L2.77494 25.5686L2.77494 24.9738L32.0583 24.9738Z" fill="#C7C7C7"/>
        <path d="M32.0583 31.5163L32.0583 32.1111L2.77494 32.1111L2.77494 31.5163L32.0583 31.5163Z" fill="#C7C7C7"/>
        <path d="M21.703 5.94127H22.2978V38.0587H21.703V5.94127Z" fill="#C7C7C7"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.18079 38.6535C2.6285 38.6535 2.18079 38.2058 2.18079 37.6535V6.3465C2.18079 5.79422 2.6285 5.3465 3.18079 5.3465H31.0584C31.6106 5.3465 32.0584 5.79422 32.0584 6.3465L32.0584 37.6535C32.0584 38.2058 31.6107 38.6535 31.0584 38.6535H3.18079ZM2.77555 6.94127C2.77555 6.38898 3.22327 5.94127 3.77555 5.94127L30.4636 5.94127C31.0159 5.94127 31.4636 6.38898 31.4636 6.94127V37.0587C31.4636 37.611 31.0159 38.0587 30.4636 38.0587H3.77555C3.22327 38.0587 2.77555 37.611 2.77555 37.0587V6.94127Z"
              fill="#7D7D7D"/>
        <mask id="mask0_4_640" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="32" y="16" width="12"
              height="12">
            <rect x="32.5195" y="16.2598" width="11.4804" height="11.4804" fill="#C35E5B"/>
        </mask>
        <g mask="url(#mask0_4_640)">
            <path
                d="M37.0165 24.1533L38.2603 22.9095L39.5041 24.1533L40.1739 23.4836L38.93 22.2397L40.1739 20.9959L39.5041 20.3262L38.2603 21.57L37.0165 20.3262L36.3467 20.9959L37.5905 22.2397L36.3467 23.4836L37.0165 24.1533ZM35.8683 26.3061C35.6052 26.3061 35.38 26.2125 35.1928 26.0253C35.0053 25.8378 34.9115 25.6125 34.9115 25.3493V19.1302H34.4331V18.1734H36.8251V17.695H39.6955V18.1734H42.0875V19.1302H41.6091V25.3493C41.6091 25.6125 41.5155 25.8378 41.3283 26.0253C41.1407 26.2125 40.9154 26.3061 40.6523 26.3061H35.8683Z"
                fill="#C35E5B"/>
        </g>
    </SvgIcon>)
}
