import {Confirm, maxValue, minValue, regex, required, TextInput, useNotify, useRefresh, useUpdate,} from "react-admin";
import {FormProvider, useForm} from "react-hook-form";
import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import {CircularProgress, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

export const UpdateOffsetModal = ({offset, channelId, onClose}) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const [updateChannelOffset, {isLoading}] = useUpdate();
	const [confirmationOpen, setConfirmationOpen] = useState(false)
	const form = useForm({
		values: {
			offset,
		},
		shouldFocusError: true,
		mode: "onChange",
		reValidateMode: "onChange",
	});

	const oUpdateChannelOffset = () => {
		if (isLoading) {
			return;
		}
		const {offset} = form.getValues()
		updateChannelOffset(
			`social-networks/offset/${channelId}`,
			{
				data: {offset},
				meta: {resourceSuffix: ""},
			},
			{
				onSuccess: () => {
					refresh();
					notify("Channel adjustment updated");
					onClose();
				},
				onError: (error) => {
					notify(error.message, {type: "error"});
					form.setError("offset", {type: "custom", message: error.message});
				},
			}
		).then();
	};

	const submit = () => {
		setConfirmationOpen(true);
	};

	const validationRules = [
		required(),
		minValue(0),
		maxValue(1000000),
		regex(
			/^\d+(\.\d{1,2})?$/,
			"Please enter a valid number with max 2 symbols after dot"
		),
	];

	return (
		<>
			<Dialog open={true} onClose={onClose} disableEnforceFocus={true}>
				<DialogTitle>Update user channel adjustment</DialogTitle>
				<DialogContent sx={{width: "400px"}}>
					<FormProvider {...form}>
						<form onSubmit={form.handleSubmit(submit)}>
							<TextInput
								name="offset"
								source="offset"
								fullWidth
								helperText={false}
								validate={validationRules}
							/>

							<DialogActions sx={{gap: '8px'}}>
								<Button onClick={onClose}>Cancel</Button>
								<Button
									variant="contained"
									type="submit"
									color="primary"
									disabled={isLoading || !form.formState.isValid}
								>
									{isLoading && <CircularProgress size={25} thickness={2}/>}
									Save
								</Button>
							</DialogActions>
						</form>
					</FormProvider>
				</DialogContent>
			</Dialog>
			<Confirm
				isOpen={confirmationOpen}
				loading={isLoading}
				title={`Warning!`}
				content="Adjustment will be applied to all channel links."
				CancelIcon={() => ""}
				onConfirm={() => {
					setConfirmationOpen(false)
					oUpdateChannelOffset()
				}}
				onClose={() => setConfirmationOpen(false)}
			/>
		</>
	);
};
