import {SvgIcon} from "@mui/material";

export const DeleteTable = (props) => {
    return (<SvgIcon fontSize="inherit" {...props} width="44" height="44" viewBox="0 0 44 44" fill="none">
        <g clipPath="url(#clip0_4_718)">
            <path d="M3.56238 7.7746C3.56238 6.76208 4.38319 5.94127 5.39571 5.94127H15.4577V11.8889H3.56238V7.7746Z"
                  fill="white"/>
            <path d="M16.0524 5.94128H27.9477V11.8889H16.0524V5.94128Z" fill="white"/>
            <path d="M28.5425 5.94128H38.6044C39.617 5.94128 40.4378 6.7621 40.4378 7.77462V11.8889H28.5425V5.94128Z"
                  fill="white"/>
            <path d="M3.56238 12.4837H15.4577V18.4314H3.56238V12.4837Z" fill="white"/>
            <path d="M3.56238 19.0262H15.4577V24.9738H3.56238V19.0262Z" fill="white"/>
            <path d="M3.56238 25.5686H15.4577V31.5163H3.56238V25.5686Z" fill="white"/>
            <path d="M3.56238 32.1111H15.4577V38.0587H3.56238V32.1111Z" fill="white"/>
            <path d="M16.0524 12.4837H27.9477V18.4314H16.0524V12.4837Z" fill="white"/>
            <path d="M16.0524 25.5686H27.9477V31.5163H16.0524V25.5686Z" fill="white"/>
            <path d="M16.0524 19.0262H27.9477V24.9738H16.0524V19.0262Z" fill="white"/>
            <path d="M16.0524 32.1111H27.9477V38.0587H16.0524V32.1111Z" fill="white"/>
            <path d="M28.5425 25.5686H40.4378V31.5163H28.5425V25.5686Z" fill="white"/>
            <path d="M28.5425 12.4837H40.4378V18.4314H28.5425V12.4837Z" fill="white"/>
            <path d="M28.5425 19.0262H40.4378V24.9738H28.5425V19.0262Z" fill="white"/>
            <path d="M28.5425 32.1111H40.4378V38.0587H28.5425V32.1111Z" fill="white"/>
            <path d="M15.4576 5.94127H16.0524V38.0587H15.4576V5.94127Z" fill="#C7C7C7"/>
            <path d="M40.4377 11.8889L40.4377 12.4837L3.56228 12.4837L3.56228 11.8889L40.4377 11.8889Z" fill="#C7C7C7"/>
            <path d="M40.4377 18.4314L40.4377 19.0262L3.56228 19.0262L3.56228 18.4314L40.4377 18.4314Z" fill="#C7C7C7"/>
            <path d="M40.4377 24.9738L40.4377 25.5686L3.56228 25.5686L3.56228 24.9738L40.4377 24.9738Z" fill="#C7C7C7"/>
            <path d="M40.4377 31.5163L40.4377 32.1111L3.56228 32.1111L3.56228 31.5163L40.4377 31.5163Z" fill="#C7C7C7"/>
            <path d="M27.9478 5.94127H28.5425V38.0587H27.9478V5.94127Z" fill="#C7C7C7"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M41.0325 7.17987C41.0325 6.16735 40.2117 5.34654 39.1991 5.34654L4.80091 5.34653C3.78839 5.34653 2.96758 6.16734 2.96758 7.17986L2.96753 36.8202C2.96753 37.8327 3.78834 38.6535 4.80086 38.6535H39.1991C40.2117 38.6535 41.0325 37.8327 41.0325 36.8202V7.17987ZM40.4377 7.77463C40.4377 6.76211 39.6169 5.9413 38.6044 5.9413L5.39568 5.9413C4.38316 5.9413 3.56235 6.76211 3.56235 7.77463V36.2254C3.56235 37.2379 4.38316 38.0587 5.39568 38.0587H38.6044C39.6169 38.0587 40.4377 37.2379 40.4377 36.2254V7.77463Z"
                  fill="#7D7D7D"/>
            <rect x="37.5261" y="35.7798" width="4.55786" height="4.55791" fill="white"/>
            <mask id="mask0_4_718" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="34" y="32" width="12"
                  height="12">
                <rect x="34.0446" y="32.3185" width="11.4804" height="11.4804" fill="#C35E5B"/>
            </mask>
            <g mask="url(#mask0_4_718)">
                <path
                    d="M38.5415 40.2121L39.7853 38.9683L41.0292 40.2121L41.6989 39.5423L40.4551 38.2985L41.6989 37.0547L41.0292 36.3849L39.7853 37.6287L38.5415 36.3849L37.8717 37.0547L39.1156 38.2985L37.8717 39.5423L38.5415 40.2121ZM37.3933 42.3649C37.1302 42.3649 36.905 42.2713 36.7178 42.0841C36.5303 41.8965 36.4365 41.6712 36.4365 41.4081V35.1889H35.9581V34.2321H38.3501V33.7537H41.2205V34.2321H43.6125V35.1889H43.1341V41.4081C43.1341 41.6712 43.0405 41.8965 42.8533 42.0841C42.6658 42.2713 42.4404 42.3649 42.1773 42.3649H37.3933Z"
                    fill="#C35E5B"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_4_718">
                <rect width="44" height="44" fill="white"/>
            </clipPath>
        </defs>
    </SvgIcon>)
}
