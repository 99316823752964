import React, {forwardRef} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Collapse, List, ListItemIcon, MenuItem} from "@mui/material";
import clsx from "clsx";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {MenuItemLink} from "react-admin";
import {Link} from "react-router-dom";


const LinkRef = forwardRef((props, ref) => (
	<Link ref={ref} {...props} />
));
export const SubMenu = ({item, expandedItem, toggleCollapse}) => (
	<div>
		<MenuItem
			component={LinkRef}
			className={clsx({'RaMenuItemLink-active': item.url === expandedItem})}
			onClick={() => toggleCollapse(item.url)}
			sx={{
				color: (theme) => theme.palette.text.secondary,
				borderLeft: '3px solid transparent',
				'& .RaMenuItemLink-icon': {
					minWidth: (theme) => theme.spacing(5)
				},
				'&.RaMenuItemLink-active': {
					color: theme => theme.palette.text.primary
				}
			}}
		>
			<ListItemIcon className="RaMenuItemLink-icon">
				{item.icon}
			</ListItemIcon>

			{item.label}

			<ListItemIcon className="RaMenuItemLink-icon"
										sx={{marginLeft: 'auto', justifyContent: 'flex-end'}}>
				{expandedItem === item.url ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
			</ListItemIcon>
		</MenuItem>
		<Collapse in={expandedItem === item.url} timeout="auto" unmountOnExit>
			<List component="li" disablePadding>
				{
					item.children.map(child =>
						<MenuItemLink
							key={child.url}
							to={{pathname: `${item.url}${child.url}`}}
							primaryText={child.label}
							leftIcon={child.icon}
							sx={{
								paddingLeft: 3
							}}
						/>
					)
				}
			</List>
		</Collapse>
	</div>
);

