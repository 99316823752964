import {Card, CardContent, Link, List, ListItem} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';

import {Title} from 'react-admin';
import {DOCUMENT_LIST} from "./Constant/constant";

export const PolicyDocumentList = () => {
    return <Card>
        <Title title="Documents"/>
        <CardContent>
            <List>
                {
                    DOCUMENT_LIST.map((item) => {
                        return <ListItem key={item.type}>
                            <Link component={RouterLink} to={`/settings/documents/${item.type}`}>
                                {item.title}
                            </Link>
                        </ListItem>
                    })
                }
            </List>
        </CardContent>
    </Card>
}
