import React from 'react';
import {Datagrid, LoadingPage, SelectField, ShowButton, TextField, usePermissions} from 'react-admin';
import ListMod from "../../Component/Redesign/ListMod";
import {HolderStatusSelection} from "../../Utils/constant/Const";
import UrlField from "../../Component/Redesign/UrlField";
import DateFieldMod from "../../Component/Redesign/DateFieldMod";
import {getHighNoteOrganizationLink} from "../../Utils/constant/HighNote";
import {hasAdminRole} from "../../Utils/helpers/permissions";

export const AccountHolderList = () => {

    const higNoteLink = getHighNoteOrganizationLink();
    const {permissions, isLoading} = usePermissions();

    return !isLoading ?
        <ListMod title="User requests"
                 basePath="/holders"
        >
            <Datagrid bulkActionButtons={false}>
                <TextField label={"ID"} source="id"/>
                {
                    hasAdminRole(permissions) && [
                        <TextField key="user_name" label={"USER NAME"} source="user_name"/>,
                        <TextField key="name" label={"AUTHORIZED PERSON"} source="name"/>,
                        <TextField key="email" label={"EMAIL"} source="email"/>,
                    ]
                }
                <TextField label={"LEGAL BUSINESS NAME"} source="business_name"/>
                <SelectField label={"STATUS"} source="status" choices={HolderStatusSelection}/>
                <DateFieldMod showTime label={"CREATED AT"} source="createdAt"/>
                <UrlField label='HIGHNOTE LINK'
                    source='holder_id'
                    sortable={false}
                    val={higNoteLink + '/account-holders/'}
                    target='_blank' // New window
                    rel="noopener noreferrer" // For security
                />
                <ShowButton/>
            </Datagrid>
        </ListMod> :
        <LoadingPage/>
}
