import {fetchUtils} from 'react-admin';
import {stringify} from 'qs';
import inMemoryJWT from '../Auth/inMemoryJwt';
import {ENV} from '../Const/ProjectEnv';

const apiUrl = ENV.HOST_BACKEND + '/admin';

const httpClient = (url, options = {}, config = {blob: false}) => {
	if (!options.headers) {
		options.headers = new Headers({});
	}

	if (config.isPublicRequest) {
		return config.blob ? fetchBlob(url, options) : fetchUtils.fetchJson(url, options);
	}

	const token = inMemoryJWT.getToken();

	if (token) {
		options.headers.set('Authorization', `Bearer ${token}`);
		const req = config.blob ? fetchBlob(url, options) : fetchUtils.fetchJson(url, options);
		return req.catch(err => {
			if (err.status === 403 && err.error?.message !== 'Access denied') {
				return inMemoryJWT.getRefreshedToken().then(data => {
					if (data?.token) {
						options.headers.set('Authorization', `Bearer ${data.token}`);
						return config.blob ? fetchBlob(url, options) : fetchUtils.fetchJson(url, options)
					}
					return Promise.reject(err);
				})
			}
			return Promise.reject(err);
		})
	}

	inMemoryJWT.ereaseToken()
	return Promise.reject()
};

const fetchBlob = (url, options = {}) => {
	return fetch(url, {...options})
		.then(response =>
			response.blob().then(blob => ({
				status: response.status,
				statusText: response.statusText,
				headers: response.headers,
				body: blob,
			}))
		)
		.then(({status, statusText, headers, body}) => {
			if (status < 200 || status >= 300) {
				return false;
			}

			return Promise.resolve({status, headers, body});
		});
};

export function buildFormData(data, parentKey = '', formData = new FormData()) {
	if (data instanceof FormData) {
		return data
	}
	if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
		if (data.hasOwnProperty('rawFile')) {
			buildFormData(data['rawFile'], parentKey, formData)
		} else {
			Object.keys(data).forEach(key => {
				buildFormData(data[key], parentKey ? `${parentKey}[${key}]` : key, formData);
			});
		}
	} else if (data && data instanceof Date) {
		data.setTime(data.getTime() - data.getTimezoneOffset() * 60 * 1000);
		formData.append(parentKey, data.toISOString());
	} else {
		formData.append(parentKey, data == null ? '' : data);
	}
	return formData
}

const buildUrl = ({resource, id, resourceSuffix, query}) => {
	if (!resource) {
		throw new Error('resource arg required')
	}
	const url = [id, resourceSuffix].reduce((acc, curr) => {
		return curr ? acc.concat(`/${curr}`) : acc
	}, `${apiUrl}/${resource}`);
	return query ? `${url}?${stringify(query)}` : url;
}

const init = {
	getList: (resource, params) => {
		const query = {
			sort: {
				column: params.sort.field,
				direction: params.sort.order
			},
			size: params.pagination.perPage,
			page: params.pagination.page,
			filter: params.filter,
		};

		const url = buildUrl({resource, query});

		return httpClient(url).then(({headers, json}) => ({
			data: json.data.items,
			total: json.data.pagination.total,
		}));
	},

	getOne: (resource, params) => {
		const url = buildUrl({resource, id: params?.id, resourceSuffix: params?.meta?.resourceSuffix});
		return httpClient(url).then(({json}) => ({
			data: json.data
		}));
	},

	get: (resource, {id, meta} = {}) => {
		const url = buildUrl({resource, id, query: meta?.query, resourceSuffix: meta?.resourceSuffix});
		return httpClient(url).then(({json}) => ({
			data: json.data
		}));
	},

	getMany: (resource, params) => {
		const query = {filter: {id: params.ids}};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;

		return httpClient(url).then(({json}) => ({data: json.data.items}));
	},

	getManyReference: (resource, params) => {
		const query = {
			sort: {
				column: params.sort.field,
				direction: params.sort.order
			},
			size: params.pagination.perPage,
			page: params.pagination.page,
			filter: {
				...params.filter,
				[params.target]: params.id
			},
		};
		const url = buildUrl({resource, query});
		return httpClient(url).then(({headers, json}) => ({
			data: json.data.items,
			total: json.data.pagination.total,
		}));
	},

	update: (resource, {data, id, meta, previousData} = {}) => {
		const body = buildFormData(data)
		const resourceSuffix = meta?.resourceSuffix !== undefined ? meta?.resourceSuffix : 'edit'
		const url = buildUrl({resource, id, resourceSuffix})
		return httpClient(url, {
			method: 'POST',
			body,
		})
			.then(({json}) => {
				return {data: {...previousData, id, ...data, ...json.data}} //hack, response must have updated `data` object
			})
			.catch(error => {
				if (!error.body.status) {
					error.message = error.body.message;
					error.status = error.body.code;
					error.data = error.body.data;
				}

				return Promise.reject(error); // rethrow it
			});
	},

	updateMany: (resource, params) => {
		const query = {filter: JSON.stringify({id: params.ids})};
		const url = buildUrl({resource, query});

		return httpClient(url, {
			method: 'POST',
			body: JSON.stringify(params.data),
		})
			.then(({json}) => ({
				data: {...params.data, ...json.data}
			}));

	},

	create: (resource, params) => {
		const {data, meta} = params;
		const body = buildFormData(data);
		const resourceSuffix = meta?.resourceSuffix !== undefined ? meta?.resourceSuffix : 'create';
		const url = buildUrl({resource, resourceSuffix});
		return httpClient(url, {
			method: 'POST',
			body,
		}, {isPublicRequest: meta?.isPublicRequest}).then(response => {
			return response;
		}).then(({json}) => ({
			data: {id: null, ...params.data, ...json.data}, //hack, response must have `id` parameter
		})).catch(error => {
			if (!error.body.status) {
				error.message = error.body.message;
				error.status = error.body.code;
			}

			return Promise.reject(error); // rethrow it
		});
	},
	delete: (resource, params) => {
		const url = buildUrl({resource, id: params.id});
		return httpClient(url, {
			method: 'DELETE',
		}).then(({json}) => ({data: json})).catch(error => {
			if (!error.body.status) {
				error.message = error.body.message;
				error.status = error.body.code;
			}

			return Promise.reject(error); // rethrow it
		});
	},
	deleteMany: (resource, params) => {
		const query = {
			filter: JSON.stringify({id: params.ids}),
		};
		const url = buildUrl({resource, query});

		return httpClient(url, {
			method: 'DELETE',
			body: JSON.stringify(params.data),
		}).then(({json}) => ({data: json}));
	},
	uploadFile: (resource, params) => {
		const url = buildUrl({resource, id: params.id, query: params?.query});
		return httpClient(url, {}, {blob: true})
			.then(response => {
				if (!response) {
					return {data: 'fail'};
				}

				let filename = null;
				const disposition = response.headers.get('content-disposition');
				if (disposition && disposition.indexOf('attachment') !== -1) {
					const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					const matches = filenameRegex.exec(disposition);
					if (matches !== null && matches[1]) {
						filename = matches[1].replace(/['"]/g, '');
					}
				}

				const url = window.URL.createObjectURL(response.body);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', filename);
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);

				return {data: 'ok'};
			});
	},
};

export default init;
