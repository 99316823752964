import CustomLayout from './App/Component/Layouts/CustomLayout';
import authProvider from './App/Auth/authProvider';
import {Admin, CustomRoutes, defaultTheme, Resource} from 'react-admin';
import dataProvider from './App/Api/api';
import * as React from 'react';
import {AdminList} from './App/Pages/Admin/list';
import {UserList} from './App/Pages/User/list';
import {AdminCreate} from './App/Pages/Admin/create';
import {AccountHolderList} from './App/Pages/Requests/list';
import {AccountHoldersShow} from './App/Pages/Requests/show';
import {CardList} from './App/Pages/Card/list';
import {CardShow} from './App/Pages/Card/show';
import {UserShow} from './App/Pages/User/show';
import Dashboard from './App/Pages/Overview/Dashboard';
import {Route} from 'react-router-dom';
import {PolicyDocumentList} from './App/Pages/Settings/Documents/list';
import {PolicyDocumentEdit} from './App/Pages/Settings/Documents/edit';
import {FinancialAccountList} from './App/Pages/FinancialAccount/list';
import {FinancialAccountShow} from './App/Pages/FinancialAccount/show';
import {RepaymentSettings} from './App/Pages/Settings/Repayment/RepaymentSettings';
import {CreditSettings} from './App/Pages/Settings/Credit/CreditSettings';
import {ROLE_ADMIN, ROLE_SUPER_ADMIN} from './App/Utils/constant/Permissions';
import {hasRole} from './App/Utils/helpers/permissions';
import {ReferralSettings} from './App/Pages/Settings/Referral/ReferralSettings';
import {AdminEdit} from './App/Pages/Admin/edit';
import LoginPage from './App/Pages/Login/LoginPage';
import {DisputesList} from './App/Pages/Disputes/list';
import {DisputesShow} from './App/Pages/Disputes/show';
import {UserEdit} from "./App/Pages/User/edit";
import MarketValueDashboard from "./App/Pages/MarketValueDashboard/MarketValueDashboard";
import {UserCreate} from "./App/Pages/User/create";
import {MarketValueSettingsList} from "./App/Pages/Settings/MarketValue/list";
import {List} from "./App/Pages/Settings/TopCreators/list";
import {TopCreatorCreate} from "./App/Pages/Settings/TopCreators/create";
import {TopCreatorEdit} from "./App/Pages/Settings/TopCreators/edit";

const theme = {
	...defaultTheme,
	header: {
		primary: {
			main: '#2196f3',
		},
	},
	sidebar: {
		width: 200,
		closedWidth: 54,
	},
	components: {
		...defaultTheme.components,
		MuiTypography: {
			styleOverrides: {
				body2: {
					fontWeight: 400,
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					fontWeight: 800,
				},
			},
		},
		RaMenuItemLink: {
			styleOverrides: {
				active: {
					borderLeft: '3px solid #2196f3',
				},
				root: {
					borderLeft: '3px solid #fff',
				},
			},
		},
		RaTabbedShowLayout: {
			styleOverrides: {
				root: {
					'& .RaSimpleShowLayout-stack .RaLabeled-label': {
						fontWeight: 700,
						fontSize: '1rem',
						color: '#000',
					},
				},
			},
		},
		RaRichTextInput: {
			styleOverrides: {
				root: {
					maxHeight: 'calc(100vh - 5rem)',
					overflow: 'auto',
					'& .RaRichTextInputToolbar-root': {
						padding: '1rem',
						backgroundColor: '#fff',
						position: 'sticky',
						top: 0,
						zIndex: 2,
						borderRadius: '4px 4px 0 0',
						border: '1px solid rgb(0 0 0 / 20%)',
						width: '100%',
					},
					'& .RaRichTextInput-editorContent .ProseMirror': {
						borderRadius: '0 0 4px 4px',
						minHeight: '20rem',
					},
					'& table': {
						borderCollapse: 'collapse',
						margin: 0,
						overflow: 'hidden',
						tableLayout: 'fixed',
						width: '100%',
					},
					'& td,& th': {
						border: '2px solid #ced4da',
						boxSizing: 'border-box',
						minWidth: '1em',
						padding: '3px 5px',
						position: 'relative',
						verticalAlign: 'top',
					},
					'& td > *,& th > *': {
						marginBottom: 0,
					},
					'& th': {
						backgroundColor: '#f1f3f5',
						fontWeight: 'bold',
					},
					'& .selectedCell:after': {
						background: 'rgba(200, 200, 255, 0.4)',
						content: '""',
						left: 0,
						right: 0,
						top: 0,
						bottom: 0,
						pointerEvents: 'none',
						position: 'absolute',
						zIndex: 2,
					},
					'& .column-resize-handle ': {
						backgroundColor: '#adf',
						bottom: '-2px',
						position: 'absolute',
						right: '-2px',
						pointerEvents: 'none',
						top: 0,
						width: '4px',
					},
					'& p': {
						margin: 0,
					},
					'& .tableWrapper': {
						padding: '1rem 0',
						overflowX: 'auto',
					},
					'& .resize-cursor': {
						cursor: 'col-resize',
					},
				},
			},
		},
	},
};

const App = () => {


	return (
		<Admin theme={theme}
					 loginPage={LoginPage}
					 layout={CustomLayout}
					 dataProvider={dataProvider}
					 authProvider={authProvider}
					 disableTelemetry>
			{(permissions) => (
				<>
					<Resource name="/overview/market-value" list={MarketValueDashboard}/>
					<Resource name="users"
										list={UserList}
										show={UserShow}
										edit={UserEdit}
										create={UserCreate}/>
					{hasRole(ROLE_SUPER_ADMIN, permissions) ? (
						<Resource name="admins"
											list={AdminList}
											edit={AdminEdit}
											create={AdminCreate}/>
					) : null},
					<Resource name="holders"
										list={AccountHolderList}
										show={AccountHoldersShow}/>
					{hasRole([ROLE_SUPER_ADMIN, ROLE_ADMIN], permissions) ? (
						<Resource name="financial-accounts"
											list={FinancialAccountList}
											show={FinancialAccountShow}
						/>
					) : null}
					,
					<Resource name="cards"
										list={CardList}
										show={CardShow}/>
					<Resource name="subscriptions"/>
					<Resource name="disputes"
										list={DisputesList}
										show={DisputesShow}/>
					<Resource name="transactions"/>
					<Resource name="accounts"/>
					<Resource name="credit-limits"/>
					<Resource name="activity"/>
					<Resource name="repayments"/>
					<Resource name="social-networks"/>
					<CustomRoutes>
						<Route path="/settings/market-value" element={<MarketValueSettingsList/>}/>
						<Route path="/settings/market-value/:id" element={<MarketValueSettingsList/>}/>
					</CustomRoutes>
					{hasRole(ROLE_SUPER_ADMIN, permissions) ? (
						<CustomRoutes>
							<Route path="/overview/banking-stats"
										 element={<Dashboard/>}/>

							<Route
								path="/settings/documents"
								element={<PolicyDocumentList/>}
							/>
							<Route
								path="/settings/documents/:type"
								element={<PolicyDocumentEdit/>}
							/>
							<Route
								path="/settings/repayment"
								element={<RepaymentSettings/>}
							/>
							<Route path="/settings/credit" element={<CreditSettings/>}/>
							<Route path="/settings/referral" element={<ReferralSettings/>}/>
							<Route path="/settings/top-creator" element={<List/>}/>
							<Route path="/settings/top-creator/create" element={<TopCreatorCreate/>}/>
							<Route path="/settings/top-creator/:id" element={<TopCreatorEdit/>}/>

						</CustomRoutes>
					) : null}
				</>
			)}
		</Admin>
	);
};

export default App;
