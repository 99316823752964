import {MenuItemLink, usePermissions} from "react-admin";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import React, {useState} from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import FolderIcon from "@mui/icons-material/Folder";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CalculateIcon from "@mui/icons-material/Calculate";
import {ALL_ADMIN_ROLES, ROLE_ADMIN, ROLE_SUPER_ADMIN,} from "../../Utils/constant/Permissions";
import {hasRole} from "../../Utils/helpers/permissions";
import {SubMenu} from "./SubMenu";
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {useLocation} from "react-router-dom";
import TimelineIcon from '@mui/icons-material/Timeline';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

const navLinks = [
	{
		url: "/overview",
		label: "Overview",
		icon: <AnalyticsIcon/>,
		roles: ALL_ADMIN_ROLES,
		children: [
			{
				url: "/banking-stats",
				label: "Banking Stats",
				icon: <LeaderboardIcon/>,
			},
			{
				url: "/market-value",
				label: "Market Value",
				icon: <TrendingUpIcon/>,
			},
		]
	},
	{
		url: "/users",
		label: "Users",
		icon: <PeopleAltIcon/>,
		roles: ALL_ADMIN_ROLES,
	},
	{
		url: "/admins",
		label: "Admins",
		icon: <AdminPanelSettingsIcon/>,
		roles: [ROLE_SUPER_ADMIN],
	},
	{
		url: "/holders",
		label: "Requests",
		icon: <AccountBoxIcon/>,
		roles: ALL_ADMIN_ROLES,
	},
	{
		url: "/financial-accounts",
		label: "Financial accounts",
		icon: <LocalAtmIcon/>,
		roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
	},
	{
		url: "/cards",
		label: "Cards",
		icon: <CreditCardIcon/>,
		roles: ALL_ADMIN_ROLES,
	},
	{
		url: "/disputes",
		label: "Disputes",
		icon: <ChatBubbleOutlineIcon/>,
		roles: ALL_ADMIN_ROLES
	},
	{
		url: "/settings",
		label: "Settings",
		icon: <SettingsIcon/>,
		roles: [ROLE_SUPER_ADMIN],
		children: [
			{
				url: "/documents",
				label: "Documents",
				icon: <FolderIcon/>,
			},
			{
				url: "/repayment",
				label: "Repayment",
				icon: <RequestQuoteIcon/>,
			},
			{
				url: "/credit",
				label: "Credit",
				icon: <CalculateIcon/>,
			},
			{
				url: "/referral",
				label: "Referrals",
				icon: <SettingsAccessibilityIcon/>,
			},
			{
				url: "/market-value",
				label: "Market Value",
				icon: <TimelineIcon/>,
			},
			{
				url: "/top-creator",
				label: "Top Creators",
				icon: <FormatListNumberedIcon/>,
			},
		],
	},
	{
		url: "/settings",
		label: "Settings",
		icon: <SettingsIcon/>,
		roles: [ROLE_ADMIN],
		children: [
			{
				url: "/market-value",
				label: "Market Value",
				icon: <TimelineIcon/>,
			},
		],
	},
];

const CustomMenu = ({onMenuClick, dense = false}) => {
	const location = useLocation();
	const initialExpandedItem = navLinks.find((item) => item.children?.length ? location.pathname.includes(item.url) : null)?.url;
	const [expandedItem, setExpandedItem] = useState(initialExpandedItem ?? null);
	const {permissions, isLoading} = usePermissions();
	const toggleCollapse = (url) => {
		if (url === expandedItem) {
			setExpandedItem(null);
			return;
		}
		setExpandedItem(url);
	};

	return (
		<>
			{navLinks.map((item) =>
				!isLoading && hasRole(item.roles, permissions) ? (
					item?.children?.length ? (
						<SubMenu
							key={item.url}
							item={item}
							expandedItem={expandedItem}
							toggleCollapse={toggleCollapse}
						/>
					) : (
						<MenuItemLink
							key={item.url}
							to={{pathname: item.url}}
							primaryText={item.label}
							leftIcon={item.icon}
							onClick={() => {
								setExpandedItem(null);
							}}
							dense={dense}
						/>
					)
				) : null
			)}
		</>
	);
};

export default CustomMenu;
