import {SvgIcon} from "@mui/material";

export const MergeCells = (props) => {
    return (<SvgIcon fontSize="inherit" {...props} width="44" height="44" viewBox="0 0 44 44" fill="none">
        <path d="M2.7749 6.9411C2.7749 6.38882 3.22262 5.9411 3.7749 5.9411H11.9416V11.8887H2.7749V6.9411Z"
              fill="white"/>
        <path d="M12.5363 5.94113H21.7029V11.8888H12.5363V5.94113Z" fill="white"/>
        <path d="M22.297 5.94113H31.4637V11.8888H22.297V5.94113Z" fill="white"/>
        <path d="M32.0583 5.94113H40.225C40.7773 5.94113 41.225 6.38885 41.225 6.94113V11.8888H32.0583V5.94113Z"
              fill="white"/>
        <path d="M2.7749 12.4836H11.9416V18.4312H2.7749V12.4836Z" fill="white"/>
        <path d="M2.7749 19.026H11.9416V24.9737H2.7749V19.026Z" fill="white"/>
        <path d="M2.7749 25.5685H11.9416V31.5161H2.7749V25.5685Z" fill="white"/>
        <path d="M2.7749 32.1109H11.9416V38.0586H2.7749V32.1109Z" fill="white"/>
        <path d="M12.5363 12.4836H21.7029V18.4312H12.5363V12.4836Z" fill="white"/>
        <path d="M12.5363 25.5685H21.7029V31.5161H12.5363V25.5685Z" fill="white"/>
        <path d="M12.5363 19.026H21.7029V24.9737H12.5363V19.026Z" fill="white"/>
        <path d="M12.5363 32.1109H21.7029V38.0586H12.5363V32.1109Z" fill="white"/>
        <path d="M22.297 25.5685H31.4637V31.5161H22.297V25.5685Z" fill="white"/>
        <path d="M32.0583 25.5685H41.225V31.5161H32.0583V25.5685Z" fill="white"/>
        <path d="M22.297 12.4836H31.4637V18.4312H22.297V12.4836Z" fill="white"/>
        <path d="M32.0583 12.4836H41.225V18.4312H32.0583V12.4836Z" fill="white"/>
        <path d="M22.297 19.026H31.4637V24.9737H22.297V19.026Z" fill="white"/>
        <path d="M32.0583 19.026H41.225V24.9737H32.0583V19.026Z" fill="white"/>
        <path d="M22.297 32.1109H31.4637V38.0586H22.297V32.1109Z" fill="white"/>
        <path d="M32.0583 32.1109H41.225V38.0586H32.0583V32.1109Z" fill="white"/>
        <path d="M11.9415 24.9737H12.5363V38.0585H11.9415V24.9737Z" fill="#C7C7C7"/>
        <path d="M11.9415 5.94109H12.5363V18.4312H11.9415V5.94109Z" fill="#C7C7C7"/>
        <path d="M41.2251 11.8888L41.2251 12.4835L2.77495 12.4835L2.77495 11.8888L41.2251 11.8888Z" fill="#C7C7C7"/>
        <path d="M41.2251 31.5161L41.2251 32.1109L2.77495 32.1109L2.77495 31.5161L41.2251 31.5161Z" fill="#C7C7C7"/>
        <path d="M21.703 25.5685H22.2978V38.0585H21.703V25.5685Z" fill="#C7C7C7"/>
        <path d="M21.703 5.94109H22.2978V18.7215H21.703V5.94109Z" fill="#C7C7C7"/>
        <path d="M31.4636 25.2989H32.0584V38.0585H31.4636V25.2989Z" fill="#C7C7C7"/>
        <path d="M31.4636 5.94109H32.0584V18.7215H31.4636V5.94109Z" fill="#C7C7C7"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M41.8198 6.34667C41.8198 5.79439 41.3721 5.34667 40.8198 5.34667H3.18018C2.62789 5.34667 2.18018 5.79439 2.18018 6.34667V37.6533C2.18018 38.2056 2.62789 38.6533 3.18017 38.6533H40.8198C41.3721 38.6533 41.8198 38.2056 41.8198 37.6533V6.34667ZM2.77491 37.0586C2.77491 37.6108 3.22263 38.0586 3.77491 38.0586H40.2251C40.7773 38.0586 41.2251 37.6108 41.2251 37.0586V6.94144C41.2251 6.38915 40.7773 5.94144 40.2251 5.94144H3.77491C3.22263 5.94144 2.77491 6.38915 2.77491 6.94144V37.0586Z"
              fill="#7D7D7D"/>
        <path d="M41.2251 18.236L41.2251 19.026L2.77495 19.026L2.77495 18.236L41.2251 18.236Z" fill="#5694E1"/>
        <path d="M41.2251 24.9736L41.2251 25.7636L2.77495 25.7636L2.77495 24.9736L41.2251 24.9736Z" fill="#5694E1"/>
        <path d="M2.09253 18.236H2.88253V25.7636H2.09253V18.236Z" fill="#5694E1"/>
        <path d="M41.2251 18.236H42.0151V25.7636H41.2251V18.236Z" fill="#5694E1"/>
    </SvgIcon>)
}

