import {SvgIcon} from "@mui/material";

export const ToggleHeaderCell = (props) => {
    return (<SvgIcon fontSize="inherit" {...props} width="44" height="44" viewBox="0 0 44 44" fill="none">
        <path d="M2.77515 15.8775C2.77515 15.3252 3.22286 14.8775 3.77515 14.8775H11.9418V20.8251H2.77515V15.8775Z"
              fill="white"/>
        <path d="M12.5366 14.8775H21.7033V20.8251H12.5366V14.8775Z" fill="white"/>
        <path d="M32.0586 14.8775H40.2253C40.7775 14.8775 41.2253 15.3252 41.2253 15.8775V20.8251H32.0586V14.8775Z"
              fill="white"/>
        <path d="M2.77515 21.4199H11.9418V27.3676H2.77515V21.4199Z" fill="white"/>
        <path d="M2.77515 27.9624H11.9418V33.91H2.77515V27.9624Z" fill="white"/>
        <path d="M2.77515 34.5048H11.9418V40.4525H2.77515V34.5048Z" fill="white"/>
        <path d="M12.5366 21.4199H21.7033V27.3676H12.5366V21.4199Z" fill="white"/>
        <path d="M12.5366 34.5048H21.7033V40.4525H12.5366V34.5048Z" fill="white"/>
        <path d="M12.5366 27.9624H21.7033V33.91H12.5366V27.9624Z" fill="white"/>
        <path d="M32.0586 34.5048H41.2253V40.4525H32.0586V34.5048Z" fill="white"/>
        <path d="M22.2974 21.4199H31.464V27.3676H22.2974V21.4199Z" fill="#5694E1"/>
        <path d="M32.0586 21.4199H41.2253V27.3676H32.0586V21.4199Z" fill="white"/>
        <path d="M32.0586 27.9624H41.2253V33.91H32.0586V27.9624Z" fill="white"/>
        <path d="M11.9419 14.8775H12.5367V41.0472H11.9419V14.8775Z" fill="#C7C7C7"/>
        <path d="M41.2253 20.8251L41.2253 21.4199L2.77519 21.4199L2.77519 20.8251L41.2253 20.8251Z" fill="#C7C7C7"/>
        <path d="M41.2253 27.3676L41.2253 27.9623L2.77519 27.9623L2.77519 27.3676L41.2253 27.3676Z" fill="#C7C7C7"/>
        <path d="M41.2253 33.91L41.2253 34.5048L2.77519 34.5048L2.77519 33.91L41.2253 33.91Z" fill="#C7C7C7"/>
        <path d="M21.7034 14.8775H22.2981V41.0472H21.7034V14.8775Z" fill="#C7C7C7"/>
        <path d="M31.4639 14.8775H32.0586V41.0472H31.4639V14.8775Z" fill="#C7C7C7"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.18042 14.2827C2.62814 14.2827 2.18042 14.7304 2.18042 15.2827V40.0472C2.18042 40.5995 2.62813 41.0472 3.18042 41.0472L40.8195 41.0472C41.3718 41.0472 41.8195 40.5995 41.8195 40.0472V15.2827C41.8195 14.7304 41.3718 14.2827 40.8195 14.2827H3.18042ZM41.2247 15.8775C41.2247 15.3252 40.777 14.8775 40.2247 14.8775L3.77518 14.8775C3.2229 14.8775 2.77518 15.3252 2.77518 15.8775V39.4525C2.77518 40.0048 3.2229 40.4525 3.77518 40.4525L40.2247 40.4525C40.777 40.4525 41.2247 40.0048 41.2247 39.4525V15.8775Z"
              fill="#7D7D7D"/>
        <path
            d="M27.2219 11.2632V5.30222H28.1161V7.83561H30.7985V5.30222H31.6926V11.2632H30.7985V8.72975H28.1161V11.2632H27.2219Z"
            fill="#5694E1"/>
        <rect x="22.0693" y="5.28269" width="3.42857" height="0.857143" fill="#5694E1"/>
        <rect x="22.0693" y="6.99698" width="3.42857" height="0.857143" fill="#5694E1"/>
        <rect x="22.0693" y="8.71127" width="3.42857" height="0.857143" fill="#5694E1"/>
        <rect x="22.0693" y="10.4256" width="3.42857" height="0.857143" fill="#5694E1"/>
    </SvgIcon>)
}
