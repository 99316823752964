import {useParams} from "react-router-dom";
import {getDocumentTitleByType} from "./Constant/constant";
import {regex, required, TextInput, Title, useCreate, useNotify, useRedirect} from "react-admin";
import {Card, CardActions, CardContent, CardHeader, CircularProgress, Typography} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {DateTimeFormatString} from "../../../Component/Handlers/DateTimeFormat";
import {RichTextInputMod} from "../../../Component/RichTextInputMod/RichTextInputMod";
import Button from "@mui/material/Button";
import {makeStyles} from "tss-react/mui";
import {IsEmptyValue} from "../../../Component/Handlers/IsEmptyValue";
import {useGet} from "../../../Hooks/useGet";
import {NAME_PATTERN} from "../../../Utils/regex/regex";

const useStyles = makeStyles()({
	richText: {
		'& .RichTextInputTableButtons-root .MuiToggleButton-root': {
			fontSize: '1.5rem',
			padding: '8px'
		}
	}
})

const DocumentForm = ({values, loading, onCancel, onSubmit}) => {
	const {classes} = useStyles();

	const form = useForm({
		defaultValues: {
			title: '',
			content: '',
		},
		values: IsEmptyValue(values) ? {} : {title: values.title, content: values.content},
		reValidateMode: 'onSubmit'
	});


	const submit = values => {
		onSubmit(values)
	};

	const cancel = () => {
		onCancel()
	};

	return <FormProvider {...form}>
		<form onSubmit={form.handleSubmit(submit)}>
			<CardContent>
				<TextInput source="title" name="title"
				           validate={[required(), regex(NAME_PATTERN, 'Please enter a valid title.')]} fullWidth
				           variant="outlined"/>
				<RichTextInputMod source="content" name="content" variant="standard" isRequired={true}
				                  className={classes.richText}/>
			</CardContent>
			<CardActions style={{padding: '1.25rem', justifyContent: 'flex-end'}}>
				<Button variant="outlined"
				        color="secondary"
				        disabled={loading}
				        onClick={cancel}
				>
					Cancel
				</Button>
				<Button variant="contained"
				        type="submit"
				        color="primary"
				        disabled={loading}>
					{loading && <CircularProgress size={25} thickness={2}/>}
					Save
				</Button>
			</CardActions>

		</form>
	</FormProvider>
}

export const PolicyDocumentEdit = () => {
	const {type} = useParams()
	const title = getDocumentTitleByType(+type)
	const notify = useNotify()
	const redirect = useRedirect()
	const {data, isLoading} = useGet('documents-version/get', {meta: {query: {type}}})
	const [createDocument, {isLoading: updateLoading}] = useCreate();

	const onSubmit = values => {
		const data = {
			type,
			title: values.title,
			content: values.content
		}
		createDocument('documents-version', {data}, {
			onSuccess: () => {
				notify('Successfully updated')
				redirectToList()
			},
			onError: (err) => {
				notify(err.message, {type: 'error'})
			}
		})
	};

	const onCancel = () => {
		redirectToList()
	}

	const redirectToList = () => {
		redirect('/settings/documents')
	}

	return <Card>
		<Title title={title}/>
		<CardHeader component={() => (
			<div style={{padding: '1.25rem'}}>
				<Typography variant="h5">{title}</Typography>
				{data?.document ? <>
					<div>
						<Typography variant="caption">
							LastUpdated: {DateTimeFormatString(data.document?.updatedAt)?.toLocaleString('en-US')}
						</Typography>
					</div>
					<div>
						<Typography variant="caption">
							Version: {data.document.version}
						</Typography>
					</div>
				</> : null
				}
			</div>)}/>
		<DocumentForm values={data?.document} loading={updateLoading || isLoading} onSubmit={onSubmit}
		              onCancel={onCancel}/>
	</Card>
}
