import * as React from 'react';
import {useMemo} from 'react';
import {useRecordContext} from 'react-admin';
import {Link} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import get from "lodash/get";


const UrlField = ({alignment = 'center', ...props}) => {
	const {source, val, target, rel} = props;
	const record = useRecordContext(props);
	const value = record && get(record, source);


	const linkWithProtocol = useMemo(() => {
		if (!value) return null;
		const link = `${val || ''}${value}`;
		return link.includes('http') ? link : `https://${link}`
	}, [val, value]);

	if (!linkWithProtocol) {
		return null;
	}

	return <div style={{display: 'flex', justifyContent: alignment}}>
		<Link href={linkWithProtocol} target={target} rel={rel}>
			<OpenInNewIcon/>
		</Link>
	</div>
};

UrlField.defaultProps = {
	addLabel: true,
};

export default UrlField;
