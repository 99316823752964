import {Card, CardContent, Tab, Tabs, tabsClasses} from "@mui/material";
import {Title} from "react-admin";
import {useNavigate, useParams} from "react-router-dom";
import {useState} from "react";
import {SOCIAL_CHANNEL_TYPES_LIST} from "../../../Utils/constant/Const";
import {MarketValueChannelSettings} from "./marketValueChannel";

const samePageLinkNavigation = (e) => {
	return !(e.defaultPrevented ||
		e.button !== 0 || // ignore everything but left-click
		e.metaKey ||
		e.ctrlKey ||
		e.altKey ||
		e.shiftKey);
}

const LinkTab = (props) => {
	const navigate = useNavigate()
	return (
		<Tab
			component="a"
			onClick={(event) => {
				// Routing libraries handle this, you can remove the onClick handle when using them.
				if (samePageLinkNavigation(event)) {
					event.preventDefault();
				}
				navigate(event.currentTarget.getAttribute('href'));
			}}
			aria-current={props.selected && 'page'}
			{...props}
		/>
	);
}

const parseTabIndex = (paramsId) => {
	const index = parseInt(paramsId)
	return isNaN(index) || index >= SOCIAL_CHANNEL_TYPES_LIST.length ? 0 : index

}
export const MarketValueSettingsList = () => {
	const params = useParams()
	const [tabIndex, setTabIndex] = useState(parseTabIndex(params.id));
	const rootPath = '/settings/market-value'
	const handleChange = (event, newValue) => {
		setTabIndex(newValue)
	}

	return <Card>
		<Title title="Market value"/>
		<Tabs
			value={tabIndex}
			onChange={handleChange}
			aria-label="nav tabs"
			role="navigation"
			variant="scrollable"
			scrollButtons
			sx={{
				[`& .${tabsClasses.scrollButtons}`]: {
					'&.Mui-disabled': {display: 'none'},
				},
			}}
		>
			{
				SOCIAL_CHANNEL_TYPES_LIST.map((channel, index) => (
					<LinkTab key={channel.id} label={channel.name} href={index === 0 ? rootPath : `${rootPath}/${index}`}/>
				))
			}
		</Tabs>
		<CardContent>
			<MarketValueChannelSettings channelType={SOCIAL_CHANNEL_TYPES_LIST[tabIndex]?.id}/>
		</CardContent>
	</Card>
}
