import {useDataProvider, useNotify, useRecordContext} from "react-admin";
import {booleanToConditional, conditionalToBoolean} from "../../Utils/constant/Const";
import {Checkbox} from "@mui/material";
import React, {useState} from "react";
import {makeStyles} from "tss-react/mui";

const adminNotificationStatusStyles = makeStyles()({
    checkbox: {
        display: "flex",
        justifyContent: "center"
    }
})

export const AdminNotificationStatusItem = () => {
    const record = useRecordContext()
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const {classes} = adminNotificationStatusStyles()
    const [checked, setChecked] = useState(conditionalToBoolean(record.receive_notification))

    const onToggleNotification = (value) => {
        const status = booleanToConditional(value)
        dataProvider.update(
            'admins',
            {
                id: record.id,
                data: {status},
                previousData: {...record, receive_notification: status},
                meta: {
                    resourceSuffix: 'set-notify',
                }
            }
        ).then(() => {
            setChecked(value)
            notify('Notifications status updated')
        }).catch((err) => {
            notify(err.message, {type: 'error'})
        })
    }

    return <div className={classes.checkbox}>
        <Checkbox edge="start"
                  checked={checked}
                  tabIndex={-1}
                  onChange={e => {
                      onToggleNotification(e.target.checked);
                  }}/>
    </div>
}
