import {maxValue, minValue, regex, required, TextInput, useNotify, useRefresh, useUpdate} from "react-admin";
import {FormProvider, useForm} from "react-hook-form";
import React from "react";
import Dialog from "@mui/material/Dialog";
import {CircularProgress, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

export const UpdateMultiplierModal = ({multiplier, channelType, onClose}) => {
	const notify = useNotify()
	const refresh = useRefresh()
	const [updateMultiplier, {isLoading}] = useUpdate();


	const form = useForm({
		values: {
			multiplier
		},
		shouldFocusError: true,
		mode: 'onChange',
		reValidateMode: 'onChange'
	})

	const onSubmit = values => {
		const data = {
			type: channelType,
			value: values.multiplier,
		}
		updateMultiplier(
			'social-network-settings/multiplier',
			{data, meta: {resourceSuffix: ''}},
			{
				onSuccess: () => {
					notify('Successfully updated')
					refresh()
					onClose()
				},
				onError: (err) => {
					notify(err.message, {type: 'error'})
				}
			})
	};

	const submit = values => {
		onSubmit(values)
	};

	const validationRules = [
		required(),
		minValue(0.01),
		maxValue(100),
		regex(/^\d+(\.\d{1,2})?$/, 'Please enter a valid number with max 2 symbols after dot')
	]

	return <Dialog open={true} onClose={onClose} disableEnforceFocus={true}>
		<DialogTitle>Update multiplier</DialogTitle>
		<DialogContent sx={{width: '400px'}}>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(submit)}>
					<TextInput name="multiplier"
										 source="multiplier"
										 fullWidth
										 helperText={false}
										 validate={validationRules}/>

					<DialogActions>
						<Button onClick={onClose}>Cancel</Button>
						<Button variant="contained"
										type="submit"
										color="primary"
										disabled={isLoading || !form.formState.isValid}
										sx={{margin: '0 0 8px 8px'}}>
							{isLoading && <CircularProgress size={25} thickness={2}/>}
							Save
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</DialogContent>
	</Dialog>
}
