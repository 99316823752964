import {useNavigate} from "react-router-dom";
import {
	Create,
	PasswordInput,
	regex,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRefresh
} from "react-admin";
import Button from "@mui/material/Button";
import {CREATOR_STATUS_TYPE, CreatorType, Gender} from "../../Utils/constant/Const";
import {EMAIL_PATTERN, NAME_PATTERN, PASSWORD_PATTERN, PHONE_NUMBER_PATTERN} from "../../Utils/regex/regex";
import React from "react";
import {useFormContext} from "react-hook-form";

const UserCreateToolbar = () => {
	const navigate = useNavigate();
	const goBack = () => navigate(-1);

	return (
		<Toolbar>
			<SaveButton/>
			<Button variant="outlined"
							color="secondary"
							style={{marginLeft: '1rem'}}
							onClick={goBack}>
				Cancel
			</Button>
		</Toolbar>
	)
}

const transform = ({phone, ...rest}) => {
	return ({
		...rest,
		phone: phone?.replace('+', '') || '',
	});
}

const PasswordFormFields = () => {
	const {watch} = useFormContext()
	const passwordValue = watch('password')

	return (
		<>
			<PasswordInput label="Password"
										 name="password"
										 defaultValue=""
										 initialValue=""
										 autoComplete="off"
										 source="password"
										 validate={[required(), regex(PASSWORD_PATTERN, 'Min 12 symbols with at least 1 upper, lower, number and one special symbol')]}
										 sx={{
											 maxWidth: '400px',
											 '& .Mui-error': {wordWrap: 'break-word'}
										 }}/>
			<PasswordInput label="Repeat Password"
										 name="passwordRepeat"
										 defaultValue=""
										 initialValue=""
										 autoComplete="off"
										 source="passwordRepeat"
										 validate={[required(), (value) => value && value !== passwordValue ? 'Passwords do not match' : undefined]}
										 sx={{
											 maxWidth: '400px',
											 '& .Mui-error': {wordWrap: 'break-word'}
										 }}/>
		</>
	)

}

const UserCreateForm = () => {

	const creators = CreatorType.filter((type) => type.id !== CREATOR_STATUS_TYPE.deleted)

	return <>
		<SimpleForm toolbar={<UserCreateToolbar/>}
								sx={{'& .MuiFormControl-root ': {minWidth: '400px'}}}>
			<TextInput label="First Name"
								 name="firstName"
								 source="firstName"
								 validate={[required(), regex(NAME_PATTERN, 'Please enter a valid first name.')]}/>
			<TextInput label="Last Name"
								 name="lastName"
								 source="lastName"
								 validate={[required(), regex(NAME_PATTERN, 'Please enter a valid last name.')]}/>
			<TextInput label="Email" name="email" source="email"
								 validate={[required(), regex(EMAIL_PATTERN, 'Invalid email format')]}/>
			<TextInput label="Phone"
								 name="phone"
								 source="phone"
								 placeholder="+12345678900"
								 validate={[regex(PHONE_NUMBER_PATTERN, 'Phone number must be US number: +12345678900')]}/>
			<SelectInput label="Gender"
									 name="gender"
									 source="gender"
									 choices={Gender}
									 validate={required()}
									 sx={{minWidth: 219}}/>
			<SelectInput label="Creator type"
									 name="creatorType"
									 source="creatorType"
									 choices={creators}
									 validate={required()}
									 sx={{minWidth: 219}}/>
			<PasswordFormFields/>
		</SimpleForm>
	</>
}
export const UserCreate = () => {
	const notify = useNotify();
	const refresh = useRefresh();
	const navigate = useNavigate();
	const onSuccess = () => {
		notify('User updated successfully')
		navigate(-1);
		refresh();
	}

	return (
		<Create redirect="list"
						transform={transform}
						mutationMode="pessimistic"
						mutationOptions={{onSuccess}}>
			<UserCreateForm/>
		</Create>
	)
}
