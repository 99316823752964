import {LoadingPage, Title, useNotify, useRefresh, useUpdate} from "react-admin";
import {useNavigate, useParams} from "react-router-dom";
import {TopCreatorForm} from "./form";
import React, {useEffect, useMemo} from "react";
import {useGet} from "../../../Hooks/useGet";

export const TopCreatorEdit = () => {
	const {id} = useParams()
	const {
		data,
		isLoading: isLoadingCreator,
	} = useGet('configs/top-creator', {meta: {query: {'filter[topCreatorId]': id}}})

	const topCreator = useMemo(() => data?.items?.[0] || null, [data])
	const notify = useNotify();
	const refresh = useRefresh();
	const navigate = useNavigate();
	const [create, {isLoading}] = useUpdate()

	useEffect(() => {
		if (!isLoadingCreator && !topCreator) {
			navigate(-1)
		}
	}, [isLoadingCreator, topCreator, navigate]);

	const onSubmit = (values) => {
		create(
			'configs/top-creator',
			{
				data: values,
				meta: {resourceSuffix: ''}
			},
			{
				onSuccess: () => {
					notify('Top creator successfully edited')
					navigate(-1);
					refresh();
				},
				onError: (err) => {
					notify(err.message, {type: 'error'})
				}
			})
	}

	return isLoadingCreator ?
		<LoadingPage/> :
		<>
			<Title
				title="Edit Top Creator"
			/>
			<TopCreatorForm onSubmit={onSubmit} formValues={topCreator} isLoading={isLoading}/>
		</>
}
