import * as React from "react";
import {AppBar} from 'react-admin';
import inMemoryJWT from "../../Auth/inMemoryJwt";
import {makeStyles} from "tss-react/mui";
import {Typography} from "@mui/material";

const useStyles = makeStyles()({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const MyAppBar = props => {
    const user = inMemoryJWT.decodeToken();

    const {classes} = useStyles();
    return (
        <AppBar {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer}/>
            <Typography
                variant="h6"
                color="inherit"
                title={user?.username}
            >{user?.username}
            </Typography>
        </AppBar>
    );
};

export default MyAppBar;
