import {Title, useCreate, useNotify, useRefresh,} from "react-admin";
import {useNavigate} from "react-router-dom";
import React from "react";
import {TopCreatorForm} from "./form";


export const TopCreatorCreate = () => {
	const notify = useNotify();
	const refresh = useRefresh();
	const navigate = useNavigate();
	const [create, {isLoading}] = useCreate()

	const onSubmit = (values) => {
		create(
			'configs/top-creator',
			{
				data: values,
				meta: {resourceSuffix: ''}
			},
			{
				onSuccess: () => {
					notify('Top creator successfully created')
					navigate(-1);
					refresh();
				},
				onError: (err) => {
					notify(err.message, {type: 'error'})
				}
			})
	}

	return <>
		<Title
			title="Top Creator"
			defaultTitle="Top Creator"
		/>
		<TopCreatorForm onSubmit={onSubmit} isLoading={isLoading}/>
	</>
}
