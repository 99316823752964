import {maxValue, minValue, number, regex, required} from "react-admin";
import {DefaultCurrency} from "../../../../Utils/constant/Const";
import {INTEGER_NUMBER_PATTERN} from "../../../../Utils/regex/regex";

const defaultValidation = [required(), number()];

export const referralConfigList = [
	{
		name: "referralBonus",
		label: `Referral bonus, ${DefaultCurrency.badge}`,
		type: "number",
		validate: [...defaultValidation, regex(INTEGER_NUMBER_PATTERN, 'Must be integer number'), minValue(1), maxValue(100000)],
	},
	{
		name: "referralTrialPeriodInDays",
		label: "Referral trial transaction period in days",
		type: "number",
		validate: [...defaultValidation, regex(INTEGER_NUMBER_PATTERN, 'Must be integer number'), minValue(1), maxValue(30)],
	},
];
