import * as React from "react";
import { Box, Card, Divider, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((_) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    height: "auto",
    "& a": { textDecoration: "none", color: "inherit" },
  },
  main: {
    position: "relative",
    height: 58,
    overflow: "inherit",
    padding: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .icon": { color: "#dc2440", zIndex: 2 },
  },
  cardContent: { zIndex: 2 },
  title: {
    fontWeight: "bold",
  },
}));
const DashboardCard = (props) => {
  const { title, subtitle, children } = props;
  const { classes } = useStyles(props);
  return (
    <Card className={classes.card}>
      <div className={classes.main}>
        <Box textAlign="center" className={classes.cardContent}>
          <Typography className={classes.title}> {title} </Typography>
          <Typography variant="h5" component="h2">
            {" "}
            <span>{`${subtitle}`}</span>{" "}
          </Typography>{" "}
        </Box>
      </div>
      {children && <Divider />} {children}
    </Card>
  );
};
export default DashboardCard;
