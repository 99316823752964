import {
  AlignmentButtons,
  ClearButtons,
  DefaultEditorOptions,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
  RichTextInput,
  RichTextInputToolbar,
} from 'ra-input-rich-text';
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableHeader from "@tiptap/extension-table-header";
import TableCell from "@tiptap/extension-table-cell";
import {RichTextInputTableButtons} from "./table/RichTextInputTableButtons";
import clsx from "clsx";
import {FontSize, FontSizeSelect} from "./font-size/RichTextInputFontSize";


const RichTextInputToolbarMod = ({size, ...props}) => {

	return (
		<RichTextInputToolbar {...props}>
			<LevelSelect size={size}/>
			<FontSizeSelect size={size}/>
			<FormatButtons size={size}/>
			<AlignmentButtons size={size}/>
			<ListButtons size={size}/>
			<LinkButtons size={size}/>
			<QuoteButtons size={size}/>
			<ClearButtons size={size}/>
			<RichTextInputTableButtons size={size}/>
		</RichTextInputToolbar>
	);
}


export const RichTextInputMod = ({source, className, isRequired, ...props}) => {
	return <RichTextInput
		editorOptions={EditorOptions}
		toolbar={<RichTextInputToolbarMod size="medium"/>}
		label={false}
		source={source}
		isRequired={isRequired}
		className={clsx('ra-input', `ra-input-${source}`, className)}
		{...props}
	/>
};

const EditorOptions = {
	...DefaultEditorOptions,
	extensions: [
		...DefaultEditorOptions.extensions,
		Table.configure({
			resizable: true,
		}),
		FontSize,
		TableRow,
		TableHeader,
		TableCell,
	],
};
