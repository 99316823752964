import React, {useState} from "react";
import InformationErrorDialog from "../InformationDialog/InformationErrorDialog";
import Dialog from "@mui/material/Dialog";
import {CircularProgress, DialogContent, DialogTitle} from "@mui/material";
import {useCreate} from "react-admin";
import {useGet} from "../../Hooks/useGet";
import {FinancialRepaymentForm} from "./FinancialRepaymentForm";

export const FinancialRepaymentModal = ({holderId, outstandingBalance, onSuccess, onClose}) => {
    const [errorModalState, setErrorModalState] = useState({errors: null, open: false});

    const {data, isLoading: dataLoading} = useGet('financial-accounts/holder', {
        id: holderId,
        meta: {
            resourceSuffix: 'get-repayment-info'
        }
    });

    const [pay, {isLoading}] = useCreate()

    const handleOpenError = (errors) => {
        setErrorModalState(() => ({errors, open: true}));
    };

    const handleCloseError = () => {
        setErrorModalState({errors: null, open: false});
    };

    const onSubmit = (values) => {
        pay(
            `repayments/holder/${holderId}`,
            {data: values},
            {
                onSuccess: () => {
                    onSuccess()
                },
                onError: (err) => {
                    handleOpenError(err);
                }
            }
        )
    }

    const onCancel = () => {
        onClose()
    }

    return <Dialog open={true} onClose={onClose} disableEnforceFocus={true}>
        <DialogTitle>Repay Balance</DialogTitle>
        <DialogContent sx={{width: '400px'}}>
            {dataLoading ?
                <CircularProgress size={50} thickness={2}/> :
                <>

                    <FinancialRepaymentForm onSubmit={onSubmit} data={data}
                                            outstandingBalance={outstandingBalance}
                                            onCancel={onCancel}
                                            isLoading={isLoading}/>
                </>
            }
        </DialogContent>
        {
            errorModalState.errors && <InformationErrorDialog {...errorModalState} onClose={handleCloseError}/>
        }
    </Dialog>
}
