import {maxValue, minValue, number, regex, required} from "react-admin";
import {INTEGER_NUMBER_PATTERN} from "../../../../Utils/regex/regex";

const defaultValidation = [required(), number()]
export const repaymentConfigTypes = [
	{
		name: 'automatedRepaymentDate',
		label: 'Automated repayment date, actual day in the month',
		type: 'number',
		validate: [...defaultValidation, regex(INTEGER_NUMBER_PATTERN, 'Must be integer number'), minValue(1), maxValue(25)]
	},
	{
		name: 'creditSetBeforeDayForDebtor',
		label: 'Credit set date until repayment is required in current month, actual day in the month',
		type: 'number',
		validate: [...defaultValidation, regex(INTEGER_NUMBER_PATTERN, 'Must be integer number'), minValue(10), maxValue(20)]
	},
	{
		name: 'delayForRepeatedAutomatedRepayment',
		label: 'Interval between repeated automated repayment, seconds',
		type: 'number',
		validate: [...defaultValidation, minValue(60), maxValue(60 * 60 * 24)]
	},
	{
		name: 'automatedRepaymentAttemptsMaxCount',
		label: 'Automated repayment, number of attempts',
		type: 'number',
		validate: [...defaultValidation, minValue(1), maxValue(5)]
	},
]
