import {SvgIcon} from "@mui/material";

export const AddColumnBefore = (props) => {
    return (<SvgIcon fontSize="inherit" {...props} width="44" height="44" viewBox="0 0 44 44" fill="none">
        <path d="M2.7749 6.9411C2.7749 6.38882 3.22262 5.9411 3.7749 5.9411H11.9416V11.8887H2.7749V6.9411Z"
              fill="white"/>
        <path d="M12.5363 5.94113H21.703V11.8888H12.5363V5.94113Z" fill="#84BA71"/>
        <path d="M22.297 5.94113H31.4637V11.8888H22.297V5.94113Z" fill="#D2E3F5"/>
        <path d="M32.0584 5.94113H40.2251C40.7774 5.94113 41.2251 6.38885 41.2251 6.94113V11.8888H32.0584V5.94113Z"
              fill="white"/>
        <path d="M2.7749 12.4836H11.9416V18.4312H2.7749V12.4836Z" fill="white"/>
        <path d="M2.7749 19.026H11.9416V24.9737H2.7749V19.026Z" fill="white"/>
        <path d="M2.7749 25.5685H11.9416V31.5161H2.7749V25.5685Z" fill="white"/>
        <path d="M2.7749 32.1109H11.9416V38.0586H2.7749V32.1109Z" fill="white"/>
        <path d="M12.5363 12.4836H21.703V18.4312H12.5363V12.4836Z" fill="#84BA71"/>
        <path d="M12.5363 25.5685H21.703V31.5161H12.5363V25.5685Z" fill="#84BA71"/>
        <path d="M12.5363 19.026H21.703V24.9737H12.5363V19.026Z" fill="#84BA71"/>
        <path d="M12.5363 32.1109H21.703V38.0586H12.5363V32.1109Z" fill="#84BA71"/>
        <path d="M22.297 25.5685H31.4637V31.5161H22.297V25.5685Z" fill="#D2E3F5"/>
        <path d="M32.0584 25.5685H41.2251V31.5161H32.0584V25.5685Z" fill="white"/>
        <path d="M22.297 12.4836H31.4637V18.4312H22.297V12.4836Z" fill="#D2E3F5"/>
        <path d="M32.0584 12.4836H41.2251V18.4312H32.0584V12.4836Z" fill="white"/>
        <path d="M22.297 19.026H31.4637V24.9737H22.297V19.026Z" fill="#D2E3F5"/>
        <path d="M32.0584 19.026H41.2251V24.9737H32.0584V19.026Z" fill="white"/>
        <path d="M22.297 32.1109H31.4637V38.0586H22.297V32.1109Z" fill="#D2E3F5"/>
        <path d="M32.0584 32.1109H41.2251V38.0586H32.0584V32.1109Z" fill="white"/>
        <path d="M11.9416 5.9411H12.5364V38.0585H11.9416V5.9411Z" fill="#C7C7C7"/>
        <path d="M41.225 11.8888L41.225 12.4835L2.77489 12.4835L2.77489 11.8888L41.225 11.8888Z" fill="#C7C7C7"/>
        <path d="M41.225 18.4312L41.225 19.026L2.77489 19.026L2.77489 18.4312L41.225 18.4312Z" fill="#C7C7C7"/>
        <path d="M41.225 24.9737L41.225 25.5684L2.77489 25.5684L2.77489 24.9737L41.225 24.9737Z" fill="#C7C7C7"/>
        <path d="M41.225 31.5161L41.225 32.1109L2.77489 32.1109L2.77489 31.5161L41.225 31.5161Z" fill="#C7C7C7"/>
        <path d="M21.703 5.9411H22.2977V38.0585H21.703V5.9411Z" fill="#C7C7C7"/>
        <path d="M31.4637 5.9411H32.0584V38.0585H31.4637V5.9411Z" fill="#C7C7C7"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M41.8198 6.34667C41.8198 5.79439 41.3721 5.34667 40.8198 5.34667H3.18018C2.62789 5.34667 2.18018 5.79439 2.18018 6.34667V37.6533C2.18018 38.2056 2.62789 38.6533 3.18017 38.6533H40.8198C41.3721 38.6533 41.8198 38.2056 41.8198 37.6533V6.34667ZM2.77491 37.0586C2.77491 37.6108 3.22263 38.0586 3.77491 38.0586H40.2251C40.7773 38.0586 41.2251 37.6108 41.2251 37.0586V6.94144C41.2251 6.38915 40.7773 5.94144 40.2251 5.94144H3.77491C3.22263 5.94144 2.77491 6.38915 2.77491 6.94144V37.0586Z"
              fill="#7D7D7D"/>
        <mask id="mask0_4_315" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="15" y="15" width="14"
              height="14">
            <rect x="16.49" y="27.51" width="11.02" height="11.02" transform="rotate(-90 16.49 27.51)" fill="#D9D9D9"
                  stroke="black"/>
        </mask>
        <g mask="url(#mask0_4_315)">
            <path
                d="M22.5008 23.7034L22.2008 23.7034L22.2008 24.0034L22.2008 25.4524C22.2008 25.6211 22.0047 25.714 21.8742 25.6072L17.6546 22.1548C17.5568 22.0748 17.5568 21.9253 17.6546 21.8452L21.8742 18.3929C22.0047 18.286 22.2008 18.3789 22.2008 18.5476L22.2008 19.9967L22.2008 20.2967L22.5008 20.2967L26.0075 20.2967C26.1179 20.2967 26.2075 20.3862 26.2075 20.4967L26.2075 23.5034C26.2075 23.6138 26.1179 23.7034 26.0075 23.7034L22.5008 23.7034Z"
                fill="#84BA71" stroke="white" strokeWidth="0.6"/>
        </g>
    </SvgIcon>)
}

