import {useNavigate} from "react-router-dom";
import {
	ArrayInput,
	FormDataConsumer,
	FormGroupsProvider,
	ImageField,
	ImageInput,
	minValue,
	regex,
	required,
	SelectInput,
	SimpleFormIterator,
	TextInput,
	Toolbar,
} from "react-admin";
import Button from "@mui/material/Button";
import {Card, CardContent, CircularProgress, Stack} from "@mui/material";
import {styled} from "@mui/material/styles";
import {FormProvider, useForm} from "react-hook-form";
import {FULL_STATS_SOCIAL_CHANNELS, SOCIAL_CHANNEL_TYPES_LIST} from "../../../Utils/constant/Const";
import React, {useEffect} from "react";
import {buildFormData} from "../../../Api/api";
import {FINANCIAL_NUMBER_PATTERN, INTEGER_NUMBER_PATTERN, NAME_PATTERN} from "../../../Utils/regex/regex";

const TopCreatorToolbar = ({isLoading, isValidForm, isSubmitted}) => {
	const navigate = useNavigate();
	const goBack = () => navigate(-1);

	return (
		<Toolbar sx={{gap: '8px'}}>
			<Button variant="outlined"
							color="secondary"
							onClick={goBack}>
				Cancel
			</Button>
			<Button variant="contained"
							type="submit"
							color="primary"
							disabled={isLoading || (!isValidForm && isSubmitted)}>
				{isLoading && <CircularProgress size={25} thickness={2}/>}
				Save
			</Button>
		</Toolbar>
	)
}

const StyledDivContent = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	[`& .MuiFormControl-root`]: {
		minWidth: '400px'
	}
})

const parseInitialFormValues = (formValues) => {
	if (!formValues) return null
	const {
		data,
		primary,
		name,
		marketValue,
		followers,
		id,
		s3Url
	} = formValues;
	const channelsData = data && Array.isArray(data) ?
		[] :
		typeof data === 'object' ?
			Object.keys(data).map(key => ({...data[key], type: parseInt(key)})) :
			[]
	return {
		primary,
		name,
		marketValue,
		followers,
		id,
		image: {
			src: s3Url
		},
		data: channelsData
	}
}
export const TopCreatorForm = ({onSubmit, isLoading, formValues}) => {
	const form = useForm({
		defaultValues: parseInitialFormValues(formValues),
		shouldFocusError: true,
		mode: 'onChange',
		reValidateMode: 'onChange',
	})

	const {watch, formState, trigger, setValue} = form
	const dataWatchValue = watch('data')

	const validatePrimaryChannel = (value) => {
		if (!dataWatchValue || !Array.isArray(dataWatchValue) || dataWatchValue.length === 0) {
			return 'There is no added channel information below';
		}
		if (dataWatchValue.some(({type}) => type === value)) {
			return undefined
		}
		return 'You must select the main channel only from the added channels'
	};

	useEffect(() => {
		const {unsubscribe} = watch((values, {name, type}) => {
			if (name && name.includes('.type') && type === 'change') {
				const index = parseInt(name.split('.')[1])
				setValue(`data[${index}]`, {
					type: values.data[index].type,
					avgViews: undefined,
					avgInteractions: undefined,
					avgEngagementRate: undefined,
					followerGrowth: undefined,
					totalStreams: undefined,
					followers: undefined
				})
				trigger('primary')
			}
		})
		return () => {
			unsubscribe()
		};
	}, [watch, trigger, setValue]);


	const onSubmitForm = ({data, image, ...rest}) => {
		const formData = buildFormData(rest)
		if (Array.isArray(data)) {
			data.forEach((item) => {
				const type = item.type
				if (FULL_STATS_SOCIAL_CHANNELS.includes(type)) {
					formData.append(`data[${type}][avgViews]`, item.avgViews)
					formData.append(`data[${type}][avgInteractions]`, item.avgInteractions)
					formData.append(`data[${type}][avgEngagementRate]`, item.avgEngagementRate)
					formData.append(`data[${type}][followerGrowth]`, item.followerGrowth)
				} else {
					formData.append(`data[${type}][totalStreams]`, item.totalStreams)
					formData.append(`data[${type}][followers]`, item.followers)
				}
			})
		}
		if (image.rawFile) {
			formData.append('image', image.rawFile)
		}
		onSubmit(formData)
	}

	const validateFileSize = (value) => {
		if (!value) {
			return undefined
		}
		if (value.rawFile?.size > 1024 * 1024 * 2) {
			return 'File size must be less than 2MB'
		}
		return undefined
	}

	return <div
		style={{display: 'flex'}}
	>
		<Card sx={{flex: '1 1 auto'}}>
			<FormProvider {...form} >
				<FormGroupsProvider>
					<form onSubmit={form.handleSubmit(onSubmitForm)}>
						<CardContent>
							<Stack sx={{alignItems: 'flex-start'}}>
								<StyledDivContent>
									<TextInput label="Name"
														 name="name"
														 source="name"
														 validate={[required(), regex(NAME_PATTERN, 'Please enter a valid name.')]}/>
									<TextInput label="Followers" name="followers" source="followers"
														 validate={[required(), minValue(0), regex(INTEGER_NUMBER_PATTERN, 'Value must be an integer number')]}/>
									<TextInput label="Market value" name="marketValue" source="marketValue"
														 validate={[required(), minValue(0), regex(FINANCIAL_NUMBER_PATTERN, 'Value must be in financial number format with dot separator')]}/>
									<ImageInput source="image" name="image" label="Image" validate={[required(), validateFileSize]}
															accept="image/png,image/jpg,image/jpeg,image/webp">
										<ImageField source="src" title="title"/>
									</ImageInput>
								</StyledDivContent>
								<SelectInput label="Primary channel"
														 name="primary"
														 source="primary"
														 choices={SOCIAL_CHANNEL_TYPES_LIST}
														 validate={[required(), validatePrimaryChannel]}
														 sx={{minWidth: 219}}/>
								<ArrayInput source="data" name="data" label="Channels">
									<SimpleFormIterator inline
																			disableReordering
																			disableAdd={Array.isArray(dataWatchValue) && dataWatchValue.length >= SOCIAL_CHANNEL_TYPES_LIST.length}>
										<FormDataConsumer>
											{
												({formData, scopedFormData, getSource, ...rest}) => {
													const choices = SOCIAL_CHANNEL_TYPES_LIST.filter(({id}) => !formData.data.some(({type}) => type === id) || scopedFormData?.type === id)
													return <SelectInput label="Type"
																							source={getSource('type')}
																							choices={choices}
																							validate={required()}
																							sx={{minWidth: 219}}
																							{...rest}/>
												}
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{
												({formData, scopedFormData, getSource, ...rest}) => {
													const type = scopedFormData?.type
													if (!getSource || !type) {
														return null
													}
													return FULL_STATS_SOCIAL_CHANNELS.includes(type) ? <>
															<TextInput label="Average views"
																				 key={`${type}-${getSource('avgViews')}`}
																				 name={getSource('avgViews')}
																				 source={getSource('avgViews')}
																				 validate={[required(), minValue(0), regex(FINANCIAL_NUMBER_PATTERN, 'Value must be a float format')]}
																				 {...rest}/>
															<TextInput label="Average Interactions"
																				 source={getSource('avgInteractions')}
																				 name={getSource('avgInteractions')}
																				 key={`${type}-${getSource('avgInteractions')}`}
																				 validate={[required(), minValue(0), regex(FINANCIAL_NUMBER_PATTERN, 'Value must be a float format')]}
																				 {...rest}/>
															<TextInput label="Average Engagement Rate"
																				 source={getSource('avgEngagementRate')}
																				 name={getSource('avgEngagementRate')}
																				 key={`${type}-${getSource('avgEngagementRate')}`}
																				 validate={[required(), minValue(0), regex(FINANCIAL_NUMBER_PATTERN, 'Value must be a float format')]}
																				 {...rest}/>
															<TextInput label="Follower Growth"
																				 source={getSource('followerGrowth')}
																				 name={getSource('followerGrowth')}
																				 key={`${type}-${getSource('followerGrowth')}`}
																				 validate={[required(), minValue(0), regex(FINANCIAL_NUMBER_PATTERN, 'Value must be a float format')]}
																				 {...rest}/>

														</> :
														<>
															<TextInput label="Total streams"
																				 source={getSource('totalStreams')}
																				 name={getSource('totalStreams')}
																				 key={`${type}-${getSource('totalStreams')}`}
																				 validate={[required(), minValue(0), regex(INTEGER_NUMBER_PATTERN, 'Value must be an integer number')]}
																				 {...rest}/>
															<TextInput label="Followers"
																				 source={getSource('followers')}
																				 name={getSource('followers')}
																				 key={`${type}-${getSource('followers')}`}
																				 validate={[required(), minValue(0), regex(INTEGER_NUMBER_PATTERN, 'Value must be an integer number')]}
																				 {...rest}/>
														</>
												}
											}
										</FormDataConsumer>
									</SimpleFormIterator>
								</ArrayInput>
							</Stack>
							<TopCreatorToolbar isLoading={isLoading} isValidForm={formState.isValid}
																 isSubmitted={formState.isSubmitted}/>
						</CardContent>
					</form>
				</FormGroupsProvider>
			</FormProvider>
		</Card>
	</div>
}
