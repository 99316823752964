import React from 'react';
import DashboardCard from '../../Component/Cards/DashboardCard';
import {List, ListItem, ListItemText} from '@mui/material';
import {getFinancialNumberWithCurrency, reformatNumber,} from '../../Utils/helpers/reformat-number';
import * as PropTypes from 'prop-types';
import {makeStyles} from 'tss-react/mui';
import {Link} from 'react-router-dom';
import {stringify} from 'query-string';
import {USER_HAS_FILTER_TYPE, USER_STATUS_TYPE} from '../../Utils/constant/Const';

const useStyles = makeStyles()({
	listItem: {
		textAlign: 'left',
	},
	link: {
		cursor: 'pointer',
		textDecoration: 'none',
		color: 'inherit',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: '20px',
		padding: '30px 0',
	},
});

function DashboardsStatistic({data}) {
	const {classes} = useStyles();
	return (
		<div className={classes.container}>
			<DashboardCard title="Users" subtitle="">
				<List>
					<ListItem className={classes.listItem}>
						<ListItemText primary={`Total No. of Creators: ${reformatNumber(
							data?.usersNumber
						)}`}
						/>
					</ListItem>
					<ListItem className={`${classes.listItem} ${classes.link}`}
										component={Link}
										to={{
											pathname: '/users',
											search: stringify({
												filter: JSON.stringify({
													bankAccount: USER_HAS_FILTER_TYPE.with,
													status: USER_STATUS_TYPE.active
												})
											})
										}}>
						<ListItemText
							primary={`Total No. of Creators With Connected Bank Accounts: ${reformatNumber(
								data?.bankUserNumber
							)}`}
						/>
					</ListItem>
				</List>
			</DashboardCard>
			<DashboardCard title="Bank accounts" subtitle="">
				<List>
					<ListItem className={classes.listItem}>
						<ListItemText
							primary={`Total Bank Accounts Connected: ${reformatNumber(
								data?.accountsNumber
							)}`}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText
							primary={`Total Bank Account Balances (All-Time): ${getFinancialNumberWithCurrency(
								data?.sumBalance
							)}`}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText
							primary={`Total Discovered Income For All Creators (All-Time): ${getFinancialNumberWithCurrency(
								data?.sumIncome
							)}`}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText
							primary={`Average No. of Bank Accounts Per Creator: ${reformatNumber(
								data?.avgBankUser
							)}`}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText
							primary={`Average Bank Account Balance : ${getFinancialNumberWithCurrency(
								data?.averageBalance
							)}`}
						/>
					</ListItem>
				</List>
			</DashboardCard>
			<DashboardCard title="Credits" subtitle="">
				<List>
					<ListItem className={classes.listItem}>
						<ListItemText
							primary={`Total Credits Set For All Creators (All-Time): ${getFinancialNumberWithCurrency(
								data?.sumCredit
							)}`}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText
							primary={`Total Outstanding Balance For All Creators (All-Time): ${getFinancialNumberWithCurrency(
								data?.totalOutstandingBalance
							)}`}
						/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText
							primary={`Average Outstanding Balance Per Creator: ${getFinancialNumberWithCurrency(
								data?.averageOutstandingBalance
							)}`}
						/>
					</ListItem>
				</List>
			</DashboardCard>
			<DashboardCard title="Cards" subtitle="">
				<List>
					<ListItem className={classes.listItem}>
						<ListItemText
							primary={`Total Transactions By All Creators (All-Time): ${getFinancialNumberWithCurrency(
								data?.sumTransaction
							)}`}
						/>
					</ListItem>
				</List>
			</DashboardCard>
		</div>
	);
}

DashboardsStatistic.propTypes = {
	data: PropTypes.shape(),
};

export default DashboardsStatistic;
