import {styled} from "@mui/material/styles";
import {useTranslate} from "ra-core";
import Inbox from "@mui/icons-material/Inbox";
import {Typography} from "@mui/material";
import {CreateButton} from "react-admin";
import * as React from "react";

const PREFIX = 'RaEmpty';

export const EmptyClasses = {
	message: `${PREFIX}-message`,
	icon: `${PREFIX}-icon`,
	toolbar: `${PREFIX}-toolbar`,
};

export const EmptyMod = ({route, withCreate, label, className}) => {

	const translate = useTranslate();

	const inviteMessage = translate('ra.page.invite');

	return (
		<Root className={className}>
			<div className={EmptyClasses.message}>
				<Inbox className={EmptyClasses.icon}/>
				<Typography variant="h4" paragraph>
					{label}
				</Typography>
				{withCreate && (
					<Typography variant="body1">
						{translate(`resources.${route}.invite`, {
							_: inviteMessage,
						})}
					</Typography>
				)}
			</div>
			{withCreate && (
				<div className={EmptyClasses.toolbar}>
					<CreateButton variant="contained" resource={route}/>
				</div>
			)}
		</Root>
	);
};

const Root = styled('span', {
	name: PREFIX,
	overridesResolver: (props, styles) => styles.root,
})(({theme}) => ({
	flex: 1,
	[`& .${EmptyClasses.message}`]: {
		textAlign: 'center',
		opacity: theme.palette.mode === 'light' ? 0.5 : 0.8,
		margin: '0 1em',
		color:
			theme.palette.mode === 'light'
				? 'inherit'
				: theme.palette.text.primary,
	},

	[`& .${EmptyClasses.icon}`]: {
		width: '9em',
		height: '9em',
	},

	[`& .${EmptyClasses.toolbar}`]: {
		textAlign: 'center',
		marginTop: '2em',
	},
}));
