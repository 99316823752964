import {FormProvider, useForm} from "react-hook-form";
import {FileField, FileInput, required, useNotify, useRefresh, useUpdate} from "react-admin";
import Dialog from "@mui/material/Dialog";
import {CircularProgress, DialogContent, DialogTitle} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React from "react";

export const UploadChannelStatisticTableModal = ({onClose, channelType}) => {
	const form = useForm({
		values: {
			file: ''
		},
		mode: 'onChange',
		reValidateMode: 'onChange'
	})
	const notify = useNotify()
	const refresh = useRefresh()
	const [updateChannelConfig, {isLoading}] = useUpdate();

	const onSubmit = values => {
		const data = {
			type: channelType,
			file: values.file,
		}
		updateChannelConfig(
			'social-network-settings/upload',
			{data, meta: {resourceSuffix: ''}},
			{
				onSuccess: () => {
					notify('Successfully updated')
					refresh()
					onClose()
				},
				onError: (err) => {
					notify(err.message, {type: 'error'})
					form.setError('file', {type: 'custom', message: err.message})
				}
			})
	};

	const submit = values => {
		onSubmit(values)
	};

	return <Dialog open={true} onClose={onClose} disableEnforceFocus={true}>
		<DialogTitle>Update multiplier</DialogTitle>
		<DialogContent sx={{width: '400px'}}>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(submit)}>
					<FileInput source="file" name="file"
										 helperText={form.getFieldState('file').error?.message}
										 validate={required()}
										 accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}>
						<FileField source="src" title="title"/>
					</FileInput>
					<DialogActions>
						<Button onClick={onClose}>Cancel</Button>
						<Button variant="contained"
										type="submit"
										color="primary"
										disabled={isLoading || !form.formState.isValid}
										sx={{margin: '0 0 8px 8px'}}>
							{isLoading && <CircularProgress size={25} thickness={2}/>}
							Save
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</DialogContent>
	</Dialog>
}
