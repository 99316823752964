import React from 'react';
import {
	Button,
	CreateButton,
	Datagrid,
	EditButton,
	FilterButton,
	FunctionField,
	LoadingPage,
	SelectField,
	SelectInput,
	ShowButton,
	TextField,
	TextInput,
	TopToolbar,
	useDataProvider,
	useListContext,
	useNotify,
	usePermissions,
	useRecordContext,
	useRefresh,
	useUpdate,
} from 'react-admin';
import ListMod from '../../Component/Redesign/ListMod';
import {
	USER_STATUS_TYPE,
	UserClaimFilterCollection,
	UserHasFilterCollection,
	UserStatus
} from '../../Utils/constant/Const';
import {Block, Check, Email} from '@mui/icons-material';
import DateFieldMod from '../../Component/Redesign/DateFieldMod';
import {hasAdminRole} from '../../Utils/helpers/permissions';
import DownloadIcon from '@mui/icons-material/GetApp';

const UserStatusActionsButton = () => {
	const record = useRecordContext();
	const refresh = useRefresh();

	const [block] = useUpdate(
		'users/block',
		{id: record?.id || '', data: {}},
		{
			onSuccess: () => {
				refresh()
			},
		}
	);
	const [unblock] = useUpdate(
		'users/unblock',
		{id: record?.id || '', data: {}},
		{
			onSuccess: () => {
				refresh()
			},
		}
	);
	const blockUser = () => {
		block()
	}
	const unblockUser = () => {
		unblock()
	}
	if (!record || !record.id) {
		return null;
	}

	return (record?.status === 1) ?
		<Button children={<Check/>} label="Unblock" onClick={unblockUser}/> :
		<Button children={<Block/>} label="Block" onClick={blockUser}/>

};

const getUserFilters = (permissions) => {
	const commonFilters = [
		<SelectInput label="STATUS" emptyText="ALL" name="status" source="status"
								 choices={UserStatus}
								 alwaysOn/>,
		<SelectInput label="Membership"
								 emptyText="All"
								 name="membership"
								 source="membership"
								 choices={UserHasFilterCollection}
								 alwaysOn/>,
		<SelectInput label="Connected bank Account"
								 emptyText="All"
								 name="bankAccount"
								 source="bankAccount"
								 choices={UserHasFilterCollection}
								 alwaysOn/>,
		<SelectInput label="Social channels"
								 emptyText="All"
								 name="socialChannel"
								 source="socialChannel"
								 choices={UserHasFilterCollection}
								 alwaysOn/>,
		<SelectInput label="Сreated by admin"
								 emptyText="All"
								 name="loginClaimed"
								 source="loginClaimed"
								 sx={{minWidth: 180}}
								 choices={UserClaimFilterCollection}
								 alwaysOn/>
	]
	const adminFilters = [
		<TextInput label="Search" name="search" source="search" alwaysOn/>
	]
	return hasAdminRole(permissions) ? [...commonFilters, ...adminFilters] : [...commonFilters]
}

const SendEmail = () => {
	const record = useRecordContext()
	const [send, {isLoading}] = useUpdate()
	const notify = useNotify()

	const isUnverified = record.status === USER_STATUS_TYPE.unverified
	const isUnclaimed = record.loginClaimed === false

	const sendUnverifiedVerification = () => {
		send(
			'users/resend',
			{id: record.id, meta: {resourceSuffix: ''}},
			{
				onSuccess: () => {
					notify('Email sent')
				},
				onError: (err) => {
					notify(err.message, {type: 'error'})
				}
			}
		)
	}

	const sendClaimVerification = () => {
		send(
			'users/unclaimed',
			{id: record.id, meta: {resourceSuffix: ''}},
			{
				onSuccess: () => {
					notify('Email sent')
				},
				onError: (err) => {
					notify(err.message, {type: 'error'})
				}
			}
		)
	}

	if (!isUnverified && !isUnclaimed) return null

	if (isUnverified) {
		return <Button label="Send Verification" disabled={isLoading} onClick={sendUnverifiedVerification}>
			<Email/>
		</Button>
	}
	return <Button label="Send Invitation" disabled={isLoading} onClick={sendClaimVerification}>
		<Email/>
	</Button>
}

const CustomActions = () => {
	const {permissions} = usePermissions();
	const {sort, filterValues, total} = useListContext();
	const dataProvider = useDataProvider()
	const notify = useNotify()
	
	const onDownloadExcel = () => {
		dataProvider.uploadFile('users/export/xls', {
			query: {
				filter: filterValues,
				sort: {column: sort.field, direction: sort.order}
			}
		})
			.then(() => {
				notify('Exported successfully', {type: 'info'})
			})
			.catch(() => {
				notify('Export failed', {type: 'error'})
			})
	}
	return (
		<TopToolbar>
			<FilterButton/>
			<CreateButton/>
			{
				hasAdminRole(permissions) &&
				<Button
					label={'Export'}
					startIcon={<DownloadIcon/>}
					disabled={total === 0}
					onClick={onDownloadExcel}
				/>
			}
		</TopToolbar>
	);
}

export const UserList = ({...props}) => {

	const {permissions, isLoading} = usePermissions();
	return !isLoading ?
		<ListMod {...props}
						 actions={<CustomActions/>}
						 filters={getUserFilters(permissions)} filterDefaultValues={{status: UserStatus[0].id}}
						 title="User list"
						 hasCreate
						 basePath="/users">
			<Datagrid bulkActionButtons={false}>
				<TextField label={'ID'} source="id" sortBy="id"/>
				{
					hasAdminRole(permissions) && [
						<FunctionField key="avatar" render={record => {
							if (!record.s3Url) return null
							return <img src={record.s3Url} alt={record.first_name}
													style={{width: 30, height: 30, borderRadius: '50%'}}/>
						}}/>,
						<TextField key="first_name" label={'FIRST NAME'} source="first_name"/>,
						<TextField key="last_name" label={'LAST NAME'} source="last_name"/>,
						<TextField key="email" label={'EMAIL'} source="email"/>,
					]
				}

				<SelectField label={'STATUS'} source="status" choices={UserStatus}/>
				<FunctionField label="MEMBERSHIP" render={record => record.activeSubscription ? 'YES' : 'NO'}/>
				<FunctionField label="MARKET VALUE" render={record => record.hasMarketValue ? 'YES' : 'NO'}/>
				<FunctionField label="CREATED BY"
											 render={record => record.loginClaimed ? 'CLAIMED' : record.loginClaimed === false ? 'UNCLAIMED' : '-'}/>
				<DateFieldMod showTime label="CREATED AT" source="created_at"/>
				{
					hasAdminRole(permissions) && <SendEmail/>
				}
				<ShowButton/>
				{
					hasAdminRole(permissions) &&
					<FunctionField label={false}
												 render={record => record.status !== USER_STATUS_TYPE.deleted ? <EditButton/> : null}/>
				}
				{
					hasAdminRole(permissions) && <UserStatusActionsButton/>
				}
			</Datagrid>
		</ListMod> :
		<LoadingPage/>
};
