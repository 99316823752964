import {
	maxValue,
	minValue,
	regex,
	required,
	SelectInput,
	TextInput, useCreate,
	useNotify,
	useRefresh,
} from "react-admin";
import {FormProvider, useForm} from "react-hook-form";
import React, {useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import {CircularProgress, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {
	ADD_SOCIAL_CHANNEL_TYPES_LIST,
	SOCIAL_CHANNEL_TYPES, SPOTIFY_ARTIST_LINK_PATTERN,
	TIKTOK_LINK_PATTERN,
	YOUTUBE_MUSIC_ARTIST_LINK_PATTERN
} from "../../Utils/constant/Const";

export const AddSocialChannelModal = ({userId, onClose}) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const [addSocialChannel, {isLoading}] = useCreate();
	const form = useForm({
		values: {
			type: null,
			link: '',
			user: userId
		},
		shouldFocusError: true,
		mode: "onChange",
		reValidateMode: "onChange",
	});

	const selectedType = form.watch("type");

	useEffect(() => {
		form.clearErrors("link");
		form.resetField("link");
		form.register("link", {
			validate: getValidationRules(selectedType),
		});
	}, [selectedType, form]);

	const addSocialChannelLink = () => {
		if (isLoading) {
			return;
		}
		const {type, link, user} = form.getValues()
		addSocialChannel(`social-networks`, {
			data: {
				type,
				link: link.split(/[?#]+/g)[0],
				user
			},
		},
			{
				onSuccess: () => {
					refresh();
					notify("Channel has been added!");
					onClose();
				},
				onError: (error) => {
					notify(error.message, {type: "error"});
					form.setError("link", {type: "custom", message: error.message});
				},
			}
		).then()
	}

	const submit = () => {
		addSocialChannelLink();
	};

	const getValidationRules = (type) => {
		switch (type) {
			case SOCIAL_CHANNEL_TYPES.youtubeMusic:
				return regex(
					YOUTUBE_MUSIC_ARTIST_LINK_PATTERN,
					"Please enter a valid youtube music artist link"
				)
			case SOCIAL_CHANNEL_TYPES.tikTok:
				return regex(
					TIKTOK_LINK_PATTERN,
					"Please enter a valid TikTok link"
				)
			case SOCIAL_CHANNEL_TYPES.spotify:
				return regex(
					SPOTIFY_ARTIST_LINK_PATTERN,
					"Please enter a valid spotify artist link"
				)
			default:
				return 'Select Social Channel Type!'
		}
	}

	const validationRules = [
		required(),
		minValue(0),
		maxValue(1000000),
		getValidationRules(selectedType),
	];

	return (
		<>
			<Dialog open={true} onClose={onClose} disableEnforceFocus={true}>
				<DialogTitle>Update user channel adjustment</DialogTitle>
				<DialogContent sx={{width: "400px"}}>
					<FormProvider {...form}>
						<form onSubmit={form.handleSubmit(submit)}>
							<SelectInput label="Select Social Type" name="type" source="type" choices={ADD_SOCIAL_CHANNEL_TYPES_LIST} validate={required()}
										 fullWidth sx={{minWidth: 219}}/>
							<TextInput
								name="link"
								source="link"
								fullWidth
								helperText={false}
								validate={validationRules}
							/>

							<DialogActions sx={{gap: '8px'}}>
								<Button onClick={onClose}>Cancel</Button>
								<Button
									variant="contained"
									type="submit"
									color="primary"
									disabled={isLoading || !form.formState.isValid}
								>
									{isLoading && <CircularProgress size={25} thickness={2}/>}
									Save
								</Button>
							</DialogActions>
						</form>
					</FormProvider>
				</DialogContent>
			</Dialog>
		</>
	);
};
