import { Datagrid, FunctionField, ReferenceManyField, SelectField, TextField } from 'react-admin';
import PaginationMod from '../../Component/Redesign/PaginationMod';
import DateFieldMod from '../../Component/Redesign/DateFieldMod';
import { REPAYMENT_INIT_TYPE } from '../../Utils/constant/Const';
import { getFinancialNumberWithCurrency } from '../../Utils/helpers/reformat-number';

export const RepaymentsReferenceList = ({ source }) => {

    return (
        <ReferenceManyField source={ source }
                            reference="repayments"
                            target="financialAccountId"
                            label={ false }
                            pagination={ <PaginationMod/> }
                            sort={ { field: 'updatedAt', order: 'DESC' } }>
            <Datagrid bulkActionButtons={ false }>
                <TextField label="Id" source="id"/>
                <FunctionField label="Amount" sortBy="amount"
                               render={ record => `${ getFinancialNumberWithCurrency(record.amount) }` }/>
                <TextField label="Status" source="status"/>
                <SelectField label="Initiated by" source="type" choices={ REPAYMENT_INIT_TYPE }/>
                <FunctionField label="Available credit" sortBy="availableCredit"
                               render={ record => `${ getFinancialNumberWithCurrency(record.availableCredit || 0) }` }/>
                <FunctionField label="Credit limit" sortBy="creditLimit"
                               render={ record => `${ getFinancialNumberWithCurrency(record.creditLimit || 0) }` }/>
                <DateFieldMod label="Date Credit Set" source="dateCreditSet"/>
                <DateFieldMod showTime label="Created at" source="createdAt"/>
                <DateFieldMod showTime label="Updated at" source="updatedAt"/>
            </Datagrid>
        </ReferenceManyField>
    )
}
