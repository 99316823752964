import decodeJwt from "jwt-decode";
import {stringify} from 'qs';
import {ENV} from '../Const/ProjectEnv';

const apiUrl = ENV.HOST_BACKEND + '/admin';

const inMemoryJWTManager = () => {
    let logoutEventName = 'ra-logout';
    let refreshEndpoint = apiUrl + '/token/refresh';
    let isRefreshing = null;

    // This listener allows to disconnect another session of react-admin started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
        }
    });

    const waitForTokenRefresh = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }

        return isRefreshing.then((token) => {
            isRefreshing = null;
            return token;
        });
    }

    const getRefreshedToken = () => {
        if (isRefreshing) {
            return isRefreshing
        }
        const request = new Request(refreshEndpoint, {
            method: 'POST',
            body: stringify({
                refresh_token: localStorage.getItem('refreshToken'),
            }),
            headers: new Headers({'Content-Type': 'application/x-www-form-urlencoded'})
        });
        isRefreshing = fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    ereaseToken();
                    global.console.log('Token renewal failure');
                    return {data: {token: null}};
                }

                return response.json();
            })
            .then(({data}) => {
                if (data.token) {
                    setToken(data.token, data.refresh_token || data.refreshToken);
                    isRefreshing = null
                    return data;
                }

                ereaseToken();
                isRefreshing = null
                return null;
            });

        return isRefreshing;
    }

    const decodeToken = () => {
        const token = getToken();
        return token ? decodeJwt(token) : null;
    }

    const hasPermissions = () => {
        const decodedToken = decodeToken();
        if (!decodedToken) {
            return false;
        }

        //return HasPermissions([CONTENT, ADMISSION, PARTNERSHIP], decodedToken.roles[0]);
        return true;
    }

    const getRoles = () => {
        const decodedToken = decodeToken();
        return decodedToken ? decodedToken.roles : false;
    }

    const getId = () => {
        const decodedToken = decodeToken();
        return decodedToken ? decodedToken.id : false;
    }

    const getName = () => {
        const decodedToken = decodeToken();
        return decodedToken ? decodedToken.name : false;
    }

    const getEmail = () => {
        const decodedToken = decodeToken();
        return decodedToken ? decodedToken.email : false;
    }

    const isExpired = () => {
        const decodedToken = decodeToken();
        const currentTime = new Date().getTime() / 1000;
        return currentTime > decodedToken.exp;
    }

    const getToken = () => localStorage.getItem('token');

    const setToken = (token, refreshToken) => {
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        return true;
    };

    const ereaseToken = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.setItem(logoutEventName, Date.now());
        return true;
    }

    const setLogoutEventName = name => logoutEventName = name;

    return {
        ereaseToken,
        getToken,
        decodeToken,
        getRoles,
        getId,
        getName,
        getEmail,
        setLogoutEventName,
        setToken,
        waitForTokenRefresh,
        hasPermissions,
        getRefreshedToken,
        isExpired
    }
};

export default inMemoryJWTManager();
