import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useDataProvider, useNotify, usePermissions, useRecordContext, useRefresh} from "react-admin";
import get from 'lodash/get';
import CustomErrorFormControlView from "./CustomErrorFormControlView";
import CustomErrorFormModal from "./CustomErrorFormModal";
import {makeStyles} from "tss-react/mui";
import {hasAdminRole} from "../../Utils/helpers/permissions";

const useStyles = makeStyles()(
    theme => ({
        label: {
            position: 'relative',
            cursor: 'inherit'
        },
        value: {
            fontFamily: theme.typography.fontFamily,
            color: 'currentColor',
            padding: `0 0 ${theme.spacing(1) / 2}px`,
            border: 0,
            boxSizing: 'content-box',
            verticalAlign: 'middle',
            background: 'none',
            margin: 0, // Reset for Safari
            display: 'block',
            width: '100%',
        }
    }),
    {name: 'RaLabeled'}
);

const getErrorPayload = (type, field, error) => {
    const fieldKey = `${type}[field]`;
    const errorKey = `${type}[error]`;
    return {
        [fieldKey]: field,
        [errorKey]: error
    }
}

const getRequestError = (record, type, field) => {
    const errors = get(record, 'requestErrors') || []
    return errors.find(item => item.type === type && item.field === field) || null
}

const getHolderStatus = (record) => {
    return get(record, 'holder.status')
}

const CustomErrorFormField = ({customLabel, source = '', children}) => {

    const [type, field] = source.split('.')
    if (!type || !field) {
        throw new Error('type and field props required')
    }
    const {classes} = useStyles()
    const record = useRecordContext()
    const requestStatus = getHolderStatus(record)
    const [isOpen, setOpen] = useState(false)
    const [error, setError] = useState(null)
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const {permissions} = usePermissions();

    useEffect(() => {
        const error = getRequestError(record, type, field)
        setError(error)
    }, [record, type, field])

    const handleClose = () => {
        setOpen(false)
    }

    const handleUpdateError = useCallback((data) => {
        handleClose()
        dataProvider.update(
            'holders/request-error',
            {
                id: record?.id,
                data: getErrorPayload(type, field, data),
            }
        ).then(() => {
            refresh()
            notify('Error updated')
        })
    }, [dataProvider, type, field, notify, refresh, record])

    const handleDeleteError = useCallback((id) => {
        handleClose()
        dataProvider.delete(
            'holders/request-error',
            {
                id,
            }
        ).then(() => {
            refresh()
            notify('Error deleted')
        })
    }, [dataProvider, notify, refresh])

    const handleOpen = () => {
        setOpen(true)
    }

    return useMemo(() => record ?
            (<div>
                <CustomErrorFormControlView customLabel={customLabel} classes={classes} showError={requestStatus === 5}
                                            handleOpen={handleOpen}
                                            editable={hasAdminRole(permissions)}
                                            error={error} children={children}/>

                {isOpen &&
                    <CustomErrorFormModal open={isOpen} error={error} onClose={handleClose} onUpdate={handleUpdateError}
                                          onDelete={handleDeleteError}
                                          label={customLabel}/>
                }
            </div>) :
            null,
        [customLabel, record, classes, isOpen, error, handleUpdateError, children, handleDeleteError, requestStatus, permissions]
    );
};

export default CustomErrorFormField
