import {styled} from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import {tableCellClasses} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import {DefaultCurrency} from "../../../Utils/constant/Const";

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: 'transparent',
	},
}));


const StyledTableRow = styled(TableRow)(({theme}) => ({
	'&:nth-of-type(even) td, &:nth-of-type(even) th': {
		backgroundColor: 'rgb(235, 235, 235)',
	},
	'&:nth-of-type(odd) td, &:nth-of-type(odd) th': {
		backgroundColor: 'white',
	},
	'& td:nth-of-type(even):not(:last-of-type)': {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	'&:first-of-type th:nth-of-type(odd):not(:last-of-type)': {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	'&:nth-of-type(2) th:nth-of-type(even):not(:last-of-type)': {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	'&:last-of-type td': {
		borderBottom: 'none',
	}
}));

const StyledTableHead = styled(TableHead)(({theme}) => ({
	'& tr:nth-of-type(1)': {
		position: 'sticky',
		top: 0,
		zIndex: 3,
	},
	'& th': {
		borderBottom: 0
	}
}));

export const MusicSocialStatisticTable = ({table}) => {

	return <Table stickyHeader sx={{minWidth: 400, width: '100%'}} aria-label="simple table">
		<StyledTableHead>
			<StyledTableRow>
				<TableCell rowSpan={2} colSpan={2} align="center">
					Number of Streams
				</TableCell>
				<TableCell align="center">
					Lowest value {DefaultCurrency.badge}
				</TableCell>
				<TableCell align="center">
					Highest value {DefaultCurrency.badge}
				</TableCell>
			</StyledTableRow>
		</StyledTableHead>
		<TableBody>
			{table.map((row, index) => row ? (
					<StyledTableRow
						key={`body-row-${index}`}
					>
						<StyledTableCell align="center">{row.min}</StyledTableCell>
						<StyledTableCell align="center">{row.max}</StyledTableCell>
						<StyledTableCell align="center">{row.low}</StyledTableCell>
						<StyledTableCell align="center">{row.high}</StyledTableCell>
					</StyledTableRow>
				) : null
			)}
		</TableBody>
	</Table>

}
