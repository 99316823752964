import React from "react";
import {List} from "react-admin";
import PaginationMod from "./PaginationMod";

export const ListMod = ({children, ...props}) => {
	const sort = props.sort ? props.sort : {field: "id", order: "DESC"};

	return (
		<List
			{...props}
			pagination={
				props.pagination !== undefined ? props.pagination : <PaginationMod/>
			}
			sort={sort}
			exporter={false}
		>
			{children}
		</List>
	);
};

export default ListMod;
