import { FunctionField, required, SelectInput, useNotify, useRecordContext, useRefresh, useUpdate } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { ROLE_SUPER_ADMIN, ROLES_CHOICES } from '../../Utils/constant/Permissions';
import React from 'react';

export const AdminRoleEdit = () => {
    const record = useRecordContext()
    const { id, role } = record
    const notify = useNotify()
    const refresh = useRefresh()

    const form = useForm({
        defaultValues: {
            role,
        }
    })

    const [ update, { isLoading } ] = useUpdate('admins')

    const handleChange = (e) => {
        if (isLoading) {
            return
        }
        const value = e.target.value
        update(
            `admins`,
            { id, data: { role: value }, meta: { resourceSuffix: 'update-role' } },
            {
                onSuccess: () => {
                    notify('Role updated')
                },
                onError: (err) => {
                    notify(err.message, { type: 'error' })
                    refresh()
                }
            }
        )
    }


    return role !== ROLE_SUPER_ADMIN ?
        <FormProvider { ...form }>
            <SelectInput name="role"
                         source="role"
                         helperText={ false }
                         validate={ required() }
                         choices={ ROLES_CHOICES }
                         onChange={ handleChange }/>
        </FormProvider> :
        <FunctionField render={ () => 'Super admin' }/>
}
