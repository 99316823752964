import React, {useMemo} from "react";
import {MONTHS_LABELS} from "../../Utils/constant/Const";
import {getBarChartOptions} from "../../Utils/helpers/get-bar-chart-options";
import {SelectMod} from "../Redesign/SelectMod";
import {Bar} from "react-chartjs-2";
import * as PropTypes from "prop-types";
import {Chart, registerables} from "chart.js";


Chart.register(...registerables);

function BarChartGraph({datasetConfig, graphic, filterData, handleChange, availableYears}) {
	const graphValue = Array.from(Object.values(graphic || {}));
	const chartData = useMemo(
		() => {
			if (!graphValue?.length) return {labels: MONTHS_LABELS, datasets: []};
			return {
				labels: MONTHS_LABELS,
				datasets: datasetConfig.map((config) => {
					return {
						label: config.label,
						data: graphValue.map(item => item[config.dataKey]),
						backgroundColor: config.backgroundColor,
						borderRadius: 5,
						maxBarThickness: 30,
					};
				})
			}
		}, [graphValue, datasetConfig]);

	const options = getBarChartOptions(filterData.year);

	return <div>
		<div style={{
			"display": "flex",
			"fontWeight": "500",
			"fontSize": "17px",
			"alignItems": "center",
			"flexWrap": "wrap"
		}}>
			<div style={{
				"display": "flex",
				"flexWrap": "wrap",
				"marginLeft": "20px",
				"flexShrink": "0",
			}}>
				{
					datasetConfig.map((config, index) => (
						<div key={index} style={{
							"width": datasetConfig.length === 1 ? '100%' : '50%',
							"color": config.legendColor,
						}}>
							{config.legend}
						</div>
					))
				}
			</div>
			{availableYears?.length ?
				<div style={{"marginLeft": "auto", "padding": "1rem .5rem"}}>
					<SelectMod id="year-select" label="Year" value={filterData.year}
										 choices={availableYears}
										 handleChange={handleChange}/>
				</div> : null
			}
		</div>
		<div style={{
			"display": "flex",
			"width": "calc(100vw - 260px)",
			"minHeight": "450px"
		}}>
			<Bar options={options} data={chartData}/>
		</div>
	</div>;
}

BarChartGraph.propTypes = {
	availableYears: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		label: PropTypes.string
	})).isRequired,
	filterData: PropTypes.shape({year: PropTypes.number}).isRequired,
	handleChange: PropTypes.func.isRequired,
	graphic: PropTypes.shape({datasets: PropTypes.any, labels: PropTypes.arrayOf(PropTypes.string)}).isRequired,
	datasetConfig: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		dataKey: PropTypes.string,
		backgroundColor: PropTypes.string,
		legend: PropTypes.string,
		legendColor: PropTypes.string
	})).isRequired
};

export default BarChartGraph;
