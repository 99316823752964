import {useParams} from 'react-router-dom';
import {
	FunctionField,
	Link,
	SimpleShowLayout,
	TabbedShowLayout,
	TabbedShowLayoutTabs,
	TextField,
	TopToolbar,
	useGetList,
	useRedirect,
} from 'react-admin';
import {getHighNoteOrganizationLink} from '../../Utils/constant/HighNote';
import {Card, Link as MuiLink} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DateFieldMod from '../../Component/Redesign/DateFieldMod';
import {CreditLimit} from '../../Component/CreditLimit/CreditLimit';
import {CreditLimitHistory} from '../../Component/CreditLimit/CreditLimitHistory';
import {RepaymentsReferenceList} from '../Repayments/reference-list';
import React from 'react';
import {makeStyles} from 'tss-react/mui';
import {DefaultCurrency} from '../../Utils/constant/Const';
import {RepaymentsFailsReferenceList} from '../Repayments/repayments-fail-reference-list';

const useStyles = makeStyles()({
	toolbar: {
		alignItems: 'center',
	},
	list: {
		display: 'flex',
		'& .item': {
			display: 'flex',
			alignItems: 'center',
		},
		'& .item:not(:last-child)': {
			marginRight: '1rem',
		},
		'& label': {
			fontSize: '1rem',
			lineHeight: 2.63,
			color: '#3f51b5',
		},
		'& .text': {
			fontSize: '1.125rem',
			color: '#3f51b5',
			margin: '0 0 0 .5rem'
		}
	},
});

const ToolbarItem = ({value, label}) => {
	const currencyBadge = DefaultCurrency.badge;
	return value !== undefined ?
		<div className="item">
			<label className="label">{label}:</label>
			<p className="text">{currencyBadge} {value || 0}</p>
		</div> :
		<div className="item">
			<label className="label">{label}</label>
		</div>
}

export const FinancialAccountShow = () => {
	const {id} = useParams()
	const redirect = useRedirect()
	const {data, isLoading} = useGetList('financial-accounts', {
		pagination: {perPage: 1},
		filter: {financialAccountId: id}
	});
	const account = data?.length ? data[0] : null
	const higNoteLink = getHighNoteOrganizationLink()
	const {classes} = useStyles();

	if (isLoading) {
		return null
	}

	if (!account) {
		redirect('/financial-accounts')
		return null
	}

	return (
		<div className="show-page">
			<TopToolbar className={classes.toolbar}>
				{
					account?.additionalData ?
						<div className={classes.list}>
							<ToolbarItem label="Credit limit" value={account.additionalData?.creditLimit}/>
							<ToolbarItem label="Available Credit" value={account.additionalData?.availableCredit}/>
							<ToolbarItem label="Outstanding balance"
													 value={account.additionalData?.outstandingBalance}/>
						</div> :
						<ToolbarItem label="Credit limit not set"/>
				}
			</TopToolbar>
			<Card>
				<TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto"/>}
													record={account}>
					<TabbedShowLayout.Tab label="Financial account">
						<SimpleShowLayout>
							<TextField label="Id" source="id" sortBy="id"/>
							<TextField label="Name" source="name"/>
							<FunctionField label="User name" record={account.businessHolder.user} render={user =>
								<Link to={`/users/${user?.id}/show`}>
									{user?.first_name} {user?.last_name}
								</Link>
							}/>
							<TextField label="Product id" source="product"/>
							<FunctionField label="Highnote url" render={(item) =>
								<MuiLink
									href={`${higNoteLink}/card-products/${item?.product}/${item?.externalId}`}
									target="_blank"
									rel="noopener noreferrer">
									<OpenInNewIcon/>
								</MuiLink>
							}/>
							<DateFieldMod showTime label="Created At" source="createdAt"/>
						</SimpleShowLayout>
						<SimpleShowLayout>
							<FunctionField record={account?.additionalData} render={additionalData =>
								<CreditLimit additionalData={additionalData}
														 holderId={account?.businessHolder?.id}
														 user={account?.businessHolder?.user}
														 cardStatus={account.card_status}/>
							}/>
						</SimpleShowLayout>
						<SimpleShowLayout>
							<CreditLimitHistory {...account}/>
						</SimpleShowLayout>
					</TabbedShowLayout.Tab>
					<TabbedShowLayout.Tab label="Repayments" path="repayments">
						<div>
							<RepaymentsReferenceList source="id"/>
						</div>
					</TabbedShowLayout.Tab>
					<TabbedShowLayout.Tab label="Failed Schedules" path="repayments/fails">
						<div>
							<RepaymentsFailsReferenceList source="businessHolder.user.id"/>
						</div>
					</TabbedShowLayout.Tab>
				</TabbedShowLayout>
			</Card>
		</div>
	)
}
