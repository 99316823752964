import React from 'react';
import {DateField, useRecordContext} from "react-admin";
import {DateTimeFormatString} from "../Handlers/DateTimeFormat";
import {get} from "lodash";

const DateFieldMod = ({source, ...rest}) => {
    /** fix for Safari (Date format must be YYYY-MM-DDTHH:mm:ss) */
    const record = useRecordContext();
    const value = get(record, source);
    if (record && value) {
        record[source] = DateTimeFormatString(value);
    }

    return <DateField source={source} {...rest} locales="en-US"/>;
};

export default DateFieldMod;
