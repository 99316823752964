import * as React from "react";
import {memo, useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()({
        modalContent: {
            minWidth: '320px',
        },
    }
)

const validateErrorInput = (msg) => {
    if (!msg) {
        return 'Field required'
    }
    if (msg.length > 256) {
        return 'Max symbols length is 256'
    }
    const msgPattern = /^[a-z0-9 ,.'"\-!?]+$/gi
    if (!msgPattern.test(msg)) {
        return 'Allowed symbols is latin, number and ,.\'"-!?'
    }
    return null
}

const CustomErrorModal = ({open, onClose, onUpdate, onDelete, label, error}) => {

    const {classes} = useStyles()

    const [value, setValue] = useState('')
    const [errorState, setError] = useState(null)

    useEffect(() => {
        const errorMsg = error?.error || ''
        setValue(errorMsg)
        const err = validateErrorInput(errorMsg)
        setError(err)
    }, [error])

    const handleChangeValue = (e) => {
        const {value} = e.target
        setValue(value)
        const error = validateErrorInput(value)
        setError(error)
    }

    const handleSubmit = () => {
        onUpdate(value)
    }

    const handleDelete = () => {
        onDelete(error.id)
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {label} error
            </DialogTitle>
            <DialogContent className={classes.modalContent}>
                <div id="alert-dialog-description">
                    <TextField value={value}
                               error={!!errorState}
                               onChange={handleChangeValue}
                               required
                               helperText={errorState}
                               label="Error message"
                               fullWidth
                               variant="standard"/>
                </div>
            </DialogContent>
            <DialogActions>
                {
                    error?.id ? <Button color="error" onClick={handleDelete}>Delete</Button> : null
                }
                <Button color="info" onClick={onClose}>Cancel</Button>
                <Button type="submit" color="primary" variant="contained" disabled={!!errorState}
                        onClick={handleSubmit}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default memo(CustomErrorModal)
