import {styled} from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import {tableCellClasses} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import React, {useMemo} from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import {SOCIAL_CHANNEL_TYPES} from "../../../Utils/constant/Const";

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: 'transparent',
	},
}));


const StyledTableRow = styled(TableRow)(({theme}) => ({
	'&:nth-of-type(even) td, &:nth-of-type(even) th': {
		backgroundColor: 'rgb(235, 235, 235)',
	},
	'&:nth-of-type(odd) td, &:nth-of-type(odd) th': {
		backgroundColor: 'white',
	},
	'& td:nth-of-type(1)': {
		position: 'sticky',
		left: 0,
		width: '120px',
		minWidth: '120px',
	},
	'& td:nth-of-type(2)': {
		position: 'sticky',
		left: '120px',
		width: '120px',
		minWidth: '120px',
		borderRight: `inset 1px solid ${theme.palette.divider}`,
	},
	'& td:nth-of-type(even):not(:last-of-type)': {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	'&:first-of-type th:nth-of-type(odd):not(:last-of-type)': {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	'&:nth-of-type(2) th:nth-of-type(odd):not(:last-of-type)': {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	'&:last-of-type td': {
		borderBottom: 'none',
	}
}));

const StyledTableHead = styled(TableHead)(({theme}) => ({
	'& tr:nth-of-type(1)': {
		position: 'sticky',
		top: 0,
		zIndex: 3,
		'& th:first-of-type': {
			position: 'sticky',
			left: 0,
			zIndex: 3,
		}
	},
	'& tr:nth-of-type(2)': {
		position: 'sticky',
		top: '56px',
		zIndex: 3,
		'& th:first-of-type': {
			position: 'sticky',
			left: 0,
			zIndex: 3,
		}
	},
	'& th': {
		borderBottom: 0
	}
}));

export const SOCIAL_CHANNEL_TABLE_NAME = {
	[`${SOCIAL_CHANNEL_TYPES.youtube}`]: 'Daily video views',
	[`${SOCIAL_CHANNEL_TYPES.instagram}`]: 'Number of followers',
	[`${SOCIAL_CHANNEL_TYPES.tikTok}`]: 'Number of followers',
}

export const MainSocialStatisticTable = ({table, channelType}) => {
	const tableTitle = SOCIAL_CHANNEL_TABLE_NAME?.[channelType] ?? ''
	const headerCols = useMemo(() => {
		const firstRow = table?.[0]
		if (!firstRow) {
			return []
		}
		return Object.values(firstRow).reduce((acc, item) => {
			if (typeof item === 'object' && 'min' in item) {
				acc.push(...[item.min, item.max])
			}
			return acc
		}, [])
	}, [table])

	const bodyRows = useMemo(() => {
		return table?.reduce((acc, row) => {
			const rowValues = [row.min, row.max];
			const values = Object.values(row).reduce((innerAcc, item) => {
				if (typeof item === 'object' && 'metrics' in item) {
					innerAcc.push(...[item.metrics.low, item.metrics.high])
				}
				return innerAcc
			}, [])
			rowValues.push(...values)
			acc.push(rowValues)
			return acc
		}, []) ?? []
	}, [table])

	return <Table sx={{minWidth: 650, width: '100%', borderCollapse: 'separate'}} aria-label="simple table">
		<StyledTableHead>
			<StyledTableRow>
				<TableCell colspan={2} align="center">
					Engagement rate, %
				</TableCell>
				{
					headerCols?.map((item, index) => {
						return <TableCell key={`header-value-col-${index}`} align="center">{item}</TableCell>
					})
				}
			</StyledTableRow>
			<StyledTableRow>
				<TableCell colspan={2} align="center">
					{tableTitle}
				</TableCell>
				{
					headerCols?.map((_, index) => {
						return <StyledTableCell key={`header-label-col-${index}`} align="center" sx={{whiteSpace: 'nowrap'}}>
							{
								index % 2 === 0 ? 'Avg lowest' : 'Avg highest'
							}
						</StyledTableCell>
					})
				}
			</StyledTableRow>
		</StyledTableHead>
		<TableBody>
			{bodyRows.map((row, index) => row ? (
					<StyledTableRow
						key={`body-row-${index}`}
					>
						{
							row?.map((item, ind) => {
								return <StyledTableCell
									key={`body-value-col-${ind}`} align="center">{item}</StyledTableCell>
							})
						}
					</StyledTableRow>
				) : null
			)}
		</TableBody>
	</Table>

}
