import {SvgIcon} from "@mui/material";

export const ToggleHeaderRow = (props) => {
    return (<SvgIcon fontSize="inherit" {...props} width="44" height="44" viewBox="0 0 44 44" fill="none">
        <path d="M2.7749 6.94127C2.7749 6.38898 3.22262 5.94127 3.7749 5.94127H11.9416V11.8889H2.7749V6.94127Z"
              fill="white"/>
        <path d="M12.5361 5.9413H21.7028V11.8889H12.5361V5.9413Z" fill="white"/>
        <path d="M22.2969 5.9413H31.4635V11.8889H22.2969V5.9413Z" fill="white"/>
        <path d="M2.7749 12.4837H11.9416V18.4314H2.7749V12.4837Z" fill="white"/>
        <path d="M2.7749 19.0262H11.9416V24.9738H2.7749V19.0262Z" fill="#5694E1"/>
        <path d="M2.7749 25.5686H11.9416V31.5163H2.7749V25.5686Z" fill="white"/>
        <path d="M2.7749 32.1111H11.9416V38.0587H2.7749V32.1111Z" fill="white"/>
        <path d="M12.5361 12.4837H21.7028V18.4314H12.5361V12.4837Z" fill="white"/>
        <path d="M12.5361 25.5686H21.7028V31.5163H12.5361V25.5686Z" fill="white"/>
        <path d="M12.5361 19.0262H21.7028V24.9738H12.5361V19.0262Z" fill="#5694E1"/>
        <path d="M12.5361 32.1111H21.7028V38.0587H12.5361V32.1111Z" fill="white"/>
        <path d="M22.2969 25.5686H31.4635V31.5163H22.2969V25.5686Z" fill="white"/>
        <path d="M22.2969 12.4837H31.4635V18.4314H22.2969V12.4837Z" fill="white"/>
        <path d="M22.2969 19.0262H31.4635V24.9738H22.2969V19.0262Z" fill="#5694E1"/>
        <path d="M22.2969 32.1111H31.4635V38.0587H22.2969V32.1111Z" fill="white"/>
        <path d="M11.9414 5.94127H12.5362V38.0587H11.9414V5.94127Z" fill="#C7C7C7"/>
        <path d="M32.0583 11.8889L32.0583 12.4837L2.77494 12.4837L2.77494 11.8889L32.0583 11.8889Z" fill="#C7C7C7"/>
        <path d="M32.0583 18.4314L32.0583 19.0261L2.77494 19.0261L2.77494 18.4314L32.0583 18.4314Z" fill="#C7C7C7"/>
        <path d="M32.0583 24.9738L32.0583 25.5686L2.77494 25.5686L2.77494 24.9738L32.0583 24.9738Z" fill="#C7C7C7"/>
        <path d="M32.0583 31.5163L32.0583 32.1111L2.77494 32.1111L2.77494 31.5163L32.0583 31.5163Z" fill="#C7C7C7"/>
        <path d="M21.7029 5.94127H22.2976V38.0587H21.7029V5.94127Z" fill="#C7C7C7"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.18066 38.6535C2.62838 38.6535 2.18066 38.2058 2.18066 37.6535V6.3465C2.18066 5.79422 2.62838 5.3465 3.18066 5.3465H31.0582C31.6105 5.3465 32.0582 5.79422 32.0582 6.3465L32.0583 37.6535C32.0583 38.2058 31.6106 38.6535 31.0583 38.6535H3.18066ZM2.77543 6.94127C2.77543 6.38898 3.22315 5.94127 3.77543 5.94127L30.4635 5.94127C31.0158 5.94127 31.4635 6.38898 31.4635 6.94127V37.0587C31.4635 37.611 31.0158 38.0587 30.4635 38.0587H3.77543C3.22314 38.0587 2.77543 37.611 2.77543 37.0587V6.94127Z"
              fill="#7D7D7D"/>
        <path
            d="M39.3411 24.9805V19.0195H40.2352V21.5529H42.9176V19.0195H43.8118V24.9805H42.9176V22.4471H40.2352V24.9805H39.3411Z"
            fill="#5694E1"/>
        <rect x="34.1885" y="19" width="3.42857" height="0.857143" fill="#5694E1"/>
        <rect x="34.1885" y="20.7143" width="3.42857" height="0.857143" fill="#5694E1"/>
        <rect x="34.1885" y="22.4286" width="3.42857" height="0.857143" fill="#5694E1"/>
        <rect x="34.1885" y="24.1429" width="3.42857" height="0.857143" fill="#5694E1"/>
    </SvgIcon>)
}
