import React, {useState} from "react";
import {getConfigInfo} from "../../Utils/helpers/getConfigInfo";
import {Form, SaveButton, TextInput, useUpdate} from "react-admin";
import {Dialog, DialogTitle} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import InformationErrorDialog from "../InformationDialog/InformationErrorDialog";

export const UpdateSettingsModal = ({item, configList, onClose, onSuccess}) => {
    const [errorModalState, setErrorModalState] = useState({errors: null, open: false});
    const configInfo = getConfigInfo(item.name, configList)

    const [update, {isLoading}] = useUpdate()
    const handleSubmit = (values) => {
        const {value} = values;
        update(
            'configs',
            {id: item.id, data: {value: value}},
            {
                onSuccess: () => {
                    onSuccess()
                    onClose()
                },
                onError: (err) => {
                    handleOpenError(err)
                }
            })
    }

    const handleOpenError = (errors) => {
        setErrorModalState(() => ({errors, open: true}));
    };

    const handleCloseError = () => {
        setErrorModalState({errors: null, open: false});
    };

    return (<>
            <Dialog open={true} onClose={onClose} disableEnforceFocus={true}
                    sx={{'& .MuiPaper-root': {width: '500px'}}}>
                <Form defaultValues={{value: item.value}} mode="all" onSubmit={handleSubmit}>
                    <DialogTitle>{configInfo?.label}</DialogTitle>
                    <DialogContent>
                        <TextInput
                            label="Value"
                            name="value"
                            source="value"
                            validate={configInfo.validate || null}
                            variant="outlined"
                            size="medium"
                            fullWidth={true}
                            autoFocus
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <SaveButton disabled={isLoading}/>
                    </DialogActions>
                </Form>
            </Dialog>
            {
                errorModalState.errors && <InformationErrorDialog {...errorModalState} onClose={handleCloseError}/>
            }
        </>
    )
}
