import { FunctionField, Labeled, useRecordContext } from 'react-admin';
import React from 'react';
import get from 'lodash/get';
import { makeStyles } from 'tss-react/mui';
import { IsEmptyValue } from '../../Component/Handlers/IsEmptyValue';

const useStyles = makeStyles()({
        wrapper: {
            display: 'flex',
            flexDirection: 'column'
        },
    }
);

export const HolderApplicationVerificationShow = ({ source }) => {
    const record = useRecordContext()
    const verification = get(record, source);
    const { classes } = useStyles()

    if (!verification || IsEmptyValue(verification)) {
        return null
    }
    return <div className={ classes.wrapper }>
        {
            verification?.businessProfile &&
            <Labeled label="Business profile details">
                <FunctionField color="error"
                               record={ verification.businessProfile }
                               render={ data => data.join(', ') }/>
            </Labeled>
        }
        {
            verification?.primaryAuthorizedPerson &&
            <Labeled label="Primary authorized person">
                <FunctionField color="error"
                               record={ verification.primaryAuthorizedPerson }
                               render={ data => data.join(', ') }/>
            </Labeled>
        }
        {
            verification?.ultimateBeneficialOwner &&
            <Labeled label="Beneficial owners details">
                <FunctionField color="error"
                               record={ verification?.ultimateBeneficialOwner }
                               render={ data => data?.join(', ') }/>
            </Labeled>
        }
        {
            verification?.bumpAdmin &&
            <Labeled label="Bump Admin details">
                <FunctionField color="error"
                               record={ verification?.bumpAdmin }
                               render={ data => data?.join(', ') }/>
            </Labeled>
        }
    </div>
}
