import {ENV} from "../../Const/ProjectEnv";

export const HighNoteUrl = 'https://dashboard.highnoteplatform.com';

export const getHighNoteOrganizationLink = () => {
    const org = ENV.HIGHNOTE_ORGANIZATION_ID;
    if (!org) {
        throw new Error('no organization id')
    }
    return `${HighNoteUrl}/organizations/${org}`
}

export const getHighNoteProductId = () => {
    const product = ENV.HIGHNOTE_PRODUCT_ID;
    if (!product) {
        throw new Error('no product id')
    }
    return product;
}
