export const getBarChartOptions = (year) => ({
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			bodyFont: {
				size: 12, family: 'Roboto', weight: 'normal',
			},
			bodyAlign: 'center',
			padding: 8,
			usePointStyle: true,
			boxWidth: 6,
			boxHeight: 6,
			boxPadding: 4,
			callbacks: {
				title: () => '', label: (context) => {
					let label = context.dataset.label || '';
					label += context.label + ' ' + year + '     ';
					if (context.parsed.y !== null) {
						label += new Intl.NumberFormat('en-US', {
							style: 'currency', currency: 'USD'
						}).format(context.parsed.y);
					}
					return label;
				},
			},
		},
	},
	scales: {
		y: {
			grid: {
				borderDash: [5, 5], drawTicks: false, drawBorder: false,
			}, ticks: {
				color: '#000000',
				font: {
					family: 'Roboto', weight: 'normal', size: 12,
				},
				callback: (value) => {
					value += '';
					const x = value.split('.');
					let x1 = x[0];
					const x2 = x.length > 1 ? '.' + x[1] : '';
					const rgx = /(\d+)(\d{3})/;
					while (rgx.test(x1)) {
						x1 = x1.replace(rgx, `$1,$2`);
					}
					return '$' + (x1 + x2);
				}, padding: 16,
			},
		}, x: {
			ticks: {
				color: '#000000',
				font: {
					size: 10, weight: 'normal',
				},
				padding: 16,
			}, grid: {
				display: false, drawTicks: false, drawBorder: false,
			}
		}
	}, responsive: true, maintainAspectRatio: false,
});
