import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogTitle } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import {
    maxLength,
    SaveButton,
    SelectInput,
    TextInput,
    useNotify,
    useRecordContext,
    useRefresh,
    useUpdate
} from 'react-admin';
import { FormDataConsumer } from 'ra-core';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DISPUTE_STATUS, DisputeStatus } from '../../../Utils/constant/Const';

export const EditDisputeModal = () => {

    const form = useForm();
    const notify = useNotify();
    const refresh = useRefresh();
    const { id } = useRecordContext();

    const [ update, { isLoading } ] = useUpdate();

    const [ openEditModal, setOpenEditModal ] = useState(false);

    const onOpenEditModal = () => setOpenEditModal(true);
    const onCloseEditModal = () => setOpenEditModal(false);

    const onSubmit = (data) => {

        if (!data || isLoading) return;

        if (!data.decisionDescription) {
            delete data.decisionDescription;
        }

        update(
            `disputes`,
            {
                id,
                data,
                meta: { resourceSuffix: 'status' },
            },
            {
                onSuccess: () => {
                    notify('Dispute updated')
                    onCloseEditModal();
                },
                onError: (error) => {
                    notify(error.message, { type: 'error' })
                    refresh()
                }
            }
        )
    }

    const dialogSx = {
        '& .MuiPaper-root': {
            width: '400px'
        }
    }
    const contentSx = {
        display: 'flex',
        flexDirection: 'column'
    }
    const actionsSx = {
        padding: ' 0 24px 20px',
        display: 'flex',
        justifyContent: 'space-between'
    }

    return (
        <div>
            <Button onClick={ onOpenEditModal }
                    variant="contained"
                    color="primary">
                Edit Dispute
            </Button>
            <Dialog open={ openEditModal }
                    sx={ dialogSx }
                    onClose={ onCloseEditModal }
                    disableEnforceFocus={ true }>
                <FormProvider { ...form }>
                    <form onSubmit={ form.handleSubmit(onSubmit) }>
                        <DialogTitle>Edit dispute</DialogTitle>
                        <DialogContent sx={ contentSx }>
                            <SelectInput source="status"
                                         emptyValue={ false }
                                         rules={ { required: 'Status is required' } }
                                         label="Status"
                                         choices={ DISPUTE_STATUS }/>
                            <FormDataConsumer>
                                {
                                    ({ formData }) => {
                                        if (formData.status === DisputeStatus.DECLINED || formData.status === DisputeStatus.RESOLVED) {
                                            return <TextInput source="decisionDescription"
                                                              defaultValue={ formData.decisionDescription }
                                                              validate={ maxLength(300, 'maximum 300 characters') }
                                                              label="Decision Description"/>
                                        }
                                    }
                                }
                            </FormDataConsumer>
                        </DialogContent>
                        <DialogActions sx={ actionsSx }>
                            <Button onClick={ onCloseEditModal }>Cancel</Button>
                            <SaveButton/>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </div>
    )
}
