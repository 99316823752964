import {makeStyles} from "tss-react/mui";
import DashboardCard from "../../Component/Cards/DashboardCard";
import {List, ListItem, ListItemText} from "@mui/material";
import {getFinancialNumberWithCurrency, reformatNumber} from "../../Utils/helpers/reformat-number";
import * as PropTypes from "prop-types";
import React from "react";
import {SOCIAL_CHANNEL_TYPES_LIST} from "../../Utils/constant/Const";

const useStyles = makeStyles()({
	list: {
		padding: '12px',
	},
	listItem: {
		textAlign: 'left',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: 0,
	},
	listItemText: {
		margin: 0,
		'& span': {
			display: 'flex',
			flexDirection: 'column',
			gap: '4px'
		}
	},
	listItemTextRow: {
		marginTop: '8px',
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
	},
	listItemTextTitle: {
		fontSize: '14px',
		color: '#545454'
	},
	listItemTextValue: {
		fontSize: '16px',
		fontWeight: 700,
		color: '#181818'
	},
	divider: {
		width: '100%',
		height: '1px',
		margin: '12px 0',
		backgroundColor: '#E6E6E6'
	},
	nowrap: {
		whiteSpace: 'nowrap',
		margin: '0 5px',
	},
	link: {
		cursor: 'pointer',
		textDecoration: 'none',
		color: 'inherit',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: '20px',
		padding: '30px 0',
	},
});


function MarketValueStatistic({data}) {
	const {classes} = useStyles();
	return (
		<div className={classes.container}>
			<DashboardCard title="Creators" subtitle="">
				<List className={classes.list}>
					<ListItem className={classes.listItem}>
						<ListItemText className={classes.listItemText}>
							<span className={classes.listItemTextTitle}>
								Total No. of Creators:
							</span>
							<span className={classes.listItemTextValue}>
								{reformatNumber(data?.users?.totalCreators)}
							</span>
						</ListItemText>
						<div className={classes.divider}/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText className={classes.listItemText}>
							<span className={classes.listItemTextTitle}>
								Total No. of Creators with Connected Social Media Accounts:
							</span>
							<span className={classes.listItemTextValue}>
								{reformatNumber(data?.users?.totalCreatorsWithSocialAccounts)}
							</span>
						</ListItemText>
						<div className={classes.divider}/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText className={classes.listItemText}>
							<span
								className={classes.listItemTextTitle}>Split Based On Number of Connected Social Media Accounts:</span>
						</ListItemText>
						<div className={classes.listItemTextRow}>
							{
								Object.keys(data?.users?.totalCreatorsByPlatform || {}).map((key, index) => (
									<ListItemText key={key} className={classes.listItemText}>
										<span className={classes.listItemTextTitle}>
											{index + 1}{index === SOCIAL_CHANNEL_TYPES_LIST.length - 1 ? '+' : ''} {index === 0 ? 'account' : 'accounts'}:
										</span>
										<span className={classes.listItemTextValue}>
											{reformatNumber(data?.users?.totalCreatorsByPlatform[key])}
										</span>
									</ListItemText>)
								)
							}
						</div>
					</ListItem>
				</List>
			</DashboardCard>
			<DashboardCard title="Social Media Channels" subtitle="">
				<List className={classes.list}>
					<ListItem className={classes.listItem}>
						<ListItemText className={classes.listItemText}>
							<span className={classes.listItemTextTitle}>
								Total No. of Connected Social Media Channels:
							</span>
							<span className={classes.listItemTextValue}>
								{reformatNumber(data?.socialChannels?.socialChannels)}
							</span>
						</ListItemText>
						<div className={classes.divider}/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText className={classes.listItemText}>
							<span className={classes.listItemTextTitle}>
								Split Based on Connected Social Media Channels:
							</span>
						</ListItemText>
						<div className={classes.listItemTextRow}>
							{
								SOCIAL_CHANNEL_TYPES_LIST.map((channel) => (
									<ListItemText key={channel.id} className={classes.listItemText}>
										<span className={classes.listItemTextTitle}>
											{channel.name}:
										</span>
										<span className={classes.listItemTextValue}>
											{reformatNumber(data?.socialChannels?.connected[channel.id])}
										</span>
									</ListItemText>)
								)
							}
						</div>
					</ListItem>
				</List>
			</DashboardCard>
			<DashboardCard title="Creator Market Value" subtitle="">
				<List className={classes.list}>
					<ListItem className={classes.listItem}>
						<ListItemText className={classes.listItemText}>
							<span className={classes.listItemTextTitle}>
								Total Market Value of All Creators (As of Today):
							</span>
							<span className={classes.listItemTextValue}>
								{getFinancialNumberWithCurrency(data?.marketValueDiscovered?.totalMarketValue)}
							</span>
						</ListItemText>
						<div className={classes.divider}/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText className={classes.listItemText}>
							<span className={classes.listItemTextTitle}>
								Average Market Value of All Creators (As of Today):
							</span>
							<span className={classes.listItemTextValue}>
								{getFinancialNumberWithCurrency(data?.marketValueDiscovered?.avgMarketValueCreators)}
							</span>
						</ListItemText>
					</ListItem>
					<div className={classes.divider}/>
					<ListItem className={classes.listItem}>
						<ListItemText className={classes.listItemText}>
							<span className={classes.listItemTextTitle}>
								Projected Market Value of All Creators:
							</span>
						</ListItemText>
						<div className={classes.listItemTextRow}>
							<ListItemText className={classes.listItemText}>
									<span className={classes.listItemTextTitle}>
										Monthly:
									</span>
								<span className={classes.listItemTextValue}>
										{getFinancialNumberWithCurrency(parseFloat((data?.marketValueDiscovered?.totalMarketValue || 0)).toFixed(2) * 30)}
								</span>
							</ListItemText>
							<ListItemText className={classes.listItemText}>
									<span className={classes.listItemTextTitle}>
										Annual:
									</span>
								<span className={classes.listItemTextValue}>
										{getFinancialNumberWithCurrency(parseFloat((data?.marketValueDiscovered?.totalMarketValue || 0)).toFixed(2) * 365)}
								</span>
							</ListItemText>
						</div>
					</ListItem>
				</List>
			</DashboardCard>
			<DashboardCard title="Creator Market Value Per Social Media Channel" subtitle="">
				<List className={classes.list}>
					<ListItem className={classes.listItem}>
						<ListItemText className={classes.listItemText}>
							<span className={classes.listItemTextTitle}>
								Total Market Value of All Creators Per Social Media Chanel (As of Today):
							</span>
						</ListItemText>
						<div className={classes.listItemTextRow}>
							{
								SOCIAL_CHANNEL_TYPES_LIST.map((channel) => (
									<ListItemText key={channel.id} className={classes.listItemText}>
										<span className={classes.listItemTextTitle}>
											{channel.name}:
										</span>
										<span className={classes.listItemTextValue}>
											{getFinancialNumberWithCurrency(data?.marketValueStats?.totalMarketValuePerChannel[channel.id])}
										</span>
									</ListItemText>)
								)
							}
						</div>
						<div className={classes.divider}/>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText className={classes.listItemText}>
							<span className={classes.listItemTextTitle}>
								Average Market Value of All Creators Per Social Media Chanel (As of Today):
							</span>
						</ListItemText>
						<div className={classes.listItemTextRow}>
							{
								SOCIAL_CHANNEL_TYPES_LIST.map((channel) => (
									<ListItemText key={channel.id} className={classes.listItemText}>
										<span className={classes.listItemTextTitle}>
											{channel.name}:
										</span>
										<span className={classes.listItemTextValue}>
											{getFinancialNumberWithCurrency(data?.marketValueStats?.avgMarketValuePerChannel[channel.id])}
										</span>
									</ListItemText>)
								)
							}
						</div>
					</ListItem>
				</List>
			</DashboardCard>
		</div>
	);
}

MarketValueStatistic.propTypes = {
	data: PropTypes.shape({}),
};

export default MarketValueStatistic;
