import { Datagrid, FunctionField, SelectField, ShowButton, TextField, useListContext } from 'react-admin';
import Spinner from '../../../Component/Spinner/Spinner';
import React from 'react';
import DateFieldMod from '../../../Component/Redesign/DateFieldMod';
import { DISPUTE_REASON } from '../../../Utils/constant/Const';
import { DisputeStatusEdit } from './DisputeStatusEdit';

export const DisputesListDatagrid = () => {

    const { isLoading } = useListContext();

    if (isLoading) return <Spinner/>

    return (
        <Datagrid bulkActionButtons={ false }>
            <TextField label="DISPUTE ID"
                       source="id"/>
            <TextField label="USER ID"
                       source="userId"/>
            <DateFieldMod showTime
                          label="CREATED AT"
                          source="createdAt"/>
            <FunctionField label="STATUS"
                           render={ () => <DisputeStatusEdit/> }/>
            <SelectField label="REASON"
                         source="reason"
                         sortable={ false }
                         choices={ DISPUTE_REASON }/>
            <TextField label="DESCRIPTION"
                       style={{ display: 'inline-block', maxWidth: '500px', wordBreak: 'break-all' }}
                       sortable={ false }
                       source="description"/>
            <ShowButton/>
        </Datagrid>
    )
}
