import { maxValue, minValue, number, required } from "react-admin";

const defaultValidation = [required(), number(), minValue(0)];
export const creditConfigList = [
  {
    name: "totalBalanceThreshold",
    label: "Total balance threshold, percent",
    type: "number",
    validate: [...defaultValidation, maxValue(100)],
  },
];
