import React, {useState} from 'react';
import {useGet} from '../../Hooks/useGet';
import DashboardsStatistic from './DashboardStatistic';
import BarChartGraph from "../../Component/Charts/BarChartGraph";
import {LoadingPage} from "ra-ui-materialui";


const datasetConfig = [
	{
		label: '',
		legend: 'Total Bank Account Balances For All Creators',
		legendColor: '#8264F5',
		dataKey: 'balance',
		backgroundColor: '#AF9CF9',
	},
	{
		label: '',
		legend: 'Total Credits Set For All Creators',
		legendColor: '#67D4C0',
		dataKey: 'credit',
		backgroundColor: '#67D4C0',
	},
	{
		label: '',
		legend: 'Total Discovered Income For All Creators',
		legendColor: '#EDC543',
		dataKey: 'incomeBalance',
		backgroundColor: '#EDC543',
	},
	{
		label: '',
		legend: 'Total Transactions By All Creators',
		legendColor: '#97B4FF',
		dataKey: 'transaction',
		backgroundColor: '#97B4FF',
	}
]


export const Dashboard = () => {
	const year = new Date().getFullYear();
	const [filterData, setFilterData] = useState({year});
	const {data, isLoading} = useGet('overview', {meta: {query: filterData}});

	const availableYears = data?.availableYears?.length ?
		data.availableYears.map(year => ({value: year, label: year.toString()})) :
		[{
			value: year,
			label: year.toString()
		}]
	const onChangeYear = year => {
		setFilterData({year: +year})
	}

	return isLoading ?
		<LoadingPage/> :
		<>
			<div style={{'display': 'flex', 'width': '100%'}}>
				<div style={{
					'display': 'flex',
					'flexDirection': 'column',
					'width': '100%',
				}}>
					<BarChartGraph availableYears={availableYears} filterData={filterData} datasetConfig={datasetConfig}
												 handleChange={onChangeYear} graphic={data?.graphic}/>
					<DashboardsStatistic data={data}/>
				</div>
			</div>
		</>
};

export default Dashboard;


