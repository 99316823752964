import {
	Confirm,
	Datagrid,
	FunctionField,
	Link,
	LoadingPage,
	Pagination,
	ReferenceField,
	ReferenceManyField,
	RefreshIconButton,
	SelectField,
	Show,
	SimpleShowLayout,
	TabbedShowLayout,
	TabbedShowLayoutTabs,
	TextField,
	TopToolbar,
	useDataProvider,
	useNotify,
	usePermissions,
	useRecordContext,
	useRefresh,
	useUpdate,
} from "react-admin";
import {CardStatus, CardStatusSelection, TransactionTypes} from "../../Utils/constant/Const";
import React, {useState} from "react";
import {FinancialAccountByUserShow} from "../FinancialAccount/showByUser";
import DateFieldMod from "../../Component/Redesign/DateFieldMod";
import {IsEmptyValue} from "../../Component/Handlers/IsEmptyValue";
import UrlField from "../../Component/Redesign/UrlField";
import {getHighNoteOrganizationLink, getHighNoteProductId} from "../../Utils/constant/HighNote";
import {makeStyles} from "tss-react/mui";
import Button from "@mui/material/Button";
import {getFinancialNumberWithCurrency} from "../../Utils/helpers/reformat-number";
import {hasAdminRole, hasRole} from "../../Utils/helpers/permissions";
import {ROLE_SUPER_ADMIN} from "../../Utils/constant/Permissions";
import {InformationDialog} from "../../Component/InformationDialog/InformationDialog";

const useStyles = makeStyles()({
	toolbar: {
		justifyContent: 'space-between'
	},
	creditLimit: {
		display: "flex",
	},
	total: {
		fontSize: 16,
		margin: '0 10px 0 5px',
		lineHeight: 2.63
	},
	text: {
		display: 'inline-flex',
		alignItems: 'center',
		fontSize: 16,
		color: '#3f51b5',
	}
});

const CardTabbedShow = ({permissions}) => {

	const record = useRecordContext();

	const dataProvider = useDataProvider();
	const notify = useNotify();
	const higNoteLink = getHighNoteOrganizationLink();
	const higNoteProductId = getHighNoteProductId();

	const handLeUpdateTransaction = () => {
		dataProvider.update(
			`cards`,
			{
				id: record?.card?.id,
				meta: {
					resourceSuffix: 'update-transaction',
				}
			}
		).then(() => {
			notify('Transaction updated');
		})
	}
	if (!record) {
		return null
	}
	return <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto"/>}>
		{
			hasAdminRole(permissions) &&
			<TabbedShowLayout.Tab label="Card">
				<SimpleShowLayout>
					<TextField label={"Id"} source="card.id"/>
					<TextField label={"bin"} source="card.bin"/>
					<TextField label={"last4"} source="card.last4"/>
					<DateFieldMod label="Expiration date" options={{year: 'numeric', month: 'numeric'}}
												source="card.expiration_date"/>
					<TextField label={"Network"} source="card.network"/>
					<SelectField label={"Status"} source="card.status" choices={CardStatusSelection}/>
					<FunctionField label="User" render={record => {
						return (<Link to={`/users/${record?.user?.id}/show`}>
							{record?.user?.firstName} {record?.user?.lastName}
						</Link>)
					}}/>
					{record?.holder?.id ? <ReferenceField label="Card Request" source="holder.id" reference="holders"
													link={(_, reference) => `/${reference}/${record.holder.id}/show`}
													sortable={false}>
						<FunctionField render={record => `${record.business_name}`}/>
					</ReferenceField> : null}
				</SimpleShowLayout>
			</TabbedShowLayout.Tab>
		}

		<TabbedShowLayout.Tab label="Financial account" disabled={!record?.financialAccount}>
			{
				record?.user &&
				<div>
					<FinancialAccountByUserShow source="financialAccount" userId={record?.user?.id}/>
				</div>
			}
		</TabbedShowLayout.Tab>
		<TabbedShowLayout.Tab label="Transaction" disabled={!record?.financialAccount}>
			{
				record?.financialAccount && <>
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<RefreshIconButton color="primary"
															 onClick={handLeUpdateTransaction}> </RefreshIconButton>
					</div>
					<ReferenceManyField source="financialAccount.id" reference="transactions" target="financial"
															label={false}
															pagination={<Pagination/>}>
						<Datagrid bulkActionButtons={false}>
							<TextField label="Id" source="id"/>
							<UrlField
								label='HIGHNOTE'
								source='external_id'
								val={`${higNoteLink}/card-products/${higNoteProductId}/transaction-events/`}
								target='_blank'
								rel="noopener noreferrer"
							/>
							<SelectField label="Type" source="type" choices={TransactionTypes}/>
							<TextField label="Result" source="response_code"/>
							<FunctionField label="Approved amount" sortBy="amount"
														 render={record => `${getFinancialNumberWithCurrency(record.amount / 100)}`}/>
							<FunctionField label="Requested amount" sortBy="requested_amount"
														 render={record => record.requested_amount === null ? '-' : `${getFinancialNumberWithCurrency(record.requested_amount / 100)}`}/>
							<FunctionField label="Available amount" sortBy="available_amount"
														 render={record => `${getFinancialNumberWithCurrency(record.available_amount / 100)}`}/>
							<DateFieldMod label="Time" source="time" showTime/>
							<FunctionField label="Merchant Details"
														 render={({merchant_category, merchant_name}) => {
															 if (merchant_category && merchant_name) {
																 return `${merchant_category}, ${merchant_name}`
															 }
															 return merchant_category || merchant_name
														 }}/>
							<TextField label="Description" source="merchant_description"/>
						</Datagrid>
					</ReferenceManyField>
				</>
			}
		</TabbedShowLayout.Tab>
	</TabbedShowLayout>
}


const CardShowActions = ({permissions}) => {
	const data = useRecordContext();
	const [open, setOpen] = useState(false);
	const [openActivate, setOpenActivate] = useState(false);
	const [openFeeErrorModal, setOpenFeeErrorModal] = useState(false);
	const {classes} = useStyles(data);
	const refresh = useRefresh();
	const notify = useNotify();

	const [suspendCard, {loading: loadingSuspend}] = useUpdate(
		'cards/suspend',
		{id: data?.id || '', data: {}},
		{
			onSuccess: () => {
				refresh()
				notify('Card locked');
			},
			onError: (err) => {
				notify(err.message, {type: "error"});
			},
		}
	);

	const [activateCard, {loading: loadingActivate}] = useUpdate(
		'cards/activate',
		{id: data?.id || '', data: {}},
		{
			onSuccess: () => {
				refresh();
				notify('Card unlocked');
			},
			onError: (err) => {
				notify(err.message, {type: "error"});
			},
		}
	);

	const handleClick = () => setOpen(true);
	const handleClickActivate = () => {
		const {activeSubscription, membershipTrail, membershipTrailDaysLeft} = data.user;
		if (activeSubscription || !membershipTrail || membershipTrailDaysLeft >= 0) {
			setOpenActivate(true);
			return
		}
		setOpenFeeErrorModal(true);
	}
	const handleDialogClose = () => setOpen(false);
	const handleCloseActivate = () => setOpenActivate(false);

	const handleSuspendConfirm = () => {
		suspendCard();
		setOpen(false);
	};

	const handleActivateConfirm = () => {
		activateCard();
		setOpenActivate(false);
	};

	if (!data || !data.card) {
		return null;
	}

	return <TopToolbar className={classes.toolbar}>{<>
		{data.financialAccount && (
			<div className={classes.creditLimit}>
				<div className={classes.text}> Credit limit :</div>
				<div className={classes.total}>
					<FunctionField
						color="primary" render={() => {
						const {credit_limit} = data.financialAccount;
						return IsEmptyValue(credit_limit) || !credit_limit?.account_holder_credit_limit ? 0 :
							`${getFinancialNumberWithCurrency(credit_limit.account_holder_credit_limit?.value)}`;
					}}/>
				</div>
				<div className={classes.text}> Available credit :</div>
				<div className={classes.total}>
					<FunctionField
						color="primary" render={() => {
						const {credit_limit} = data.financialAccount;
						return IsEmptyValue(credit_limit) || !credit_limit?.available_credit ? 0 :
							`${getFinancialNumberWithCurrency(credit_limit.available_credit?.value || 0)}`;
					}}/>
				</div>
			</div>)}
		{(() => {
			const status = data?.card?.status;
			const superAdminRole = hasRole(ROLE_SUPER_ADMIN, permissions);
			if (
				status === CardStatus.adminSuspend ||
				status === CardStatus.userSuspend ||
				(status === CardStatus.paymentOverdue && superAdminRole)
			) {
				return <div>
					<Button color="primary" onClick={handleClickActivate}> UNLOCK CARD </Button>
					<Confirm
						isOpen={openActivate}
						loading={loadingActivate}
						title="Unlock Card"
						content="Are you sure you want unlock card?"
						onConfirm={handleActivateConfirm}
						onClose={handleCloseActivate}
					/>
				</div>
			}
			if (status === CardStatus.paymentOverdue && !superAdminRole) {
				return (
					<div>
						<Button color="primary" disabled>
							{" "}
							PAYMENT OVERDUE{" "}
						</Button>
					</div>
				);
			}
			if (status === CardStatus.closed) {
				return <div><Button color="primary" disabled> CARD CLOSED </Button></div>
			}
			if (status === CardStatus.active) {
				return <div>
					<Button color="primary" onClick={handleClick}> LOCK CARD </Button>
					<Confirm
						isOpen={open}
						loading={loadingSuspend}
						title="Lock Card"
						content="Are you sure you want lock card?"
						onConfirm={handleSuspendConfirm}
						onClose={handleDialogClose}
					/>
				</div>
			}
			return null
		})()
		}
		<InformationDialog open={openFeeErrorModal} onClose={() => setOpenFeeErrorModal(false)}
											 title="Unlock card"
											 text="User does not have an active subscription or trial period has been finished already."/>

	</>
	}
	</TopToolbar>
};

export const CardShow = () => {

	const {permissions, isLoading} = usePermissions()
	return !isLoading ?
		<Show actions={hasAdminRole(permissions) ? <CardShowActions permissions={permissions}/> : false}>
			<CardTabbedShow permissions={permissions}/>
		</Show> :
		<LoadingPage/>
};
