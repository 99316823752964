import {FormProvider, useForm} from "react-hook-form";
import {Confirm, maxValue, minValue, regex, required, SelectInput, TextInput} from "react-admin";
import {FINANCIAL_NUMBER_PATTERN} from "../../Utils/regex/regex";
import {CircularProgress, Typography} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as PropTypes from "prop-types"
import React, {useCallback, useEffect, useState} from "react";
import {DefaultCurrency} from "../../Utils/constant/Const";
import {makeStyles} from "tss-react/mui";
import {getFinancialNumberWithCurrency} from "../../Utils/helpers/reformat-number";


const useStyles = makeStyles()({
    labeled: {
        fontWeight: 700,
        fontSize: '1rem',
        color: '#000',
        marginBottom: '1rem'
    }
})

export const FinancialRepaymentForm = ({data, outstandingBalance, onSubmit, onCancel, isLoading}) => {

    const defaultAccountId = data?.externalAccounts?.find(acc => acc.defaultAccount)?.externalId
    const maxAmount = outstandingBalance - (data?.totalIncomplete || 0)
    const {classes} = useStyles()
    const [openConfirm, setConfirmOpen] = useState(false)

    const form = useForm({
        defaultValues: {
            amount: '',
            externalId: defaultAccountId || '',
        },
        shouldFocusError: true,
        mode: 'onChange',
        reValidateMode: 'onChange'
    })

    const setExternalAccountError = useCallback(() => {
        form.setValue('externalId', null, {shouldTouch: true, shouldValidate: true})
    }, [form])

    useEffect(() => {
        const accountLength = data?.externalAccounts?.length;
        if (!accountLength) {
            setExternalAccountError()
        }
    }, [data, setExternalAccountError])


    const validateAmount = [
        required(),
        regex(FINANCIAL_NUMBER_PATTERN, 'Value must be in financial number format with dot separator'),
        minValue(0.01),
        maxValue(maxAmount, `Value should be equal to or less then ${maxAmount}`)
    ]

    const validateExternalAccount = () => {
        if (!data?.externalAccounts?.length) {
            return 'No available account';
        }
        return undefined;
    };

    const handleSubmit = (values) => {
        const {externalId} = values
        const isDefaultSelected = defaultAccountId && defaultAccountId === externalId
        isDefaultSelected ? onSubmit(values) : setConfirmOpen(true)
    }

    const handleConfirmClose = () => {
        setConfirmOpen(false)
    }

    const handleConfirm = () => {
        onSubmit(form.getValues())
        handleConfirmClose()
    }

    return <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Typography variant="body2" className={classes.labeled}>
                Outstanding balance: {getFinancialNumberWithCurrency(outstandingBalance)}
            </Typography>
            <Typography variant="body2" className={classes.labeled}>
                Scheduled repayment: {getFinancialNumberWithCurrency(data?.totalIncomplete)}
            </Typography>
            {
                maxAmount === 0 ?
                    <Typography variant="body2" className={classes.labeled}>
                        There is nothing to return, the entire outstanding balance is in the process of repayment
                    </Typography> :
                    <>
                        <TextInput source="amount" name="amount" validate={validateAmount} fullWidth
                                   placeholder="0.00"
                                   error={true}
                                   variant="outlined"/>
                        <SelectInput source="externalId"
                                     choices={data?.externalAccounts}
                                     label="Please select account"
                                     optionValue="externalId"
                                     optionText={({name, externalBankAccountDetails, defaultAccount, currentBalance}) =>
                                         `${name} ${externalBankAccountDetails.last4} ${defaultAccount ? '(Default)' : ''} - ${DefaultCurrency.badge}${currentBalance}`}
                                     validate={[validateExternalAccount, required()]}
                                     fullWidth
                                     variant="outlined"/>
                    </>
            }

            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                {
                    maxAmount !== 0 &&
                    <Button type="Submit" disabled={isLoading || !form.formState.isValid || !maxAmount}
                            variant="contained"
                            color="primary">
                        {isLoading && <CircularProgress size={25} thickness={2}/>}
                        Pay</Button>
                }

            </DialogActions>

            <Confirm
                isOpen={openConfirm}
                title="Attention"
                content="Are you sure that you want to initiate the repayment not from the default account?"
                onConfirm={handleConfirm}
                onClose={handleConfirmClose}
            />
        </form>
    </FormProvider>;
}

FinancialRepaymentForm.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    data: PropTypes.object,
    outstandingBalance: PropTypes.number,
    isLoading: PropTypes.bool,
};
